import { Title } from "../components/Title";
import { useTranslation } from "react-i18next";
import { DeliveryAddressTable } from "../components/cart/step_2/Delivery-addresses/DeliveryAddressTable";
import { Button } from "../components/Button";
import { useState } from "react";
import AddAddressModal, { Address } from "../components/templates/AddAddressModal";
import useProfileMutations from "../api/profile/useProfileMutations";
import { useNavigate } from "react-router-dom";
import { Loader } from "../components/Loader";

export default function LogisticsDetails({ data: profile }: any) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // state
    const [openNewAddres, setOpenNewAddress] = useState(false);

    // react query
    const { updateProfile } = useProfileMutations();

    const addNewAddress = async (formData: Address) => {
        await updateProfile.mutateAsync({ customer_code: profile?.data?.code, addAddress: formData });
        navigate("/profile-sent");
    };

    return (
        <>
            {updateProfile.isLoading && <Loader />}
            {openNewAddres && <AddAddressModal open={openNewAddres} onClose={setOpenNewAddress} title={t("Aggiungi nuovo indirizzo")} primaryActionLabel={t("Aggiungi")} callbackFn={addNewAddress} />}

            <div className="px-6 mb-6">
                <Title className="mb-6" variant={Title.variant.secondary}>
                    {t("Indirizzi di spedizione")}
                </Title>

                {profile?.data?.deliveryPartners && <DeliveryAddressTable items={profile?.data?.deliveryPartners} isSelectable={false} data={profile} />}

                <div className="mt-6 text-right">
                    <Button onClick={() => setOpenNewAddress(true)}>{t("Nuovo indirizzo")}</Button>
                </div>
            </div>
        </>
    );
}
