import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, usePagination, Column } from "react-table";
import { Button } from "../../Button";

type PreviewSemiramisComplaintsTableProps = {
    data: any;
};

export default function PreviewSemiramisComplaintsTable({ data: propsData }: PreviewSemiramisComplaintsTableProps) {
    const { t } = useTranslation();

    // table data
    const data = useMemo(
        () => {
            return [propsData];
        },
        [propsData?.items]
    );

    const columns: Array<Column<any>> = useMemo(
        () => [
            {
                Header: t("Codice articolo").toString(),
                accessor: "code",
                disableSortBy: true,
                className: "text-left py-6 px-16",
                Cell: () => {
                    return <div className="w-full flex flex-col gap-6">

                        <p className="font-bold">{t("Codice articolo")}:{" "}
                            <span className="font-light">{propsData?.item_code || "-"}</span>
                        </p>


                        <p className="font-bold">
                            {t("Classificazione articolo")}:{" "}
                            <span className="font-light">{propsData?.item_priceclassification || "-"}</span>
                        </p>

                        {
                            propsData?.complaint_classification_five?.description &&
                            <p className="font-bold">
                                {t("Soluzione")}:{" "}
                                <span className="font-light">
                                    {propsData.complaint_classification_five.description}
                                </span>
                            </p>
                        }
                        {/* Add note */}
                        {/* <p className="font-bold">
                            {t("Note")}:{" "}
                            <span className="font-light">
                                {propsData?.complaint_classification_five?... || "-"}
                            </span>
                        </p> */}
                    </div>;
                }
            },
        ],
        [t]
    );

    const {
        getTableProps,
        getTableBodyProps,
        page,
        prepareRow,
    } = useTable({ columns, data, initialState: { pageSize: 100 } }, useSortBy, usePagination);

    return (
        <div className='border'>
            <table {...getTableProps()} className="w-full">
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} className='odd:bg-tertiary'>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps({ className: (cell.column as any).className })}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
