import { useQuery } from "@tanstack/react-query";
import client from "../client";

export const useComplaintSubTypes = () => {
    const getComplaintSubTypes = async () => {
        const res = await client.get("complaint-classifications-one/sub-types");

        return res.data.data.map((complaintSubType: any) => {
            return { ...complaintSubType, label: `${complaintSubType.description}` };
        });
    };

    // react query
    const { data, isLoading, isFetching } = useQuery(["complaintSubTypes"], () => getComplaintSubTypes(), {
        refetchOnWindowFocus: false,
        retry: false,
    });

    return { data, isLoading, isFetching };
};