import { useTranslation } from "react-i18next";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Title } from "../components/Title";
import Complaint from "./Complaint";
import { Tab, Tabs } from "../components/Tabs";
import Complaints from "./Complaints";
import ComplaintDetails from "./ComplaintDetails";
import { useEffect, useState } from "react";
import { Slide, ToastContainer } from "react-toastify";

export default function Assistance() {
    const { t } = useTranslation();
    const location = useLocation();

    const [title, setTitle] = useState("Assistenza");

    const tabs: Tab[] = [{ title: "I miei reclami", to: "/assistance", isVisibile: true }, { title: "Reclamo", to: "/assistance/complaint", isVisibile: true }];

    useEffect(() => {
        if (tabs.find((e) => e.to === location.pathname)) {
            setTitle("Assistenza");
        }
    }, [location]);

    return (
        <div className="py-10 px-8 2xl:px-28">
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />

            <Title variant={Title.variant.primary}>{t(title)}</Title>

            {tabs.find((e) => e.to === location.pathname) && <Tabs tabs={tabs} />}

            <Routes>
                <Route path='/' element={<Complaints />} />
                <Route path='/complaint' element={<Complaint/>} />
                <Route path="/complaint/:id" element={<ComplaintDetails setTitle={setTitle}/>} />
            </Routes>
        </div>
    );
}
