import { useTranslation } from "react-i18next";
import { useShippingContext } from "../../hooks/use-context/useShippingContext";

export default function RothoNotes() {
    const { t } = useTranslation();

    const { rotho_notes, setRothoNotes } = useShippingContext();

    const handleNotesChange = (e: any) => {
        setRothoNotes(e.target.value);
    };

    return (
        <div className="mb-8 mt-20">
            <label>{t("Note per rothoblaas")}</label>

            <textarea
                value={rotho_notes}
                className="placeholder:text-xs"
                placeholder={t("Es. Fatturazione separata...")}
                rows={5}
                maxLength={255}
                onChange={e => handleNotesChange(e)}
            />
        </div>
    );
}
