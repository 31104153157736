import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../client";

type CartIdParam = {
    cart_id: number
};

type ChangeNameParams = {
    cart_id: number,
    cart_name: string
};

type UpdateCartParams = {
    cart_id: number,
    body: {
        customer_code?: string,
        name?: string,
        client_reference?: string,
        kind_of_pricing?: string | null
    }
};

export default function useCartsMutations() {
    const queryClient = useQueryClient();

    // SAVE CART
    const saveCart = useMutation(async (params: CartIdParam) => {
        return await client.post("saved-carts", params);
    }, { onSuccess: () => { queryClient.invalidateQueries(["carts"]); } });

    // CHANGE CART'S NAME
    const changeCartName = useMutation(async (params: ChangeNameParams) => {
        return await client.put("saved-carts", params);
    }, { onSuccess: () => { queryClient.invalidateQueries(["carts"]); } });

    // UPDATE CART 
    const updateCart = useMutation(async (params: UpdateCartParams) => {
        return await client.put(`carts/${params.cart_id}`, params.body);
    }, { onSuccess: () => { queryClient.invalidateQueries(["cart"]); } });

    // DELETE CART
    const deleteCart = useMutation(async (params: CartIdParam) => {
        return await client.delete(`/carts/${params.cart_id}`);
    }, { onSuccess: () => { queryClient.invalidateQueries(["carts"]); } });

    return { saveCart, updateCart, deleteCart, changeCartName };
}