// Card.jsx
import { ReactNode } from "react";
import classnames from "classnames";
// Icons
import { FaUser, FaUsers, FaRegHandshake, FaRegUser } from "react-icons/fa";
import { BiBadgeCheck, BiHelpCircle } from "react-icons/bi";
import { BsCardChecklist, BsCart, BsCartCheck } from "react-icons/bs";
import { AiOutlineFile, AiOutlineLock, AiOutlineTag, AiOutlineHome } from "react-icons/ai";
import { MdOutlineLocalShipping } from "react-icons/md";
import { NavLink } from "react-router-dom";

enum Variants {
    header = "header",
}

enum Icon {
    cart,
    cartDownload,
    tag,
    ribbon,
    user,
    users,
    hands,
    boxCheck,
    truck,
    note,
    lock,
    info,
    home,
    profile
}

type Props = {
    url: string;
    icon: Icon;
    title?: string;
    highlight: boolean;
    children?: ReactNode;
    variants?: string;
};

export function Card(props: Props) {
    const { title, children, url, icon, highlight, variants } = props;

    let iconBtn = undefined;
    switch (icon) {
        case Icon.cart: iconBtn = <BsCart />;
            break;
        case Icon.cartDownload: iconBtn = <BsCartCheck />;
            break;
        case Icon.tag: iconBtn = <AiOutlineTag />;
            break;
        case Icon.ribbon: iconBtn = <BiBadgeCheck />;
            break;
        case Icon.user: iconBtn = <FaUser />;
            break;
        case Icon.users: iconBtn = <FaUsers />;
            break;
        case Icon.lock: iconBtn = <AiOutlineLock />;
            break;
        case Icon.hands: iconBtn = <FaRegHandshake />;
            break;
        case Icon.boxCheck: iconBtn = <BsCardChecklist />;
            break;
        case Icon.truck: iconBtn = <MdOutlineLocalShipping />;
            break;
        case Icon.note: iconBtn = <AiOutlineFile />;
            break;
        case Icon.info: iconBtn = <BiHelpCircle />;
            break;
        case Icon.home: iconBtn = <AiOutlineHome />;
            break;
        case Icon.profile: iconBtn = <FaRegUser />;
            break;
    }

    return (
        <NavLink
            to={url}
            className={({ isActive }) => classnames("flex items-center gap-x-4 text-sm border-gray-200 hover:bg-secondary hover:bg-opacity-5", {
                "bg-secondary bg-opacity-5" : isActive || highlight,
                "border p-6": variants !== Variants.header
            })}
        >
            <div className="flex items-start">
                {iconBtn && <span className="text-4xl text-secondary bg-secondary bg-opacity-5 p-4 rounded-md">{iconBtn}</span>}
            </div>
            <div className="flex flex-col">
                {title && <h4 className={`text-lg font-bold ${variants !== Variants.header ? "font-bold" : "font-normal"}`}>{title}</h4>}
                <p className="font-light">{children}</p>
            </div>
        </NavLink>
    );
}

Card.defaultProps = {
    icon: null,
    highlight: false
};

Card.variants = Variants;
Card.icon = Icon;
