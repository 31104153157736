import client from "../api/client";

export const checkSessionNow = async () => {
    const auth_token = JSON.parse(localStorage.getItem("user") ?? "{}")?.auth_token;
    if (auth_token) {
        // console.log("checkSessionNow");
        await client.get("user/self-check/" + auth_token, { headers: { "Authorization": `Bearer ${auth_token}` } });
    }
};

export const checkSession = (): any => {
    document.addEventListener("visibilitychange", async () => {
        // console.log("checkSession", document.visibilityState);
        if (document.visibilityState === "visible") {
            checkSessionNow();
        }
    });
};
