import { useNavigate, useParams } from "react-router-dom";
import useComplaint from "../api/complaints/useComplaint";
import { BsCheckCircle } from "react-icons/bs";
import { Title } from "../components/Title";
import { useTranslation } from "react-i18next";
import { Button } from "../components/Button";

function ComplaintSent() {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    // const { resetShippingContext } = useShippingContext();

    const { complaint } = useComplaint(id);

    // useEffect(() => {
    //     resetShippingContext();
    // }, [resetShippingContext]);

    if (!complaint) return null;

    return (
        <div className="py-10 px-8 2xl:px-28">
            {/* {(complaint && !complaint?.data?.blocked) ? <complaintSuccess complaint={complaint} /> : <complaintBlocked complaint={complaint} />} */}
            <div className='flex flex-col items-center h-full'>
                <div className="mb-8">
                    <BsCheckCircle className='text-secondary text-[50px] block m-auto mb-4'/>
                    <Title variant={Title.variant.secondary}>{t("Grazie {{customer_name}}", { customer_name: complaint?.data?.customer_name })}</Title>
                </div>

                <div className="w-4/5 space-y-6 border p-6 font-light mb-4">
                    <p>{t("Il tuo feedback ci aiuta a offrire un'esperienza migliore. Vogliamo che i nostri prodotti e servizi mantengano alti standard e siamo certi queste informazioni andranno realmente a beneficio dei futuri acquisti.")}</p>
                    <p>{t("Il tuo reclamo {{complaint_number}} è stato inviato correttamente.", { complaint_number: complaint?.data?.semiramis_number })}</p>
                    <p>{t("Sarà nostra premura aggiornarti quanto prima in merito a questa segnalazione.")}</p>
                </div>

                <div className="flex flex-col gap-y-2 mt-8">
                    <div className="text-center">{t("Rothoblaas team")}</div>
                    <Button variant={Button.variant.text} onClick={() => navigate("/assistance")}>{t("Torna alla pagina di assistenza")}</Button>
                </div>
            </div>
        </div>
    );
}

export default ComplaintSent;
