import { useQuery } from "@tanstack/react-query";
import client from "../../client";

type usePricelistParams = {
    "page": number,
    "filter[customer_code]"?: string,
    "filter[search]"?: string,
    "filter[priceclass]"?: string,
};

export default function usePriceList(params: usePricelistParams) {
    // GET PRICE LIST
    const fetchPriceList = async (params: usePricelistParams) => {
        const res = await client.get("reserved-conditions/price-list", { params });
        return res.data;
    };

    const { data: priceList, isLoading, isFetching, error } = useQuery(["price-list", params],
        () => fetchPriceList(params),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
            enabled: !!params["filter[customer_code]"],
            retry: 0,
        }
    );

    return { priceList, isLoading, isFetching, error };
}