import { useCallback, useState } from "react";
import { CURRENCY_DECIMALS } from "../utils/getCurrencyDecimals";
import { useMarginCalculatorContext } from "./use-context/useMarginCalculatorContext";

function useMarginCalculationMethods() {

    const { setToolMarginiItems } = useMarginCalculatorContext();
    const [skipPageReset, setSkipPageReset] = useState(false);

    /**
     *
     * @param currency
     */
    const getCurrencyDecimals = useCallback((currency: any): number => {
        if (currency in CURRENCY_DECIMALS) {
            return CURRENCY_DECIMALS[currency];
        }
        return 2;
    }, []);

    // add item to toolMargini state
    const addItem = (items: any, qty: number) => {

        let discount_1 = parseFloat(items?.itemSC1?.pricevalue);
        if (isNaN(discount_1)) {
            discount_1 = 0;
        }

        let discount_2 = parseFloat(items?.itemSC2?.pricevalue);
        if (isNaN(discount_2)) {
            discount_2 = 0;
        }
        const price_discount_type = items?.item?.pricediscounttype;

        const price = parseFloat(items?.item?.pricevalue);
        const netPrice = calculateNetPrice(price, qty, discount_1, discount_2, getCurrencyDecimals(items.selectedItem.currency));

        setToolMarginiItems(prevItems => [{ ...items.selectedItem, qty: qty, discount_1: discount_1.toFixed(2), discount_2: discount_2.toFixed(2), pricevalue: price, netPrice: netPrice, price_discount_type }, ...prevItems]);
    };

    const deleteRow = (rowIndex: any) => {
        setSkipPageReset(true);
        setToolMarginiItems(old =>
            old.filter((row, index) => {
                if (index !== rowIndex) {
                    return row;
                }
                return false;
            })
        );
    };

    /**
     *
     * @param n
     * @param d
     */
    const semiramisRound = useCallback((n: number, d: number) => {
        const multiplier = Math.pow(10, d);
        // console.log('- BEGIN ------');
        // console.log("number", n);
        // console.log("multiplier", multiplier);

        // js stranger things, arrotondo a 6 decimali per evitare il problema del .0000000000001
        n = Math.round((n + Number.EPSILON) * 1000000) / 1000000;
        //console.log("number QWE", n);

        let v = n * multiplier;
        //let tempMod = v % 1;
        //console.log(tempMod);
        // ri-arrotondo dopo il calcolo
        v = Math.round((v + Number.EPSILON) * 100000) / 100000;
        //console.log(v);

        const dec = parseInt(`${(v % 1) * 10}`, 10);
        //console.log(v, dec);

        if ([0, 1, 2, 3, 4, 5].indexOf(dec) >= 0) {
            v = parseInt(`${v}`, 10);
        } else {
            v = parseInt(`${v + 1}`, 10);
        }

        // console.log('-------');
        // console.log(v, multiplier);
        // console.log(v / multiplier);
        // console.log(Math.floor(0.456));

        //console.log('- END ------');
        return (v / multiplier);
    }, []);

    const handleNumberDecimals = (num: number, dec: number) => {
        return num.toFixed(dec).replace(".", ",");
    };

    /**
     *
     * @param price
     * @param qty
     * @param discount_1
     * @param discount_2
     * @param currencyDecimals
     */
    const calculateNetPrice = useCallback((price: number, qty: number, discount_1: number, discount_2: number, currencyDecimals: number) => {

        if (isNaN(discount_1)) {
            discount_1 = 0;
        }
        if (isNaN(discount_2)) {
            discount_2 = 0;
        }
        if (isNaN(price)) {
            price = 0;
        }
        if (isNaN(qty)) {
            qty = 0;
        }

        // console.log("price", price);
        // console.log("qty", qty);

        let total = (price * qty);
        // console.log("total", total);
        total -= total / 100 * discount_1;
        total = Math.round((total + Number.EPSILON) * 100000) / 100000;
        // console.log("total 2", total);
        total -= total / 100 * discount_2;
        total = Math.round((total + Number.EPSILON) * 100000) / 100000;
        // console.log("total 3", total);

        // round prima della divisione
        total = semiramisRound(total, currencyDecimals);
        // console.log("rounded total", total);

        return total / qty;
    }, [semiramisRound]);

    // update tool margini state when editable cell changes
    const updateTableData = (rowIndex: any, columnId: any, value: any) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);

        if (columnId === "discount_1" || columnId === "discount_2") {
            if (value >= 100) {
                value = 99;
            } else if (value < 0 || isNaN(value) || value === "") {
                value = 0;
            }
            const n = parseFloat(value).toFixed(2);
            value = n;
        }

        setToolMarginiItems(old =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };

    return { skipPageReset, addItem, deleteRow, updateTableData, semiramisRound, calculateNetPrice, handleNumberDecimals, getCurrencyDecimals };
}

export default useMarginCalculationMethods;
