import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export type Tab = {
    to: string;
    title: string;
    isVisibile: boolean;
};

type Props = {
    className?: string;
    tabs: Tab[];
};

export function Tabs({ className, tabs }: Props) {
    const { t } = useTranslation();

    const classes = classNames(
        "flex flex-col relative w-full mb-10",
        className
    );

    return (
        <div className={classes}>
            <div className="flex overflow-x-auto whitespace-nowrap border-b">
                {tabs.map((tab, i) => {            
                    return (
                        tab.isVisibile && (
                            <NavLink
                                key={i}
                                to={tab.to}
                                end={true}
                                className={(navData: any) => {
                                    return navData.isActive
                                        ? "bg-secondary bg-opacity-5 h-12 border-b-2 border-b-secondary cursor-pointer flex items-center px-8"
                                        : "cursor-pointer flex items-center px-8 h-12";
                                }}
                            >
                                <div className="font-normal uppercase">
                                    {t(tab.title)}
                                </div>
                            </NavLink>
                        )
                    );
                })}
            </div>
            {/* <hr className="absolute bottom-[0.5px] z-0 w-full bg-tertiary" /> */}
        </div>
    );
}
