import { useTranslation } from "react-i18next";
import { Button } from "../components/Button";
import { Title } from "../components/Title";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useProfileMutations from "../api/profile/useProfileMutations";
import { Loader } from "../components/Loader";
import { useNavigate } from "react-router-dom";
import Table from "../components/Table";
import { getCurrencyDecimals } from "../utils/getCurrencyDecimals";
import { useAuthContext } from "../hooks/use-context/useAuthContext";
import { convertMiliseconds, getExpiredDays } from "../utils/utils";

export default function AdministrativeInfo({ data: profile }: any) {
    const { t } = useTranslation();
    const { user, roles } = useAuthContext();
    const navigate = useNavigate();

    // state
    const [isEditing, setIsEditing] = useState<boolean>(false);

    // react form hook
    const { register, handleSubmit, reset, formState: { isDirty, dirtyFields } } = useForm();

    // react query
    const { updateProfile } = useProfileMutations();

    const numberFormat = profile ? new Intl.NumberFormat(profile?.data?.language_id, { style: "currency", currency: profile?.data?.currency, minimumFractionDigits: getCurrencyDecimals(profile?.data?.currency), maximumFractionDigits: getCurrencyDecimals(profile?.data?.currency), }) : null;

    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

    const taxIdNumber = profile?.data?.finance?.taxidnumber ? profile?.data?.finance?.taxidnumber : (profile?.data?.finance?.localtaxidnumber ? profile?.data?.finance?.localtaxidnumber : "");

    const onSubmit = async (data: any) => {
        if (isDirty) {
            const mappedData: any = {};
            Object.keys(dirtyFields).map((field: any) => {
                if (!mappedData[field]) {
                    mappedData[field] = {};
                }

                mappedData[field] = data[field];
            });

            await updateProfile.mutateAsync({ customer_code: profile?.data?.code, administrativeInfo: mappedData });
            setIsEditing(false);
            navigate("/profile-sent");
        }
    };

    const invoicesColumns = [
        {
            Header: t("Numero fattura").toString(),
            accessor: "number",
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return (
                    <div>
                        <div className="font-normal">
                            {props?.row.original.number}
                        </div>
                        <div>{t(props?.row.original.type)}</div>
                    </div>
                );
            },
        },
        {
            Header: t("Data scadenza").toString(),
            accessor: "due_date",
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return dateFormat.format(
                    new Date(props?.row.original.due_date)
                );
            },
        },
        {
            Header: t("Ritardo").toString(),
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return props.row.original.type === "Invoice"
                    ? getExpiredDays(props.row.original.due_date) > 0
                        ? convertMiliseconds(
                            getExpiredDays(props.row.original.due_date),
                            "d"
                        )
                        : ""
                    : "";
            },
        },
        {
            Header: t("Totale").toString(),
            accessor: "amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.amount);
            },
        },
        {
            Header: t("Pagato").toString(),
            accessor: "paid_amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.paid_amount);
            },
        },
        {
            Header: t("Aperto").toString(),
            accessor: "open_amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.open_amount);
            },
        },
    ];

    useEffect(() => {
        if (profile) {
            reset({
                customer_code: profile?.data?.code,
                vat_number: taxIdNumber,
                fiscal_code: profile?.data?.finance?.supplementarytaxidnumber,
                payment_method: profile?.data?.finance?.paymentmethod?.description,
                payment_type: profile?.data?.finance?.paymentterm?.description,
                iban: profile?.data?.finance?.iban,
                pec: profile?.data?.finance?.pec,
                cuu_code: profile?.data?.finance?.cuu,
                ean: profile?.data?.finance?.ean,
                siret: profile?.data?.finance?.siret,
                unpaidexpired: numberFormat?.format(profile?.data?.finance?.unpaidexpired),
                unpaid: numberFormat?.format(profile?.data?.finance?.unpaid),
                customer_ean: profile?.data?.customerFinance?.ean,
                customer_siret: profile?.data?.customerFinance?.siret,
            });
        }
    }, [profile, reset]);

    return (
        <>
            {updateProfile.isLoading && <Loader />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset>
                    <div className="px-6 space-y-6">
                        <Title variant={Title.variant.secondary}>
                            {t("Informazioni amministrative")}
                        </Title>
                        <div className="grid grid-cols-2 gap-6">
                            {
                                profile?.data?.invoice_customer_code && profile?.data?.invoice_customer_code !== profile?.data?.code &&
                                <div className="font-light">
                                    <label htmlFor="invoice_customer_code">{t("Cliente fatturazione")}</label>
                                    <input type="text" className="grid-span-2" id={"invoice_customer_code"} disabled={true} value={`${profile?.data?.invoice_customer_code} - ${profile?.data?.invoice_customer_name}`} />
                                </div>
                            }
                            <div className="font-light">
                                <label htmlFor="vat_number">{t("Partita IVA")}</label>
                                <input {...register("vat_number")} type="text" className="grid-span-1" id={"vat_number"} disabled={!isEditing} />
                            </div>
                            <div className="font-light">
                                <label htmlFor="fiscal_code">{t("Codice fiscale")}</label>
                                <input {...register("fiscal_code")} type="text" className="grid-span-1" id={"fiscal_code"} disabled={!isEditing} />
                            </div>
                            {
                                profile?.data?.country_id === "IT" &&
                                <div className="font-light">
                                    <label htmlFor="cuu_code">{t("Codice univoco")}</label>
                                    <input {...register("cuu_code")} type="text" className="grid-span-1" id={"cuu_code"} disabled />
                                </div>
                            }
                            <div className="font-light">
                                <label htmlFor="iban">{t("IBAN")}</label>
                                <input {...register("iban")} type="text" className="grid-span-1" id={"iban"} disabled={!isEditing} />
                            </div>
                            <div className="font-light">
                                <label htmlFor="payment_method">{t("Metodo di pagamento")}</label>
                                <input {...register("payment_method")} type="text" className="grid-span-1" id={"payment_method"} disabled={true} />
                            </div>
                            <div className="font-light">
                                <label htmlFor="payment_type">{t("Condizione di pagamento")}</label>
                                <input {...register("payment_type")} type="text" className="grid-span-1" id={"payment_type"} disabled={true} />
                            </div>
                            {
                                profile?.data?.country_id === "FR" &&
                                <>
                                    <div className="font-light">
                                        <label htmlFor="customer_ean">{t("EANCODE CLIENT")}</label>
                                        <input {...register("customer_ean")} type="text" className="grid-span-1" id={"customer_ean"} disabled={!isEditing} />
                                    </div>
                                    <div className="font-light">
                                        <label htmlFor="customer_siret">{t("SIRET CLIENT")}</label>
                                        <input {...register("customer_siret")} type="text" className="grid-span-1" id={"customer_siret"} disabled={!isEditing} />
                                    </div>
                                    <div className="font-light">
                                        <label htmlFor="ean">{t("EANCODE CLIENT FACTURATION")}</label>
                                        <input {...register("ean")} type="text" className="grid-span-1" id={"ean"} disabled={true} />
                                    </div>
                                    <div className="font-light">
                                        <label htmlFor="siret">{t("SIRET CLIENT FACTURATION")}</label>
                                        <input {...register("siret")} type="text" className="grid-span-1" id={"siret"} disabled={true} />
                                    </div>
                                </>
                            }
                            <div className="font-light">
                                <label htmlFor="pec">{t("PEC")}</label>
                                <input {...register("pec")} type="text" className="grid-span-1" id={"pec"} disabled={!isEditing} />
                            </div>
                            {

                                roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"]) &&
                                <>
                                    <div className="font-light">
                                        <label htmlFor="unpaidexpired">{t("Insoluto")}</label>
                                        <input {...register("unpaidexpired")} type="text" className="grid-span-1" id={"unpaidexpired"} disabled={true} />
                                    </div>
                                    <div className="font-light">
                                        <label htmlFor="unpaid">{t("Partite aperte")}</label>
                                        <input {...register("unpaid")} type="text" className="grid-span-1" id={"unpaid"} disabled={true} />
                                    </div>
                                </>
                            }
                        </div>
                        {
                            profile &&
                            <Table
                                variants={Table.variants.expandable}
                                label="Elenco partite aperte"
                                data={profile?.data?.open_nav_payments}
                                columns={invoicesColumns}
                                emptyStateMessage="Nessuna fattura aperta"
                            />
                        }
                    </div>
                    <div className="flex w-full justify-end mt-6">
                        {
                            isEditing &&
                            <div className="space-x-4">
                                <Button className="mt-6" isDisabled={!isDirty}>
                                    {t("Invia richiesta modifica")}
                                </Button>
                            </div>
                        }

                        {
                            !isEditing &&
                            <Button
                                className="mt-6"
                                onClick={() => setIsEditing(true)}
                                type="button"
                            >
                                {t("Modifica")}
                            </Button>
                        }
                    </div>
                </fieldset>
            </form>
        </>
    );
}
