import { useQuery } from "@tanstack/react-query";
import client from "../client";

type useOrdersParams = {
    page: number
};

export default function useOrders(params: useOrdersParams, canFetch: boolean = true) {
    // GET OFFERS
    const fetchOrders = async (params: useOrdersParams) => {
        const res = await client.get("orders", { params });
        return res.data;
    };

    const { data: orders, isLoading, isFetching } = useQuery(["orders", params],
        () => fetchOrders(params),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
            enabled: canFetch,
        }
    );

    return { orders, isLoading, isFetching };
}