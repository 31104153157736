import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { useState } from "react";
import { Modal } from "../commom/Modal";
import { CustomerSelect } from "../commom/CustomerSelect";
import { useDiscountTableContext } from "../../hooks/use-context/useDiscountTableContext";

export const DiscountCustomerDetails = ({ customerData, loadCustomersOptions, handleCustomerChange, error }: any) => {
    const { t } = useTranslation();

    //state
    const [isModalOpen, setIsModalOpen] = useState<any>(false);
    const [customerLocalData, setCustomerLocalData] = useState<any>();

    const { editable } = useDiscountTableContext();

    return (
        <>
            <fieldset className="p-6 border">
                <div className="flex items-center justify-between gap-6 mb-6">
                    <div className="uppercase text-xl leading-10 font-semibold">{t("Dati cliente")}</div>
                    {!editable && <Button variant={Button.variant.text} icon={Button.icon.edit} onClick={() => setIsModalOpen(true)}>{t("Modifica cliente")}</Button>}
                </div>
                <div className="font-light">
                    <div>{customerData.name} - {customerData.code} - {customerData.city} - {customerData.ts_fullname}</div>
                    <div>{t("Città")}: {customerData.city}</div>
                    <div>{t("Paese")}: {customerData.country_id}</div>
                    {/* <div>{t("Metodo e condizione di pagamento")}: unknown</div> */}
                    <div>{t("Visualizzazione prezzo nei documenti")}: {parseInt(customerData.kind_of_pricing) !== 1 ? t("netto") : t("lordo")}</div>
                </div>
            </fieldset>

            {/* CHANGE CUSTOMER MODAL */}
            <Modal title={t("Modifica cliente")} open={isModalOpen} onClose={setIsModalOpen}>
                <div className="space-y-2">
                    <CustomerSelect
                        label="Seleziona cliente"
                        loadCustomersOptions={loadCustomersOptions}
                        handleChange={(event: any) => setCustomerLocalData(event)}
                        error={error}
                        selectedCustomer={customerLocalData}
                        setSelectedCustomer={setCustomerLocalData}
                    />

                    <div className="text-right space-x-2 mt-4">
                        <Button variant={Button.variant.text} onClick={() => setIsModalOpen(false)}>{t("Annulla")}</Button>
                        <Button variant={Button.variant.primary} onClick={() => { handleCustomerChange(customerLocalData); setIsModalOpen(false); }}>{t("Procedi")}</Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
