import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import useSearchBarProducts from "../../api/products/useSearchBarProducts";
import { CustomerSelect } from "../commom/CustomerSelect";
import useGetCustomers from "../../api/customers/useGetCustomers";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import classNames from "classnames";
import { ItemSelect } from "../commom/ItemSelect";

type OutstandingOrdersSearchBarProps = {
    setSearchParams: React.Dispatch<React.SetStateAction<searchParams>>
};

type searchParams = {
    "filter[code]"?: string,
    "filter[name]"?: string,
    "filter[technical_sale]"?: string,
};

type formData = {
    "filter[code]"?: string,
    "filter[name]"?: string,
    "filter[technical_sale]"?: string,
};

export default function OutstandingOrdersSearchBar({ setSearchParams }: OutstandingOrdersSearchBarProps) {
    const [formData, setFormData] = useState<formData>({});
    const { t } = useTranslation();

    const customerSelect = useRef<any>();
    const itemCodeSelect = useRef<any>();

    // hooks
    const { roles } = useAuthContext();
    const { loadItemsOptions } = useSearchBarProducts();
    const { loadCustomersOptions } = useGetCustomers();

    const [selectedCustomer, setSelectedCustomer] = useState<any>(null);

    const handleInputsChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (value.length > 0) {
            setFormData((prevFormData: any) => {
                return { ...prevFormData, [name]: value };
            });
        } else {
            const newForm = { ...formData };
            delete newForm[name as keyof formData];
            setFormData(newForm);
        }
    };

    const handleItemSearchChange = (inputValue: any, action: any) => {
        if (action?.action === "select-option") {
            setFormData((prevFormData: any) => {
                return { ...prevFormData, [action?.name]: inputValue?.value?.code };
            });
        } else {
            const newForm = { ...formData };
            delete newForm[action?.name as keyof formData];
            setFormData(newForm);
        }
    };

    // CLIENT
    const handleClientSearchChange = (inputValue: any, action: any) => {
        setSelectedCustomer(inputValue);
        if (action?.action === "select-option") {
            setFormData((prevFormData: any) => {
                return { ...prevFormData, "filter[code]": inputValue?.code };
            });
        } else {
            const newForm = { ...formData };
            delete newForm["filter[code]"];
            setFormData(newForm);
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        setSearchParams(formData);
    };

    const resetForm = () => {
        customerSelect.current.clearValue();
        itemCodeSelect.current.clearValue();
        setFormData({});
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <fieldset className="m-0">
                    <legend className="sr-only"><span>{t("Ricerca")}</span></legend>

                    <div className="space-y-6">
                        {/* CLIENT */}
                        <CustomerSelect
                            label="Seleziona cliente"
                            ref={customerSelect}
                            loadCustomersOptions={loadCustomersOptions}
                            handleChange={handleClientSearchChange}
                            selectedCustomer={selectedCustomer}
                            setSelectedCustomer={setSelectedCustomer}
                        />
                        <div className={classNames("grid grid-cols-1 gap-4 whitespace-nowrap", {
                            "md:grid-cols-2": !roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"]),
                            "md:grid-cols-1": roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"]),
                        })}>
                            <div>
                                <label>{t("Codice articolo")}</label>
                                <ItemSelect
                                    ref={itemCodeSelect}
                                    name="filter[item_code]"
                                    loadItemsOptions={loadItemsOptions}
                                    handleChange={handleItemSearchChange}
                                />
                            </div>
                            {!roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"]) && <div>
                                <label>{t("Technical Salesman")}</label>
                                <input
                                    value={formData["filter[technical_sale]"] ?? ""}
                                    type="text"
                                    name="filter[technical_sale]"
                                    onChange={e => handleInputsChange(e)}
                                    placeholder={t("inserisci technical salesman")}
                                />
                            </div>}
                        </div>
                    </div>

                    <div className="w-1/3 flex gap-x-2 mt-4">
                        <Button variant={Button.variant.primary} size={Button.size.full}>{t("Cerca")}</Button>
                        <Button
                            variant={Button.variant.text}
                            className="text-xs py-0 flex-1 whitespace-nowrap"
                            icon={Button.icon.reset}
                            onClick={resetForm}
                        >
                            {t("reset ricerca")}
                        </Button>
                    </div>
                </fieldset>

            </form >
        </>
    );
}
