import { useQuery } from "@tanstack/react-query";
import client from "../client";

export default function useSemiramisComplaint(complaint_id: string | undefined) {
    const fetchComplaint = async (complaint_id: string | undefined) => {
        const res = await client.get(`semiramis-complaints/${complaint_id}`);
        return res.data;
    };

    // GET SINGLE COMPLAINT
    const { data: complaint, isLoading, isFetching } = useQuery(["semiramis_complaint", complaint_id],
        () => fetchComplaint(complaint_id),
        {
            refetchOnWindowFocus: false,
            enabled: !!complaint_id
        }
    );

    return { complaint, isLoading, isFetching };
}
