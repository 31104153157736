import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import client from "../../../../api/client";
import { ComplaintProductDetails } from "../product-flow/product-detail/ComplaintProductDetails";
// import { ComplaintSelectOrder } from "../product-flow/select-order/ComplaintSelectOrder";
import debounce from "lodash.debounce";
import { useAuthContext } from "../../../../hooks/use-context/useAuthContext";
import { ComplaintDescription } from "../product-flow/complaint-description/ComplaintDescription";
import { ComplaintAttachFiles } from "../product-flow/complaint-attach-files/ComplaintAttachFiles";

export const ServiceFlow = ({ selectedCustomer }: any) => {
    const { user } = useAuthContext();
    const { setValue, watch} = useFormContext();
    const watchShippingOrderId = watch("shipping_order_id");

    // handle item search SELECT change
    const handleItemSearchChange = async (inputValue: any, reactFormOnChange: any) => {
        if (inputValue) {
            reactFormOnChange(inputValue.id);
            setValue("item_priceclassification", inputValue.priceclassification);
        } else {
            setValue("item_id", "");
            setValue("item_priceclassification", null);
        }
    };

    // GET ITEMS
    const getProducts = useCallback(async (value: string) => {
        const params = {
            "filter[search]": value,
            "filter[customer_code]": selectedCustomer?.code ?? user?.customer_code,
            "filter[shipping_order_id]": watchShippingOrderId,
        };

        const res = await client.get("products", { params: params });

        return res.data.data.map((item: any) => {
            return { label: `${item.code} - ${item.description} (${parseInt(item.piecesperpackage, 10)} ${item.piecesperpackageuom})`, ...item };
        });
    }, [watchShippingOrderId]);

    const loadItemsOptions = useMemo(() => {
        return debounce((value: any, callback: any) => {
            getProducts(value).then((options) => callback(options));
        }, 1000);
    }, [getProducts]);

    return (
        <>
            {/* <ComplaintSelectOrder flow="service"/> */}

            <ComplaintProductDetails
                loadItemsOptions={loadItemsOptions}
                handleItemSearchChange={handleItemSearchChange}
                flow="service"
            />

            <ComplaintDescription flow="service"/>

            <ComplaintAttachFiles flow="service"/>
        </>
    );
};
