import { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import SummaryTable from "./SummaryTable";
import { Section } from "../Section";

type ProductsSummaryProps = {
    data: any,
    summary?: any
    availabitiliy?: boolean
};

export default function ProductsSummary({ data, summary, availabitiliy = false }: ProductsSummaryProps) {
    const { roles } = useAuthContext();
    
    // state
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

    // SET HIDDEN COLUMNS
    useEffect(() => {
        if (parseInt(data.data.kind_of_pricing) !== 1 && roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"])) {
            setHiddenColumns(prevValues => [...prevValues, "discount_1", "discount_2", "pricevalue"]);
        }

        if (!data?.data?.isSalesOrder) {
            setHiddenColumns(prevValues => [...prevValues, "status"]);
        }

        if (!availabitiliy) {
            setHiddenColumns(prevValues => [...prevValues, "availability"]);
        }

        if (roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"]) || !data?.data?.isSalesOrder) {
            setHiddenColumns(prevValues => [...prevValues, "reservedqty"]);
        }
    }, [data]);

    return (
        <Section title={"Elenco prodotti"} className={"mb-20"}>
            {
                data && (summary || data?.data?.summary) &&
                <SummaryTable
                    customer={data?.data?.customer}
                    cart={data}
                    items={data?.data?.items}
                    total_products={summary ? summary?.total_products_without_iva : data?.data?.summary?.total_products_without_iva}
                    hiddenColumns={hiddenColumns}
                />
            }
        </Section>
    );
}
