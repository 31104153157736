import { useState, useRef, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from "../components/Button";
import client from "../api/client";
import { useTranslation } from "react-i18next";
import { Message } from "../components/Message";
import { useForm } from "react-hook-form";
import { Loader } from "../components/Loader";
import { useAuthContext } from "../hooks/use-context/useAuthContext";

const SetNewPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const { user } = useAuthContext();

    // state
    const [sent, setSent] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);

    // ref
    const emailRef = useRef<HTMLInputElement>(null!);

    // react form
    const { register, handleSubmit, formState: { errors } } = useForm();

    // set the focus on the email input
    useEffect(() => {
        emailRef?.current?.focus();
    }, []);

    useEffect(() => {
        if (!token) {
            navigate("/");
        }
    }, [token]);

    // axios post that change email and password
    const onSubmit = async (data: any) => {
        try {
            setIsLoading(true);
            const params = JSON.stringify({
                token: token,
                ...data
            });
            setSent(false);
            setSubmitted(false);

            const res = await client.post("reset-password", params);

            if (res.status === 200) {
                setSubmitted(false);
                setSent(true);
            }
            setIsLoading(false);
        } catch (err: any) {
            if ("errors" in err.response.data) {
                setErrorMessage(err.response.data.errors.password[0]);
            } else if (err?.response?.status === 422) {
                const template = (<p className="text-primary-light">{err.response.data.message} <Link className="underline" to={`${process.env.REACT_APP_URL}/reset-password`}>{t("Reimposta password")}</Link></p>);
                setErrorMessage(template);
            } else {
                setErrorMessage(err.response.data.message);
            }
            setSent(false);
            setSubmitted(true);
            setIsLoading(false);
        }
    };


    return (
        <>
            {isLoading && <Loader />}
            {
                token &&
                <div className="flex flex-col items-end h-full bg-magazzino bg-cover">
                    <div className="h-full flex justify-center items-center gap-x-10 bg-black/60 w-full lg:max-w-[40%]">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <fieldset className="w-100 space-y-6 m-10 max-w-screen-sm">
                                <legend className="block w-full">
                                    <span className="block uppercase text-xl text-center font-bold text-white">{t("Reimposta la tua password")}</span>
                                </legend>

                                {submitted && <Message open={submitted} handleCloseMessage={() => { setSubmitted(false); }} variant={Message.variant.error}>{errorMessage}</Message>}

                                {sent && <Message open={sent} variant={Message.variant.success}>{t("Password aggiornata con successo.")} <Link to={"/"} className="underline">{t("Torna alla pagina di login")}</Link></Message>}

                                {
                                    !sent &&
                                    <>
                                        <div>
                                            <label htmlFor="email" className="text-white">{t("Indirizzo e-mail")}</label>
                                            <input
                                                {...register("email", { required: `${t("- questo campo è obbligatorio")}` })}
                                                type="email"
                                                id="email"
                                            />
                                            {errors ? <p className="text-xs text-rose-500">{errors?.email?.message}</p> : null}
                                        </div>

                                        <div>
                                            <label htmlFor="password" className="text-white">
                                                {t("Digitare la nuova password")}
                                            </label>
                                            <input
                                                {...register("password",
                                                    {
                                                        required: `${t("- questo campo è obbligatorio")}`,
                                                        minLength: { value: 8, message: t("La password deve contenere alemno 8 caratteri") }
                                                    })
                                                }
                                                type="password"
                                                id="password"
                                            />
                                            {errors ? <p className="text-xs text-rose-500">{errors?.password?.message}</p> : null}
                                        </div>

                                        <div>
                                            <label htmlFor="password_confirmation" className="text-white">
                                                {t("Digita nuovamente la password per confermare")}
                                            </label>
                                            <input
                                                {...register("password_confirmation",
                                                    {
                                                        required: `${t("- questo campo è obbligatorio")}`,
                                                        minLength: { value: 8, message: t("La password deve contenere alemno 8 caratteri") }
                                                    })
                                                }
                                                type="password"
                                                id="password_confirmation"
                                            />
                                            {errors ? <p className="text-xs text-rose-500">{errors?.password_confirmation?.message}</p> : null}
                                        </div>

                                        <div>
                                            <Button size={Button.size.full} variant={Button.variant.tertiary}>{t("Invia")}</Button>
                                        </div>
                                    </>
                                }

                            </fieldset>

                        </form>
                    </div>
                </div>
            }
        </>
    );
};

export default SetNewPassword;
