import { useQuery } from "@tanstack/react-query";
import client from "../client";

export const useComplaintTypes = () => {
    const getComplaintTypes = async () => {
        const res = await client.get("complaint-classifications-one");

        return res.data.data.map((complaintType: any) => {
            return { ...complaintType, label: `${complaintType.description}` };
        });
    };

    // react query
    const { data, isLoading, isFetching } = useQuery(["complaintTypes"], () => getComplaintTypes(), {
        refetchOnWindowFocus: false,
        retry: false,
    });

    return { data, isLoading, isFetching };
};
