import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../client";

type CartIdParam = {
    cart_id: number
};

export default function useCheckCartPricesMutations() {
    const queryClient = useQueryClient();

    // SAVE CART
    const { data: updatedPrices, mutateAsync: updatePrices, isLoading: updatePricesLoading } = useMutation(async (params: CartIdParam) => {
        return await client.put(`carts/${params.cart_id}/check-prices`);
    }, { onSuccess: () => { queryClient.invalidateQueries(["cart"]); } });

    return { updatedPrices, updatePrices, updatePricesLoading };
}