import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../hooks/use-context/useAuthContext";
import client from "../api/client";

const MicrosoftLogin = () => {
    const navigate = useNavigate();
    // get token from query
    const [searchParams] = useSearchParams();
    const auth_token = searchParams.get("t");
    const { i18n } = useTranslation();

    // context
    const { setUser } = useAuthContext();

    // get user info
    const getUser = useCallback(async () => {

        const res = await client.get("user/self/" + auth_token, { headers: { "Authorization": `Bearer ${auth_token}` } });

        if (res?.data?.user) {

            setUser({ ...res.data.user, auth_token: auth_token, constants: res?.data?.constants });

            // set user context and session storage
            await i18n.changeLanguage(res.data.user.interface_language_id);

            // redirect to dashboard or intended route if successfull
            const intendedRoute: string | null = sessionStorage.getItem("intended_route");
            sessionStorage.removeItem("intended_route");

            intendedRoute ? navigate(intendedRoute) : navigate("/dashboard");
        } else {
            // redirect back to login
            navigate("/");
        }

    }, [auth_token, navigate, setUser, i18n]);

    //get user info if auth_token is present otherwise redirect user to login
    useEffect(() => {
        if (auth_token) {
            getUser();
        } else {
            navigate("/");
        }
    }, [getUser, auth_token, navigate]);

    return (
        <div>Loading...</div>
    );
};

export default MicrosoftLogin;
