import { useQuery } from "@tanstack/react-query";
import client from "../client";

export default function useSuggestedProducts(cart_id?: string) {

    const getCartRelatedProducts = async (id: string | undefined) => {
        const res = await client.get(`carts/${id}/related-products`);
        return res.data.data;
    };

    // react query
    const {
        data,
        isLoading,
        isFetching
    } = useQuery(["suggested-products", cart_id], () => getCartRelatedProducts(cart_id), {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: !!cart_id
    });

    return {
        data,
        isLoading,
        isFetching
    };
}
