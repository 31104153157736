import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useShippingContext } from "../../hooks/use-context/useShippingContext";
import { InfoTooltip } from "../commom/InfoTooltip";

type ClientReferenceProps = {
    // disabled?: boolean,
    clientReference: string
};

export default function ClientReference({ clientReference }: ClientReferenceProps) {
    const { client_reference, setClientReference } = useShippingContext();
    const { t } = useTranslation();

    const handleNotesChange = (e: any) => {
        setClientReference(e.target.value);
    };

    useEffect(() => {
        setClientReference(clientReference);
    }, [clientReference, setClientReference]);

    return (
        <div>
            <div className="flex items-center pb-1">
                <label className="p-0">{t("Nota personalizzata ({{chars}} caratteri)", { chars: 100 })}:</label>
                <InfoTooltip>
                    {t("Riferimento che verrà riportato su conferma ordine, bolla e fattura")}
                </InfoTooltip>
            </div>

            {/* TODO: max characters?  */}
            <input
                type="text"
                value={client_reference ?? ""}
                className="placeholder:text-xs font-light"
                placeholder={t("Nome cantiere, numero ordine o acquisto ...")}
                onChange={e => handleNotesChange(e)}
                maxLength={100}
            // disabled={disabled}
            />
        </div>
    );

}

