import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "../client";

export default function useCheckCartPrices(cart_id: string | undefined, is_dirty: boolean) {
    const queryClient = useQueryClient();

    const checkPrices = async (cart_id: string | undefined) => {
        const res = await client.get(`carts/${cart_id}/check-prices`);
        return res.data;
    };

    const twelveHoursInMs = 1000 * 60 * 60 * 12;

    const { data: priceChanges, isLoading, isFetching } = useQuery(["check-prices", cart_id],
        () => checkPrices(cart_id),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: twelveHoursInMs,
            enabled: !!cart_id && is_dirty,
            onSuccess: () => {
                queryClient.invalidateQueries(["cart"]);
            }
        }
    );



    return { priceChanges, isLoading, isFetching };
}