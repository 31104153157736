/* eslint-disable react/display-name */
import { Button } from "../Button";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { FaFileImport } from "react-icons/fa";
import { productsListImport } from "../../utils/productsListImport";
import useCartImport from "../../api/carts/useCartImport";
import { Loader } from "../Loader";
import { toast } from "react-toastify";
import { ItemSelect } from "./ItemSelect";
import { Section } from "../Section";
import client from "../../api/client";
import { downloadResource } from "../../constants/DownloadResource";

export const AddProductForm = forwardRef(({ qty, setQty, discount1, setDiscount1, discount2, setDiscount2, openItem, handleItemSearchInputChange, handleItemSearchChange, loadItemsOptions, selectedCustomer, selectedItem, disableAddButton, data }: any, ref: any) => {
    const { roles } = useAuthContext();
    const { t } = useTranslation();

    // refs
    const searchSelect: any = useRef();
    const qtyInput: any = useRef();
    const importRef: any = useRef();

    // state
    const [isQtyValid, setIsQtyValid] = useState(true);

    const { cartImport } = useCartImport();

    useImperativeHandle(ref, () => ({
        searchSelectfocus: () => {
            searchSelect.current.focus();
        },
        searchSelectClearValue: () => {
            searchSelect.current.clearValue();
        },
        qtyInputFocus: () => {
            qtyInput.current.focus();
        },
        qtyInputSelect: () => {
            qtyInput.current.select();
        },
    }));

    const handleFocus = (e: any) => e.target.select();

    const handleQtyChange = (e: any) => {
        setQty(e.target.value);
        checkQty(e);
    };

    const handleDisocunt1Change = (e: any) => {
        setDiscount1(e.target.value);
    };

    const handleDisocunt2Change = (e: any) => {
        setDiscount2(e.target.value);
    };

    const handleImportXlsx = async (e: any) => {
        const file = e.target.files[0];
        try {
            const body = await toast.promise(productsListImport(file, data?.data?.items, t), {
                error: {
                    render({ data }: any) {
                        return t(data);
                    }
                }
            });

            await toast.promise(cartImport.mutateAsync({ cart_id: data?.data?.id, body: body }), {
                success: t("File importato con successo"),
                error: {
                    render({ data }: any) {
                        return t(data?.response?.data?.message);
                    }
                }
            });

            importRef.current.value = null;
        } catch (err: any) {
            importRef.current.value = null;
        }
    };

    //check if qty is valid
    const checkQty = (e: any) => {
        if (selectedItem)
            if (e.target.value % selectedItem?.minsellable !== 0) {
                setIsQtyValid(false);
            } else {
                setIsQtyValid(true);
            }
    };

    // DOWNLOAD IMPORT TEMPLATE
    const downloadImportTemplate = async (ev: any) => {
        ev.preventDefault();

        const res = await client.get("download/import-template", {
            headers: {
                responseType: "blob"
            }
        });

        // mode download
        if (res.data && res.data.content && res.data.name && res.data.type) {
            const filetype = res.data.type;
            const content = res.data.content;
            const filename = res.data.name;

            downloadResource(content, filename, filetype);
        }

    };

    return (
        <Section title={"Aggiungi prodotto"} className={"mb-10"}>
            {cartImport.isLoading && <Loader />}

            <fieldset>
                <legend className="sr-only">{t("Aggiungi prodotto")}</legend>
                <div className='mt-6'>
                    {/* ITEM SELECT */}
                    <div className="flex flex-wrap items-end gap-4">
                        {/* item selection row */}
                        <div className={!roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ? "w-full" : "flex-1"}>
                            <label>{t("Inserisci codice articolo o nome prodotto")}</label>
                            <ItemSelect
                                ref={searchSelect}
                                name="item_code"
                                loadItemsOptions={loadItemsOptions}
                                handleChange={handleItemSearchChange}
                                disabled={!selectedCustomer && !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"])}
                            />
                        </div>

                        {/* QTY */}
                        <div className={!roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ? "flex-1 relative" : "flex-none relative"}>
                            <label htmlFor="">{t("Quantità")}</label>
                            <input
                                onChange={e => { handleQtyChange(e); }}
                                onFocus={handleFocus}
                                ref={qtyInput}
                                className={`
                                        ${!selectedItem ? "cursor-not-allowed text-disabled" : ""}
                                        ${!isQtyValid ? "border-rose-500 outline-none rounded" : ""}
                                    `}
                                min={selectedItem?.minsellable}
                                step={selectedItem?.minsellable}
                                value={qty}
                                type="number"
                                disabled={!selectedItem || disableAddButton}
                                required
                            />
                            <div className="absolute mt-1 text-xs opacity-50 font-light">{selectedItem?.uom_description}</div>
                        </div>

                        {
                            !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) &&
                            <>
                                <div className='flex-1'>
                                    <label htmlFor="">{t("Sconto 1 (%)")}</label>
                                    <input
                                        type="number"
                                        onChange={(e) => handleDisocunt1Change(e)}
                                        onFocus={handleFocus}
                                        value={discount1}
                                        min={0}
                                        max={99}
                                        step={"0.01"}
                                        disabled={!selectedItem || disableAddButton}
                                        className={`
                                            ${!selectedItem ? "cursor-not-allowed text-disabled" : ""}
                                        `}
                                    />
                                </div>

                                <div className='flex-1'>
                                    <label htmlFor="">{t("Sconto 2 (%)")}</label>
                                    <input
                                        type="number"
                                        onChange={(e) => handleDisocunt2Change(e)}
                                        onFocus={handleFocus}
                                        value={discount2}
                                        min={0}
                                        max={99}
                                        step={"0.01"}
                                        disabled={!selectedItem || disableAddButton}
                                        className={`
                                            ${!selectedItem ? "cursor-not-allowed text-disabled" : ""}
                                        `}
                                    />
                                </div>
                            </>
                        }

                        <div className={!roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ? "flex-1 self-end" : "flex-none self-end"}>
                            <Button variant={Button.variant.primary} size={Button.size.full} isDisabled={!selectedItem || disableAddButton}>
                                {t("Aggiungi")}
                            </Button>
                        </div>
                    </div>

                    {
                        // eslint-disable-next-line @stylistic/array-bracket-spacing
                        !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) &&
                        <div className="mt-8 space-x-4">
                            <label className={`${selectedCustomer ? "cursor-pointer" : "opacity-50 cursor-not-allowed"} inline-flex items-center justify-center px-10 py-2 uppercase bg-secondary text-white hover:bg-sky-600 active:scale-95`} htmlFor="file_upload"
                            >
                                <FaFileImport className="mr-2" />
                                Import XLS
                            </label>
                            <input type="file"
                                className="hidden"
                                id="file_upload"
                                ref={importRef}
                                onChange={(e) => handleImportXlsx(e)}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx, .csv, text/csv, .numbers"
                                disabled={!selectedCustomer}
                            />

                            <Button type="button" variant={Button.variant.text} onClick={downloadImportTemplate}>{t("Scarica modello")}</Button>
                        </div>
                    }
                </div>
            </fieldset>
        </Section>
    );
});
