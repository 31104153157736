import { ReactNode } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Tooltip } from "@reach/tooltip";
import classNames from "classnames";

import "@reach/tooltip/styles.css";

type InfoTooltipProps = {
    children: ReactNode
    className?: string,
    color?: string,
};

export const InfoTooltip = ({ children, color = "#6b7280", className = "" }: InfoTooltipProps) => {
    return (
        <Tooltip
            label={children}
            style={{
                backgroundColor: "#F1F5F6",
                color: color,
                borderRadius: "3px",
                fontWeight: "300",
                marginRight: "20px",
                zIndex: 50
            }}
        >
            <span>
                <AiOutlineInfoCircle color={color} className={classNames(`text-sm text-gray-[${color}]`, className)}/>
            </span>
        </Tooltip>
    );
};
