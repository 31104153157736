import { useTranslation } from "react-i18next";
import { BiErrorAlt } from "react-icons/bi";
import { RiErrorWarningLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { getCurrencyDecimals } from "../../utils/getCurrencyDecimals";
import { Button } from "../Button";
import { Title } from "../Title";

export const OrderBlocked = ({ order }: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { roles } = useAuthContext();

    const goToOrders = () => {
        navigate("/orders");
    };

    const goToOrder = () => {
        navigate(`/orders/${order?.data?.id}`);
    };

    const numberFormat = order?.data?.customer ? new Intl.NumberFormat(order?.data?.customer?.language_id, { style: "currency", currency: order?.data?.customer?.finance.currency, minimumFractionDigits: getCurrencyDecimals(order?.data?.customer?.finance.currency), maximumFractionDigits: getCurrencyDecimals(order?.data?.customer?.finance.currency) }) : null;

    const totalBlockedAmount = order?.data?.customer?.unprocessedOrders?.reduce((acc: number, obj: any) => acc + parseFloat(obj.total_net_price), 0);
    const residualCredit = order?.data?.customer?.finance?.creditlimit - order?.data?.customer?.finance?.unpaid - order?.data?.customer?.finance?.undelivered - order?.data?.customer?.finance?.deliverednotinvoiced - order?.data?.customer?.finance?.invoicednottransferred - totalBlockedAmount;

    return (
        <div className='flex flex-col items-center h-full'>
            <div className='mb-8'>
                <RiErrorWarningLine className='text-rose-500 text-[50px] block m-auto mb-4' />
                <Title variant={Title.variant.secondary}>{t("L'ordine è stato bloccato.")}</Title>
            </div>

            {
                roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ?
                    <div className="w-4/5 text-left font-light space-y-4">

                        <div className='border p-6 space-y-4'>
                            <p className="font-light text-gray-600">{t("Verrai notificato non appena lo stato dell'ordine sarà modificato. Per ulteriori informazioni puoi rivolgerti al tuo Tecnico Commerciale.")}</p>
                            {/* <p className='font-bold'>{t("Dettagli situazione finanziaria")}:</p>
                            <p>{t("Fido")}: {numberFormat?.format(order?.data?.customer?.finance?.creditlimit)}</p>
                            <p>{t("Ordine attuale")}: {numberFormat?.format(order?.data?.total_net_price)}</p>
                            <p>{t("Fido residuo")}: <span className={`${residualCredit < 0 ? "text-rose-500" : ""}`}>{numberFormat?.format(residualCredit)}</span></p>
                            <p>{t("Insoluto")}: <span className={`${order?.data?.customer?.finance?.unpaidexpired > 0 ? "text-rose-500" : ""}`}>{numberFormat?.format(order?.data?.customer?.finance?.unpaidexpired)}</span></p> */}
                        </div>
                    </div>

                    :

                    <div className="w-4/5 text-left font-light space-y-4">
                        <p className="font-light text-gray-600">{t("Una notifica mail è già stata inviata ai responsabili per lo sblocco. Verrai notificato non appena lo stato dell'ordine sarà modificato.")}</p>

                        <div className='border p-6 space-y-4'>
                            <Title variant={Title.variant.secondary}>{t("Dati cliente")}</Title>
                            <p>{order?.data?.customer?.name} - {order?.data?.customer?.code}</p>
                        </div>

                        <div className='border p-6 space-y-4'>
                            <p className='font-bold'>{t("Dettagli situazione cliente")}:</p>
                            <p>{t("Insoluto")}: <span className={`${order?.data?.customer?.finance?.unpaidexpired > 0 ? "text-rose-500" : ""}`}>{numberFormat?.format(order?.data?.customer?.finance?.unpaidexpired)}</span></p>
                            <p>{t("Partite aperte")}: {numberFormat?.format(order?.data?.customer?.finance?.unpaid)}</p>
                            <p>{t("Fido")}: {numberFormat?.format(order?.data?.customer?.finance?.creditlimit)}</p>
                            <p>{t("Ordine attuale")}: {numberFormat?.format(order?.data?.total_net_price)}</p>
                            <p>{t("Totale ordini bloccati E-service")}: {numberFormat?.format(totalBlockedAmount)}</p>
                            <p>{t("Fido residuo")}: <span className={`${residualCredit < 0 ? "text-rose-500" : ""}`}>{numberFormat?.format(residualCredit)}</span></p>
                        </div>
                    </div>
            }

            <div className="flex flex-col gap-y-2 mt-8">
                <Button variant={Button.variant.secondary} onClick={goToOrder}>{t("Mostra dettagli ordine")}</Button>
                <Button variant={Button.variant.text} onClick={goToOrders}>{t("Torna alla lista ordini")}</Button>
            </div>
        </div>
    );
};
