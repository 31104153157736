import { useEffect, useState } from "react";
import ErrorIcon from "../ErrorIcon";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

export const EditableCellQty = ({ value: initialValue, row: { original }, cell, setIsTableValid, isTableValid, handleUpdateItem }: any) => {
    const minsellable = parseInt(cell.row.original.minsellable);

    const [value, setValue] = useState(initialValue);
    const [cellError, setCellError] = useState<string | null>(null);
    const [isDirty, setIsDirty] = useState(false);
    const { t } = useTranslation();

    const onChange = (e: any) => {
        setValue(parseInt(e.target.value));
        setIsDirty(true);
    };

    const handleFocus = (e: any) => e.target.select();

    // We'll only update the external data when the input is blurred
    const onBlur = async (ev: any) => {
        if (isDirty) {
            try {
                await handleUpdateItem(original.id, value, original.discount_1, original.discount_2);

                setCellError(null);
                setIsDirty(false);

                setIsTableValid((prevState: any) => prevState.filter((cellId: any) => {
                    return cellId !== ev.target.id;
                }));
            } catch (err: any) {
                if (err?.response?.status === 422) {
                    setCellError(t("La quantità non rispetta il minimo vendibile"));
                    setIsTableValid((prevState: any) => prevState.includes(ev.target.id) ? prevState : [...prevState, ev.target.id]);
                } else if (err?.response?.status === 403) {
                    setCellError(t(err.response.data.message));
                    setIsTableValid((prevState: any) => prevState.includes(ev.target.id) ? prevState : [...prevState, ev.target.id]);
                }
            }
        }
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
        setIsTableValid([]);
    }, [initialValue]);

    return (
        <>
            <div className='flex items-center gap-1'>
                <input id={`${original.id}`} className={classNames("w-20 p-1", {
                    "border-rose-500 outline-none rounded": (isTableValid?.includes(original.id.toString()) || !!cell.row.original.is_minsell_invalid)
                })} type="number" min={minsellable} step={minsellable} value={isNaN(value) ? "" : value} onChange={onChange} onBlur={onBlur} onFocus={handleFocus} />
                {(isTableValid?.includes(original.id.toString()) || !!cell.row.original.is_minsell_invalid) && <ErrorIcon>{cellError || t("La quantità non rispetta il minimo vendibile")}</ErrorIcon>}
            </div>
            <span className="block mt-1 text-xs opacity-50">{t(cell.row.original.uom_description)}</span>
        </>
    );
};
