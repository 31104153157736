import client from "../client";
import { useQuery } from "@tanstack/react-query";

type useDiscountParams = {
    "filter[customer_code]": string,
};

export default function useGetDiscountsData(params: useDiscountParams) {
    // GET OFFERS    
    const fetchDiscountsData = async (params: useDiscountParams) => {
        const res = await client.get("discount-table", { params: params });        
        return res.data;
    };

    const { data: discounts, isLoading, isFetching, isInitialLoading, isError } = useQuery(["discount-table", params],
        () => fetchDiscountsData(params),
        {
            enabled: !!params["filter[customer_code]"],
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
            refetchInterval: 1000 * 60 * 3,
            retry: 0,
        },
    );

    return { discounts, isLoading, isFetching, isInitialLoading, isError };
}