import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, usePagination } from "react-table";
import { EservicesStatus } from "../../constants/EservicesStatus";
import { getCurrencyDecimals } from "../../utils/getCurrencyDecimals";
import { Status, StatusVariant } from "../Status";
import { AvailabilityCell } from "./AvailabilityCell";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import classNames from "classnames";
import { InfoTooltip } from "./InfoTooltip";
import { AvailabilityTooltip } from "../cart/AvailabilityTooltip";

type SummaryTableProps = {
    items: any,
    total_products: any,
    hiddenColumns: string[],
    customer: any,
    cart: any,
};

const SummaryTable: React.FC<SummaryTableProps> = ({ items, total_products, hiddenColumns, customer, cart }) => {
    const { t } = useTranslation();
    const { user } = useAuthContext();

    // this alg is needed to hide change product modal in page offer details
    const isSummary = true;

    // table data
    const data = useMemo(
        () => {
            return items.filter((item: any) => {
                return !user?.constants?.extra_services?.includes(item?.code) && !user?.constants?.is_freight_cost?.includes(item?.code);
            });
        },
        [items]
    );

    const columns: any = useMemo(
        () => [
            {
                Header: t("Codice articolo").toString(),
                accessor: "code",
                className: "text-left p-4 whitespace-nowrap border-b",
                Cell: (props: any) => {
                    return (
                        <div className="flex gap-1 items-center">
                            <div>
                                <div className='mx-auto mb-1 text-center text-2xl'>
                                    <img src={`https://resources.rothoblaas.com/eservice-images/product/${props.cell.row.original.code}`} className="zoom max-w-[50px] max-h-[50px] m-auto" alt='' />
                                </div>
                                {
                                    props.row.original.isinpricelistrb ?
                                        <span className={classNames("block text-center hover:underline", { "text-rose-500": !!props.row.original.is_invalid })}>
                                            <a href={`https://www.rothoblaas.com/search?q=${props.cell.row.original.code}&s=eservice`} target="_blank" rel="noreferrer">{props.cell.row.original.code.toUpperCase()}</a>
                                        </span>
                                        :
                                        <span className="block text-center hover:underline">{props.cell.row.original.code}</span>
                                }
                            </div>
                        </div>
                    );
                }
            },
            {
                Header: t("Quantità").toString(),
                accessor: "qty",
                className: "text-left p-4 border-b",
                Cell: (props: any) => {
                    const numberFormat = new Intl.NumberFormat(customer?.language_id);

                    return (
                        <>
                            {numberFormat.format(props.row.original.qty)}
                        </>
                    );
                }
            },
            {
                Header: t("Disponibilità").toString(),
                id: "reservedqty",
                className: "text-left p-4 border-b",
                Cell: (props: any) => {
                    const qty = props.row.original.qty;
                    const reservedQty = props.row.original.reservedqty;
                    const availabilityCoverDate = props.row.original.availabilityInfo?.availabilityCoverDate;
                    const hqAvailabilityCoverDate = props.row.original.hqAvailabilityInfo?.hqAvailabilityCoverDate;

                    const numberFormat = new Intl.NumberFormat(customer?.language_id);

                    // show reserved qty only for under_evalutaion(1) & partially_shipped(3) orders
                    if (["1", "3"].includes(props.row.original.status)) {
                        return (
                            <>
                                {
                                    reservedQty > 0 &&
                                    <div className="flex items-center gap-x-1">
                                        <Status variant={Status.variant["item_available"]}>
                                            <InfoTooltip>{t("Prodotto riservato")}</InfoTooltip>
                                        </Status>
                                        {numberFormat.format(reservedQty)}
                                    </div>
                                }

                                {
                                    ((qty - reservedQty) > 0) &&
                                    <div className="flex items-center gap-x-1">
                                        <Status variant={Status.variant["item_not_available"]}>
                                            <AvailabilityTooltip availability_date={availabilityCoverDate} hq_availability_date={hqAvailabilityCoverDate} warehouse={props?.cart?.data?.customer?.technicalSale?.storageArea?.code} />
                                        </Status>

                                        {/* QTY */}
                                        {numberFormat.format((qty - reservedQty))}
                                    </div>
                                }
                            </>
                        );
                    } else {
                        return "-";
                    }
                }
            },
            {
                Header: t("Stato").toString(),
                id: "status",
                className: "text-left p-4 border-b",
                Cell: (props: any) => {
                    const orderStatus = props.row.original.status;
                    const status = EservicesStatus.order.find((status) => status.status === orderStatus);

                    const statusVariant = status?.variant as keyof typeof StatusVariant;

                    return <Status variant={Status.variant[statusVariant]}>{t(status?.description ?? "")}</Status>;
                }
            },
            {
                Header: t("Disponibilità").toString(),
                id: "availability",
                className: "text-left p-4 border-b",
                Cell: AvailabilityCell
            },
            {
                Header: t("Sconto %").toString(),
                accessor: "discount_1",
                disableSortBy: true,
                className: "text-right p-4 border-b",
                Cell: (props: any) => {
                    const numberFormat = new Intl.NumberFormat(customer?.language_id, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                    return (
                        <>
                            {numberFormat.format(props.row.original.discount_1)}
                        </>
                    );
                }
            },
            {
                Header: t("Sconto 2 %").toString(),
                accessor: "discount_2",
                disableSortBy: true,
                className: "text-right p-4 border-b",
                Cell: (props: any) => {
                    const numberFormat = new Intl.NumberFormat(customer?.language_id, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                    return (
                        <>
                            {numberFormat.format(props.row.original.discount_2)}
                        </>
                    );
                }
            },
            {
                Header: t("Prezzo unitario ({{currency}})", { currency: customer?.currency }).toString(),
                accessor: "pricevalue",
                disableSortBy: true,
                className: "text-right p-4 border-b",
                Cell: (props: any) => {
                    const fractionDigits = getCurrencyDecimals(customer?.currency) <= 0 ? 2 : 5;
                    const numberFormat = new Intl.NumberFormat(customer?.language_id, { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits });

                    return numberFormat.format(props.cell.row.original.net_price);
                }
            },
            {
                Header: t("Prezzo netto unitario ({{currency}})", { currency: customer?.currency }).toString(),
                accessor: "net_price",
                className: "text-right p-4 border-b",
                Cell: (props: any) => {
                    const fractionDigits = getCurrencyDecimals(customer?.currency) <= 0 ? 2 : 5;
                    const numberFormat = new Intl.NumberFormat(customer?.language_id, { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits });

                    return (
                        <>
                            {numberFormat.format(props.row.original.net_price)}
                        </>
                    );
                }
            },
            {
                Header: t("Totale scontato ({{currency}})", { currency: customer?.currency }).toString(),
                id: "discounted_total",
                className: "text-right p-4 border-b",
                Cell: (props: any) => {
                    const qty = props.row.original.qty;
                    const net_price = props.row.original.net_price;

                    const total = qty * net_price;
                    const numberFormat = new Intl.NumberFormat(customer?.language_id, { minimumFractionDigits: getCurrencyDecimals(items?.length ? items[0].currency : null), maximumFractionDigits: getCurrencyDecimals(items?.length ? items[0].currency : null) });

                    return <>{numberFormat.format(total)}</>;
                },
                Footer: () => {
                    const numberFormat = new Intl.NumberFormat(customer?.language_id, { minimumFractionDigits: getCurrencyDecimals(items?.length ? items[0].currency : null), maximumFractionDigits: getCurrencyDecimals(items?.length ? items[0].currency : null) });
                    return <>{numberFormat.format(total_products)}</>;
                }
            },
        ],
        [t, items, total_products, hiddenColumns, cart, customer]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        footerGroups,
    } = useTable({ columns, data, initialState: { hiddenColumns: hiddenColumns, pageSize: 100 }, cart, isSummary }, useSortBy, usePagination);

    return (
        <div className='overflow-y-scroll overflow-visible min-h-min max-h-[750px] border border-b-0'>
            <table {...getTableProps()} className="w-full text-xs font-light">
                <thead className='sticky top-0 bg-white'>
                    {headerGroups.map(headerGroup => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            className="relative"
                        >
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    {...column.getHeaderProps({ className: (column as any).className })}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell, index) => {
                                    return (
                                        <td
                                            {...cell.getCellProps({ className: (cell.column as any).className })}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot className='sticky bottom-0 bg-white'>
                    {footerGroups.map(group => (
                        <tr {...group.getFooterGroupProps()}
                            className="text-left relative"
                        >
                            {group.headers.map(column => (
                                <td
                                    {...column.getFooterProps()}
                                    {...column.getHeaderProps({ className: (column as any).className })}
                                >
                                    {column.render("Footer")}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
        </div>
    );
};

export default SummaryTable;
