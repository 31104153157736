import { useTranslation } from "react-i18next";
import ClientReference from "../cart/ClientReference";
import FinancialInfo from "../cart/step_1/FinancialInfo";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { forwardRef } from "react";
import { ChangeCustomer } from "./ChangeCustomer";
import useCartsMutations from "../../api/carts/useCartsMutations";
import useOffersMutations from "../../api/offers/useOffersMutations";
import { CustomerSelect } from "./CustomerSelect";

export const CustomerForm = forwardRef(({ loadCustomersOptions, handleCustomerChange, selectedCustomer, setSelectedCustomer, data, readonly = false }: any, ref: any) => {
    // context
    const { roles } = useAuthContext();
    
    // hooks
    const { t } = useTranslation();

    const { updateCart } = useCartsMutations();
    const { updateOffer } = useOffersMutations();

    const handleKindOfPricingChange = (e: any) => {
        if (data?.data?.offer_state) {
            updateOffer.mutateAsync({ cart_id: data?.data?.id, body: { customer_code: selectedCustomer?.code, kind_of_pricing: e.target.checked ? "2" : "1" } });
        } else {
            updateCart.mutateAsync({ cart_id: data?.data?.id, body: { customer_code: selectedCustomer?.code, kind_of_pricing: e.target.checked ? "2" : "1" } });
        }
    };

    return (
        <fieldset>
            <legend className="sr-only">{t("Cliente")}</legend>
            <div className="border bg-neutral-weaker">
                <h2 className="text-lg font-sans text-fg-lighter font-normal px-6 pt-6">{t("Dati cliente")}</h2>

                <div className='px-6 pb-6 space-y-10'>
                    {/* CUSTOMER SELECT */}
                    {
                        <div>
                            {!data?.data?.customer_code ? (
                                <CustomerSelect
                                    label="Seleziona cliente"
                                    ref={ref}
                                    loadCustomersOptions={loadCustomersOptions}
                                    handleChange={handleCustomerChange}
                                    selectedCustomer={selectedCustomer}
                                    setSelectedCustomer={setSelectedCustomer}
                                />
                            ) : (
                                <ChangeCustomer data={data} loadCustomersOptions={loadCustomersOptions} readonly={readonly} />
                            )}

                            {/* alloworder messages */}
                            {
                                data?.data?.customer && !data?.data?.customer?.alloworder &&
                                <p className="text-xs text-red-500 mt-1">{t("Cliente disattivato. Non è possibile procedere con nessuna operazione. Per maggiori informazioni rivolgiti al Customer Service")}</p>
                            }
                            {/* legallock messages */}
                            {
                                (data?.data?.customer?.legallock || data?.data?.customer?.invoicing?.legallock) &&
                                <div>
                                    {
                                        roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ?
                                            <p className="text-xs text-red-500">{t("Cliente con blocco finanziario. Possibile procedere solo con carrelli. Per maggiori informazioni rivolgersi al proprio tecnico commerciale.")}</p>
                                            : <p className="text-xs text-red-500">{t("Cliente con blocco finanziario. Possibile procedere solo con carrelli e offerte. Per maggiori informazioni rivolgiti al Credit Management")}</p>
                                    }
                                </div>
                            }
                        </div>
                    }

                    <ClientReference
                        // disabled={readonly}
                        clientReference={data?.data?.client_reference}
                    />

                    {/* TODO: da valutare come nascondere financial data from customers */}
                    <FinancialInfo cart={data} />

                    {data?.data?.customer && !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) &&
                        <div className="flex items-center gap-x-1 font-light">
                            <input
                                id="kindofpricing"
                                type="checkbox"
                                checked={parseInt(data?.data?.kind_of_pricing) !== 1}
                                className="cursor-pointer accent-primary"
                                onChange={(e) => handleKindOfPricingChange(e)}
                            />
                            <label htmlFor="kindofpricing" className="cursor-pointer p-0">{t("Prezzo netto")}</label>
                        </div>
                    }
                </div>
            </div>
        </fieldset>
    );
});
