import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { useSelectStyles } from "../../hooks/useSelectStyles";
import { useSearchParams } from "react-router-dom";

type UserSelectProps = {
    loadCustomersOptions: any;
    handleChange: any;
    label: string;
    error?: any;
};

export const UserSelect = forwardRef(({ loadCustomersOptions, handleChange, error, label }: UserSelectProps, ref: any) => {
    // hooks
    const { t } = useTranslation();
    const { CustomStyles } = useSelectStyles();
    const [searchParams] = useSearchParams();

    const [openCustomer, setOpenCustomer] = useState<boolean>(false);

    const getDefaultCustomer = (): any => {
        let customer = null;
        searchParams.forEach((value: any, key: any) => {
            if (key === "filter[search]") {
                customer = value;
            }
        });

        return customer ? { value: customer, label: customer } : undefined;
    };

    return (
        <div>
            <label htmlFor="">{t(label)}</label>
            {/* CLIENT SELECT */}
            <AsyncSelect
                ref={ref}
                loadOptions={loadCustomersOptions}
                defaultValue={getDefaultCustomer()}
                onChange={handleChange}
                onInputChange={(value: any) => setOpenCustomer(value !== "")}
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                styles={CustomStyles}
                placeholder={t("inserisci nome utente...")}
                loadingMessage={() => t("Caricamento in corso...")}
                noOptionsMessage={() => t("Nessun risultato")}
                openMenuOnClick={false}
                isClearable
                escapeClearsValue
                menuIsOpen={openCustomer}
            />
            {error && <p className="text-sm text-rose-500 mt-1">{error.message}</p>}
        </div>
    );
});
