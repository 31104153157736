import React, { createContext, useRef, useState } from "react";
import { useAuthContext } from "../hooks/use-context/useAuthContext";

type DiscountTableContextProviderProps = {
    children: React.ReactNode;
};

type DiscountTableContextType = {
    discountsData: object[],
    updateDiscountData: any,
    customerData: any,
    setCustomerData: React.Dispatch<any>,
    getFromLocalStorage: any,
    editable: boolean,
    setEditable: React.Dispatch<any>,
    modalCancel: boolean,
    setModalCancel: React.Dispatch<any>,
    modalConfirm: boolean,
    setModalConfirm: React.Dispatch<any>,
    selectedSublevel: any,
    setSelectedSublevel: React.Dispatch<any>,
    minClosingDate: any,
    setMinClosingDate: React.Dispatch<any>,
    updates: any,
    setUpdates: React.Dispatch<any>,
    discountFormRef: any,
    newDate: any;
    formatDate: any;
    haveDiscounts: boolean;
    setHaveDiscounts: React.Dispatch<any>,
    haveDiscountsData: boolean;
    setHaveDiscountsData: React.Dispatch<any>,
    userCanModify: boolean;
    setUserCanModify: React.Dispatch<any>,
    getDate: any,
    closeTableModal: any,
    setCloseTableModal: any,
};

export const DiscountTableContext = createContext<DiscountTableContextType | null>(null);

export const DiscountTableContextProvider = ({ children }: DiscountTableContextProviderProps) => {

    const { user } = useAuthContext();

    // return formatted data from local storage
    const getFromLocalStorage = (key: string) => {
        const temp = localStorage.getItem(key);
        if (temp) {
            return JSON.parse(temp ?? "{}");
        } else {
            return null;
        }
    };

    // function that generate a new UTC date setted to midnight.
    // if all the dates are setted on midnight you can have the same timestamp and you can validate greater, same or lower dates.
    function newDate(date?: string) {
        if (date && date?.length > 10) {
            date = date.substring(0,10);
        }
        return new Date((date ? new Date(date) : new Date()).setUTCHours(0, 0, 0, 0));
    }

    // return UTC date in format dd/mm/yyyy
    const formatDate = (date: string) => {
        const month = (newDate(date).getUTCMonth() + 1).toString();
        const day = (newDate(date).getUTCDate()).toString();
        const year = (newDate(date).getUTCFullYear()).toString();

        return (day.length === 1 ? "0" + day : day) + "/" + (month.length === 1 ? "0" + month : month) + "/" + year;
    };

    const [minClosingDate, setMinClosingDate] = useState<any>();
    const [haveDiscounts, setHaveDiscounts] = useState<boolean>(false);
    const [haveDiscountsData, setHaveDiscountsData] = useState<boolean>(false);
    const [customerData, setCustomerData] = useState<any>(getFromLocalStorage(`${user?.id}_discount_customer_data`));
    const [discountsData, setDiscountsData] = useState<any>([]);
    const [updates, setUpdates] = useState<object[]>(getFromLocalStorage(`${user?.id}_discount_session_new_items`) ?? []);
    const [selectedSublevel, setSelectedSublevel] = useState<string>("M-A0");
    const [editable, setEditable] = useState<boolean>(!!getFromLocalStorage(`${user?.id}_discount_table_editable`));
    const [modalCancel, setModalCancel] = useState<boolean>(false);
    const [modalConfirm, setModalConfirm] = useState<boolean>(false);
    const [userCanModify, setUserCanModify] = useState<boolean>(false);
    const [closeTableModal, setCloseTableModal] = useState<boolean>(false);

    // update and format table data
    const updateDiscountData = (discounts: any) => {
        const tempDiscountsData = discounts?.macroClasses ?? [];
        const subClasses = discounts?.secondLevelClasses ?? [];

        setUserCanModify(discounts?.can_be_modified && typeof discounts?.can_be_modified === "boolean");

        const getDiscountData = (level: string) => {
            const temp: any = [];
            Object.keys(discounts?.discounts).forEach((e: any) => {
                if (discounts?.discounts?.[e]?.["200"]) {
                    if ((discounts?.discounts?.[e]?.["200"]?.[0]?.priceclassification?.slice(0, 4) ?? discounts?.discounts[e]["200"][0].itemkey?.slice(0, 4)) === level) {
                        temp.push(discounts?.discounts[e]);
                    }
                }
            });
            return temp;
        };

        const getSublevelData = (level: string) => {
            const temp: any = [];
            subClasses.forEach((e: any) => {
                if (e?.code?.slice(0, 1) === level) {
                    temp.push({
                        ...e,
                        data: getDiscountData(e.code)
                    });
                }
            });
            return temp;
        };

        tempDiscountsData.forEach((e: any, index: number) => {
            tempDiscountsData[index] = {
                ...e,
                data: getSublevelData(e.code)
            };
        });

        setDiscountsData(() => [...tempDiscountsData]);
    };

    // ritorna la data se esiste e se è diversa dl 4712
    const getDate = (date: any) => {
        if (!!date && date !== "4712-12-31") {
            return date;
        } else {
            return null;
        }
    };

    // discount form ref. This is required in order to submit the form outside the <form></form> tag (in the confirm modal).
    const discountFormRef = useRef();

    return (
        <DiscountTableContext.Provider value={{
            discountsData,
            updateDiscountData,
            customerData,
            setCustomerData,
            getFromLocalStorage,
            editable,
            setEditable,
            modalCancel,
            setModalCancel,
            modalConfirm,
            setModalConfirm,
            selectedSublevel,
            setSelectedSublevel,
            updates,
            setUpdates,
            discountFormRef,
            newDate,
            minClosingDate,
            setMinClosingDate,
            formatDate,
            haveDiscounts,
            setHaveDiscounts,
            haveDiscountsData,
            setHaveDiscountsData,
            getDate,
            userCanModify,
            setUserCanModify,
            closeTableModal,
            setCloseTableModal
        }}>
            {children}
        </DiscountTableContext.Provider>
    );
};
