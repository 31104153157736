import { useMemo } from "react";
import { Column, usePagination, useSortBy, useTable } from "react-table";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { useTranslation } from "react-i18next";

type PriceHistoryTableProps = {
    items: any,
    selectedCustomer: any
};

export const PriceHistoryTable = ({ items, selectedCustomer }: PriceHistoryTableProps) => {

    const { user } = useAuthContext();
    const { t } = useTranslation();

    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);
    const numberFormat5 = new Intl.NumberFormat(selectedCustomer?.language_id ?? user?.language_id, { minimumFractionDigits: 5, maximumFractionDigits: 5 });
    const numberFormat2 = new Intl.NumberFormat(selectedCustomer?.language_id ?? user?.language_id, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    // table data
    const data = useMemo(
        () => {
            return items;
        },
        [items]
    );

    const columns: Array<Column<any>> = useMemo(
        () => [
            {
                Header: t("Codice articolo").toString(),
                className: "border-b p-4 text-left",
                accessor: "code"
            },
            {
                Header: t("Data").toString(),
                className: "border-b p-4 text-left",
                Cell: (props) => {
                    const date = props.row.original.order_date;

                    return <span>{dateFormat.format(new Date(date))}</span>;
                }
            },
            {
                Header: t("Numero ordine").toString(),
                className: "border-b p-4 text-left",
                accessor: "number"
            },
            {
                Header: t("Prezzo unitario ({{currency}})", { currency: selectedCustomer?.currency }).toString(),
                className: "border-b p-4 text-right",
                Cell: (props) => {
                    const grossPrice = props.row.original.pricevalue;

                    return <span>{numberFormat5.format(grossPrice)}</span>;
                }
            },
            {
                Header: t("Sconto 1 %").toString(),
                className: "border-b p-4 text-right",
                Cell: (props) => {
                    const discount_1 = props.row.original.discount_1;

                    return <span>{numberFormat2.format(discount_1)}</span>;
                }
            },
            {
                Header: t("Sconto 2 %").toString(),
                className: "border-b p-4 text-right",
                Cell: (props) => {
                    const discount_2 = props.row.original.discount_2;

                    return <span>{numberFormat2.format(discount_2)}</span>;
                }
            },
            {
                Header: t("Prezzo netto unitario ({{currency}})", { currency: selectedCustomer?.currency }).toString(),
                className: "border-b p-4 text-right",
                Cell: (props) => {
                    const netPrice = props.row.original.net_price;

                    return <span>{numberFormat5.format(netPrice)}</span>;
                }
            },
            {
                Header: t("Quantità").toString(),
                className: "border-b p-4 text-right",
                Cell: (props) => {
                    const qty = props.row.original.qty;

                    return <span>{parseInt(qty)}</span>;
                }
            },
        ],
        [t]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page
    } = useTable({
        columns,
        data,
        manualSortBy: true,
        disableSortRemove: true,
        initialState: { pageSize: 10 },
        dateFormat
    }, useSortBy, usePagination);

    return (
        <>
            <table {...getTableProps()} className="border w-full text-xs font-light">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="border-b">
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    {...column.getHeaderProps({ className: (column as any).className })}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell, index) => {
                                    return (
                                        <td
                                            {...cell.getCellProps({ className: (cell.column as any).className })}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};
