import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../client";

type OfferIdParam = {
    cart_id: number
};

export default function useCheckOfferPricesMutations() {
    const queryClient = useQueryClient();

    // SAVE CART
    const { data: updatedPrices, mutateAsync: updatePrices, isLoading: updatePricesLoading } = useMutation(async (params: OfferIdParam) => {
        return await client.put(`offers/${params.cart_id}/check-prices`);
    }, { onSuccess: () => { queryClient.invalidateQueries(["offer"]); } });

    return { updatedPrices, updatePrices, updatePricesLoading };
}