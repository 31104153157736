import Select from "react-select";
import { useSelectStyles } from "../../../../../hooks/useSelectStyles";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { FixingLineType } from "./FixingLineType";
import { AirTightnessAndWaterProofType } from "./AirTightnessAndWaterProofType";
import { ChemicalType } from "./ChemicalType";
import { AntiFallType } from "./AntiFallType";
import { AcousticsType } from "./AcousticsType";
import { MachinesAndEquipment } from "./MachinesAndEquipment";
import { Controller, useFormContext } from "react-hook-form";
import { Section } from "../../../../Section";

type LINES = {
    label: string,
    value: number
};


// TODO: add file machine input to unregister
const UNREGISTER = ["application_date", "external_application", "application_type", "material_used", "exposure_time", "package_type", "storage_type", "expiration_date", "gun_type", "machine_number"];

export const SelectProductLine = () => {
    const { t } = useTranslation();
    const { CustomStyles } = useSelectStyles();
    const LINES = [{ label: t("Fissaggio").toUpperCase(), value: 1 }, { label: t("Tenuta all'aria e impermeabilizzazione").toUpperCase(), value: 2 }, { label: t("Chimico").toUpperCase(), value: 3 }, { label: t("Anticaduta").toUpperCase(), value: 4 }, { label: t("Acustica").toUpperCase(), value: 5 }, { label: t("Macchine e attrezzatura").toUpperCase(), value: 6 }];

    const { unregister } = useFormContext();

    // state
    const [componentRender, setComponentRender] = useState<number | null>(null);

    const handleSelectChange = (inputValue: LINES | null, reactFormOnChange: any) => {
        if (inputValue) {
            unregister(UNREGISTER);
            setComponentRender(inputValue.value);
            reactFormOnChange(inputValue.label);
        } else {
            setComponentRender(null);
            reactFormOnChange(null);
        }
    };

    return (
        <Section title={"Scelta tipo di linea prodotto"}>
            <fieldset>
                <legend className="sr-only">{t("Linea prodotto")}</legend>
                <div className='space-y-6'>
                    {/* SELECT LINE TYPE */}
                    <div className="w-1/2 pr-2">
                        <label>{t("Seleziona linea prodotto")}</label>
                        <Controller
                            name='product_type'
                            rules={{ required: `${t("- questo campo è obbligatorio")}` }}
                            render={({ field: { onChange }, fieldState: { error } }) => (
                                <>
                                    <Select
                                        options={LINES}
                                        styles={CustomStyles}
                                        placeholder={t("Seleziona il problema riscontrato")}
                                        loadingMessage={() => t("Caricamento in corso...")}
                                        noOptionsMessage={() => t("Nessun risultato")}
                                        onChange={(e) => handleSelectChange(e, onChange)}
                                    />
                                    {error && <p className="text-sm text-rose-500 mt-1">{error.message}</p>}
                                </>
                            )}
                        />
                    </div>

                    {/* SELECTED LINE TYPE RENDER */}
                    {componentRender === 1 && <FixingLineType />}
                    {componentRender === 2 && <AirTightnessAndWaterProofType />}
                    {componentRender === 3 && <ChemicalType />}
                    {componentRender === 4 && <AntiFallType />}
                    {componentRender === 5 && <AcousticsType />}
                    {componentRender === 6 && <MachinesAndEquipment />}
                </div>
            </fieldset>
        </Section>
    );
};
