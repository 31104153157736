import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../client";

type DiscountParam = {
    customer_code: string,
    data?: string,
    closing_date?: string,
};

export default function useDiscountsTableMutations() {
    const queryClient = useQueryClient();
    // SAVE DISCOUNT TABLE
    const saveDiscount = useMutation(async (params: DiscountParam) => {
        return await client.post("discount-table", params);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["discount-table"]);
            queryClient.invalidateQueries(["price-list"]);
        }
    });

    // CLOSE DISCOUNT TABLE
    const closeDiscount = useMutation(async (params: DiscountParam) => {
        return await client.post("discount-table/close", params);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["discount-table"]);
            queryClient.invalidateQueries(["price-list"]);
        }
    });

    return { saveDiscount, closeDiscount };
}