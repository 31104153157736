import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import client from "../api/client";
import { Loader } from "../components/Loader";

export default function SessionCart() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const getSessionCartId = useCallback(async () => {
        try {
            setIsLoading(true);
            const res = await client.get("session-carts/self");
            setIsLoading(false);
            navigate(`/carts/${res?.data?.data?.id}`, { replace: true });
        } catch (err) {
            console.log(err);

            setIsLoading(false);
        }

    }, [navigate]);

    useEffect(() => {
        getSessionCartId();
    }, [getSessionCartId]);

    return (
        <>
            {isLoading && <Loader />}
        </>
    );
}
