import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { usePagination, DOTS } from "../hooks/useCustomPagination";

const Pagination = (props: any) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    // If there are less than 2 times in pagination range we shall not render the component
    if (paginationRange)
        if (currentPage === 0 || paginationRange.length < 2) {
            return null;
        }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage;
    if (paginationRange) {
        lastPage = paginationRange[paginationRange.length - 1];
    }
    return (
        <ul className='flex items-center gap-x-2 mt-6 text-xs'>
            {/* Left navigation arrow */}
            <li
                onClick={onPrevious}
                onKeyPress={(e) => { return e.key === "Enter" ? onPrevious() : null; }}
                className={`flex items-center justify-center cursor-pointer p-1 w-6 h-6 ${currentPage === 1 ? "pointer-events-none border-gray-50" : ""}`}
                tabIndex={0}
            >
                <FiChevronLeft className={`${currentPage === 1 ? "text-gray-200" : ""}`} />
            </li>
            {paginationRange && paginationRange.map((pageNumber, index) => {

                // If the pageItem is a DOT, render the DOTS unicode character
                if (pageNumber === DOTS) {
                    return <li className='flex items-center justify-center p-1 w-6 h-6' key={index}>&#8230;</li>;
                }

                // Render our Page Pills
                return (
                    <li
                        key={index}
                        onClick={() => onPageChange(pageNumber)}
                        onKeyPress={(e) => { return e.key === "Enter" ? onPageChange(pageNumber) : null; }}
                        className={`flex items-center justify-center cursor-pointer p-1 w-6 h-6 min-w-min ${pageNumber === currentPage ? "border rounded-sm border-black font-bold scale-[1.25]" : "font-light"}`}
                        tabIndex={0}
                    >
                        {pageNumber}
                    </li>
                );
            })}
            {/*  Right Navigation arrow */}
            <li
                onClick={onNext}
                onKeyPress={(e) => { return e.key === "Enter" ? onNext() : null; }}
                className={`flex items-center justify-center p-1 w-6 h-6 ${currentPage === lastPage ? "pointer-events-none border-gray-50" : "cursor-pointer"}`}
                tabIndex={0}
            >
                <div>
                    <FiChevronRight className={`${currentPage === lastPage ? "text-gray-200" : ""}`} />
                </div>
            </li>
        </ul>
    );
};

export default Pagination;