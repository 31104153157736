import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { useSelectStyles } from "../../hooks/useSelectStyles";
import { useState } from "react";
import useGetSalesLine from "../../api/impersonate/useGetSalesLine";
import client from "../../api/client";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";

export const Impersonate = () => {
    const { t } = useTranslation();
    const { CustomStyles } = useSelectStyles();

    // context
    const { setImpersonateToken, impersonateUser, setImpersonateUser } = useAuthContext();

    // state
    const [openCustomer, setOpenCustomer] = useState<boolean>(false);

    // api
    const { loadSaleslineOptions } = useGetSalesLine();

    // handle customer SELECT change
    const handleImpersonateChange = async (inputValue: any) => {
        if (inputValue) {
            try {
                const res = await client.post("impersonate-token", { code: inputValue.code });
                setImpersonateToken(res.data.data.token);
                setImpersonateUser(inputValue);
            } catch (err) {
                console.log(err);
            }
        } else {
            setImpersonateToken("");
            setImpersonateUser(null);
        }
    };

    // handle customer search INPUT change
    const handleImpersonateInputChange = (value: any) => {
        if (value === "") {
            setOpenCustomer(false);
        } else {
            setOpenCustomer(true);
        }
    };

    // useEffect(() => {
    //     console.log(impersonateToken);
    // }, [impersonateToken]);

    return (
        <div>
            <AsyncSelect
                loadOptions={loadSaleslineOptions}
                value={impersonateUser}
                onChange={handleImpersonateChange}
                onInputChange={handleImpersonateInputChange}
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                styles={CustomStyles}
                placeholder={t("inserisci sales line")}
                loadingMessage={() => t("Caricamento in corso...")}
                noOptionsMessage={() => t("Nessun risultato")}
                openMenuOnClick={false}
                isClearable
                escapeClearsValue
                menuIsOpen={openCustomer}
                menuPlacement="top"
            />
        </div>
    );
};
