import { useQuery } from "@tanstack/react-query";
import client from "../client";

export default function useBlockedOrder(order_id: string | undefined, open: boolean) {
    const fetchBlockedOrder = async (order_id: string | undefined) => {
        const res = await client.get(`blocked-orders/${order_id}`);
        return res.data;
    };

    // GET SINGLE ORDER
    const { data: blockedOrder, isLoading, isFetching } = useQuery(["blocked-order", order_id],
        () => fetchBlockedOrder(order_id),
        {
            refetchOnWindowFocus: false,
            enabled: !!order_id && open
        }
    );

    return { blockedOrder, isLoading, isFetching };
}