import { Button } from "../Button";
import { useCallback, useEffect, useRef, useState } from "react";
import useGetCountriesRegions from "../../api/countries/useGetCountriesRegions";
import Select from "react-select";
import { useSelectStyles } from "../../hooks/useSelectStyles";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import { Modal } from "../commom/Modal";

type ModalProps = {
    open: boolean;
    onClose: React.Dispatch<React.SetStateAction<boolean>>;
    primaryActionLabel: string,
    title: string,
    callbackFn: any,
    defaultValues?: any,
};

export type Address = {
    code?: string,
    name?: string,
    street?: string,
    country_id?: string,
    region_id?: string,
    city?: string,
    postalcode?: string
    phone?: string
};

type Country = {
    id: string;
    index: number;
    isocode2: string;
    isocode3: string;
    lang: string;
    description: string;
};

type Region = {
    id: string;
    index: number;
    code: string;
    country_id: string;
    lang: string;
    description: string;
    phone: string;
};

export default function AddAddressModal({ open, onClose, primaryActionLabel, title, defaultValues, callbackFn }: ModalProps) {
    const { t } = useTranslation();

    // hooks
    const { getCountries, getRegions } = useGetCountriesRegions();
    const { CustomStyles } = useSelectStyles();

    // react hook form
    const { register, handleSubmit, watch, formState: { errors }, control, getValues, reset } = useForm();

    // state
    const [countries, setCountries] = useState<{ value: Country, label: string }[]>([]);
    const [regions, setRegions] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<any>(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);

    // refs
    const regionSelect: any = useRef();

    const handleConfirmation = (formData: Address) => {
        callbackFn(formData);

        onClose(false);
    };

    // HANDLE FORM SUBMIT
    const submitForm = async (data: any) => {
        const params = {
            username: "edupigmento",
            postalcode: data?.postalcode,
            placename: encodeURIComponent(data?.city),
            country: data?.country_id,
        };

        try {
            setIsLoading(true);
            const res = await axios.get("https://secure.geonames.org/postalCodeSearchJSON", { params });

            setIsLoading(false);

            if ((res.data.postalCodes[0]?.placeName.toUpperCase() !== data?.city?.toUpperCase()) || (res.data.postalCodes[0]?.postalCode.toUpperCase() !== data?.postalcode?.toUpperCase())) {
                setOpenConfirmation(true);
            } else if (res.data.postalCodes.length <= 0) {
                setOpenConfirmation(true);
            } else {
                handleConfirmation(data);
            }
        } catch (err) {
            setIsLoading(false);
            console.log(err);
        }
    };

    // HANDLE COUNTRY REACT SELECT
    const handleSelectCountryChange = (e: any, reactHookFormChange: any) => {
        if (e) {
            reactHookFormChange(e.value);
            getRegionsOptions(e.value);
        } else {
            reactHookFormChange(null);
            setRegions([]);
        }
        regionSelect.current.clearValue();
    };

    // HANDLE REGION REACT SELECT
    const handleSelectRegionChange = (e: any, reactHookFormChange: any) => {
        if (e) {
            reactHookFormChange(e.value);
        } else {
            reactHookFormChange(null);
        }
    };

    const handleClose = () => {
        onClose(false);
    };

    // GET COUNTRIES OPTIONS
    const getCountriesOptions = useCallback(async () => {
        const countries = await getCountries();
        const options = countries.map((option: Country) => {
            return { value: option.isocode2, label: option.description };
        });
        setCountries(options);
    }, [getCountries]);

    // GET REGIONS OPTIONS
    const getRegionsOptions = useCallback(async (country_id) => {
        const regions = await getRegions(country_id);
        const options = regions.map((option: Region) => {
            return { value: option.code, label: option.description };
        });
        setRegions(options);
    }, [getRegions]);

    useEffect(() => {
        // GET COUNTRIES ONLY IF MODAL IS OPEN
        if (open) {
            getCountriesOptions();
        }
        // GET REGIONS TO SET DEFAULT REGION VALUE IN CASE DEFAULTVALUES EXIST
        if (defaultValues && !!defaultValues.country_id) {
            getRegionsOptions(defaultValues.country_id);
        }
    }, [getCountriesOptions, open, defaultValues]);

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues);
        } else {
            reset();
        }
    }, [defaultValues, reset]);

    return (
        <Modal open={open} onClose={handleClose} title={title}>
            {
                openConfirmation &&
                <Modal open={openConfirmation} onClose={setOpenConfirmation} title={t("Conferma indirizzo")}>
                    <div className="space-y-4">
                        <p>
                            {t("Questo indirizzo non è stato riconosciuto da nostro sistema. Se le informazioni inserite sono corrette si prega di confermare.")}
                        </p>

                        <div className='text-right'>
                            <Button variant={Button.variant.text} className="mr-6" onClick={() => setOpenConfirmation(false)}>{t("Annulla")}</Button>
                            <Button variant={Button.variant.primary} type="button" onClick={() => handleConfirmation(getValues())}>{primaryActionLabel}</Button>
                        </div>
                    </div>
                </Modal>
            }

            {isLoading &&
                <div className='absolute inset-0 bg-black/50 flex items-center justify-center z-50'>
                    <ClipLoader
                        color={"white"}
                        loading={true}
                        size={100}
                        aria-label="Loading Spinner"
                    />
                </div>
            }

            <form onSubmit={handleSubmit(submitForm)}>
                <fieldset className='space-y-2'>
                    {/* NAME */}
                    <div>
                        <label htmlFor="name">{t("Nome cliente")}:</label>
                        <input {...register("name", { required: t("- questo campo è obbligatorio") })} type="text" placeholder={t("inserisci il nome")} className='placeholder:text-sm' />
                        {errors?.name && <p className='text-red-500 text-xs mt-1'>{errors?.name?.message}</p>}
                    </div>

                    <div className='grid grid-cols-4 gap-x-4'>
                        {/* STREET */}
                        <div className='col-span-4'>
                            <label htmlFor="street">{t("Indirizzo")}</label>
                            <input {...register("street", { required: t("- questo campo è obbligatorio") })} type="text" placeholder={t("inserisci la via")} className='placeholder:text-sm' />
                            {errors?.street && <p className='text-red-500 text-xs mt-1'>{errors?.street?.message}</p>}
                        </div>
                    </div>

                    {/* PHONE */}
                    <div>
                        <label htmlFor="phone">{t("Numero di telefono")}</label>
                        <input {...register("phone", { required: t("- questo campo è obbligatorio"), pattern: { value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, message: "phone must be a number and may contain only + - and spaces" } })} type="tel" placeholder={t("inserisci telefono del referente")} className='placeholder:text-sm' />
                        {errors?.phone && <p className='text-red-500 text-xs mt-1'>{errors?.phone?.message}</p>}
                    </div>

                    <div className='grid grid-cols-4 gap-x-4'>
                        {/* CITY */}
                        <div className='col-span-3'>
                            <label htmlFor="city">{t("Città")}:</label>
                            <input {...register("city", { required: t("- questo campo è obbligatorio") })} type="text" placeholder={t("inserisci la città")} className='placeholder:text-sm' />
                            {errors?.city && <p className='text-red-500 text-xs mt-1'>{errors?.city?.message}</p>}
                        </div>
                        {/* POSTALCODE */}
                        <div>
                            <label htmlFor="postalcode">{t("CAP")}:</label>
                            <input {...register("postalcode", { required: t("- questo campo è obbligatorio") })} type="text" placeholder={t("inserisci il CAP")} className='placeholder:text-sm' />
                            {errors?.postalcode && <p className='text-red-500 text-xs mt-1'>{errors?.postalcode?.message}</p>}
                        </div>
                    </div>

                    <div className='grid grid-cols-4 gap-x-4'>
                        {/* COUNTRY */}
                        <div className='col-span-2'>
                            <label htmlFor="country_id">{t("Paese")}:</label>
                            <Controller
                                control={control}
                                name='country_id'
                                rules={{ required: t("- questo campo è obbligatorio") }}
                                render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                    <>
                                        <Select
                                            options={countries}
                                            styles={CustomStyles}
                                            ref={ref}
                                            value={countries.find((el: any) => el.value === value)}
                                            placeholder={t("inserisci il paese")}
                                            loadingMessage={() => t("Caricamento in corso...")}
                                            noOptionsMessage={() => t("Nessun risultato")}
                                            onChange={(e: any) => handleSelectCountryChange(e, onChange)}
                                            isClearable
                                            escapeClearsValue
                                        />
                                        {error && <p className='text-red-500 text-xs mt-1'>{error?.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                        {/* REGION */}
                        <div className='col-span-2'>
                            <label htmlFor="region_id">{t("Provincia")}:</label>
                            <Controller
                                control={control}
                                name='region_id'
                                rules={{ required: t("- questo campo è obbligatorio") }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <>
                                        <Select
                                            options={regions}
                                            ref={regionSelect}
                                            value={regions.find((el: any) => el.value === value)}
                                            styles={CustomStyles}
                                            placeholder={t("inserisci la provincia")}
                                            loadingMessage={() => t("Caricamento in corso...")}
                                            noOptionsMessage={() => t("Nessun risultato")}
                                            onChange={(e: any) => handleSelectRegionChange(e, onChange)}
                                            isClearable
                                            escapeClearsValue
                                            isDisabled={!watch("country_id")}
                                        />
                                        {error && <p className='text-red-500 text-xs mt-1'>{error?.message}</p>}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                </fieldset>
                <div className='text-right mt-6'>
                    <Button variant={Button.variant.text} className="mr-6" onClick={handleClose}>{t("Annulla")}</Button>
                    <Button variant={Button.variant.primary}>{primaryActionLabel}</Button>
                </div>
            </form>
        </Modal >
    );
}
