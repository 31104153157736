import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const AntiFallType = () => {
    const { register, formState: { errors } } = useFormContext();
    const { t } = useTranslation();
    return (
        <div className="border p-6 space-y-4">
            {/* APPLICATION DATE */}
            <div className="w-1/2 pr-2">
                <label htmlFor="application_date">{t("Data applicazione prodotto")}</label>
                <input
                    {...register("application_date", { required: `${t("- questo campo è obbligatorio")}` })}
                    type="date"
                    id="application_date"
                />
                {errors?.application_date && <p className="text-sm text-rose-500 mt-1">{errors?.application_date.message}</p>}
            </div>

            <div>
                <label>{t("Come è stato installato/utilizzato il prodotto")}</label>
                <input
                    {...register("storage_type", { required: `${t("- questo campo è obbligatorio")}` })}
                    type="text"
                />
                {errors?.storage_type && <p className="text-sm text-rose-500 mt-1">{errors?.storage_type.message}</p>}
            </div>
        </div>
    );
};
