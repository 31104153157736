import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { useSelectStyles } from "../../hooks/useSelectStyles";
import { useSearchParams } from "react-router-dom";

type ItemSelectProps = {
    name?: string;
    loadItemsOptions: any;
    handleChange: any;
    error?: any;
    disabled?: boolean;
};

export const ItemSelect = forwardRef(({ name, loadItemsOptions, handleChange, error, disabled = false }: ItemSelectProps, ref: any) => {
    // hooks
    const { t } = useTranslation();
    const { CustomStyles } = useSelectStyles();
    const [searchParams] = useSearchParams();

    const [openItem, setOpenItem] = useState<boolean>(false);

    const getDefaultItem = (): any => {
        let item = null;
        searchParams.forEach((value: any, key: any) => {
            if (key === "filter[item_code]") {
                item = value;
            }
        });

        return item ? { value: item, label: item } : undefined;
    };

    return <>
        <AsyncSelect
            ref={ref}
            name={name}
            loadOptions={loadItemsOptions}
            defaultValue={getDefaultItem()}
            onChange={handleChange}
            onInputChange={(value: any) => setOpenItem(value !== "")}
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            styles={CustomStyles}
            placeholder={t("Seleziona un prodotto...")}
            loadingMessage={() => t("Caricamento in corso...")}
            noOptionsMessage={() => t("Nessun risultato")}
            openMenuOnClick={false}
            isClearable
            escapeClearsValue
            menuIsOpen={openItem}
            isDisabled={disabled}
        />
        {error && <p className="text-sm text-rose-500 mt-1">{error.message}</p>}
    </>;
});
