import { useFormContext } from "react-hook-form";
import { InputFileDragDrop } from "../../../../commom/InputFileDragDrop";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { InfoTooltip } from "../../../../commom/InfoTooltip";

export const MachinesAndEquipment = () => {
    const { register, formState: { errors } } = useFormContext();
    const { t } = useTranslation();

    const [files, setFiles] = useState<any[]>([]);

    return (
        <div className="border p-6 space-y-4">
            <div>
                <div className="flex gap-2">
                    <label>{t("Numero macchina")}</label>
                    <InfoTooltip className="mt-1">
                        <img src="/images/numero_macchina.jpg" alt="numero_macchina" />
                    </InfoTooltip>
                </div>
                <input
                    {...register("machine_number")}
                    type="text"
                />
                {errors?.machine_number && <p className="text-sm text-rose-500 mt-1">{errors?.machine_number.message}</p>}
            </div>

            <InputFileDragDrop inputName="machine_files" container={files} setContainer={setFiles} />
        </div>
    );
};
