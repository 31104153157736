import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Title } from "../components/Title";
import { Cart } from "../components/cart/step_1/Cart";
import { Route, Routes, useParams } from "react-router-dom";
import { Message } from "../components/Message";
import Shipping from "../components/cart/step_2/Shipping";
import { CartSummary } from "../components/cart/step_3/CartSummary";
import SessionCart from "./SessionCart";
import Stepper from "../components/cart/Stepper";
import { useAuthContext } from "../hooks/use-context/useAuthContext";

const CartPage = () => {
    const { t } = useTranslation();
    const params = useParams();
    const { roles } = useAuthContext();

    const [successMsg, setSuccessMsg] = useState<string | null>(null);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);

    return (
        <div className="py-10 px-8 2xl:px-28">
            <Title>{roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ? t("Nuovo ordine") : t("Nuovo ordine o offerta")}</Title>

            {successMsg && <Message variant={Message.variant.success} open={!!successMsg} handleCloseMessage={() => { setSuccessMsg(null); }}>{successMsg}</Message>}
            {errorMsg && <Message variant={Message.variant.warning} open={!!errorMsg} handleCloseMessage={() => { setErrorMsg(null); }}>{errorMsg}</Message>}

            <Stepper activePath={params["*"]} />

            <Routes>
                <Route path="/" element={<SessionCart />} />
                <Route path=":id" element={<Cart />} />
                <Route path=":id/shipping" element={<Shipping />} />
                <Route path=":id/summary" element={<CartSummary setErrorMsg={setErrorMsg} />} />
            </Routes>
        </div>
    );
};

export default CartPage;
