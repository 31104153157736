import { useQuery } from "@tanstack/react-query";
import client from "../client";

type useInvoicesParams = { page: number };

export default function useInvoices(params: useInvoicesParams, canFetch: boolean = true) {
    // GET INVOICES
    const fetchInvoices = async (params: useInvoicesParams) => (await client.get("invoices", { params })).data;

    const { data: invoices, isLoading, isFetching } = useQuery(
        ["invoices", params],
        () => fetchInvoices(params),
        { 
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
            enabled: canFetch
        }
    );

    return { invoices, isLoading, isFetching };
}