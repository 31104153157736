import { Route, Routes, useLocation } from "react-router-dom";
import { Title } from "../components/Title";
import Orders from "./Orders";
import OutstandingOrders from "./OutstandingOrders";
import Order from "./Order";
import { useState } from "react";
import { PriceHistory } from "./PriceHistory";
import RequireAuth from "../auth/RequireAuth";
import { useAuthContext } from "../hooks/use-context/useAuthContext";
import { Tab, Tabs } from "../components/Tabs";

export default function OrderPage() {
    const location = useLocation();
    const { can } = useAuthContext();

    const [title, setTitle] = useState<string | null>("");

    const tabs: Tab[] = [
        {
            title: "I miei ordini",
            to: "/orders",
            isVisibile: true
        },
        {
            title: "Le mie rimanenze",
            to: "/orders/outstanding-orders",
            isVisibile: true
        },
        {
            title: "Storico prezzi articoli clienti",
            to: "/orders/price-history",
            isVisibile: can(["can_see_price_history"])
        },
    ];

    return (
        <div className="py-10 px-8 2xl:px-28">
            <Title variant={Title.variant.primary}>{title}</Title>
            {tabs.find((e) => e.to === location.pathname) && <Tabs tabs={tabs}/>}
            <Routes>
                <Route path="/" element={
                    <Orders setTitle={setTitle}/>
                }/>
                <Route path="outstanding-orders" element={
                    <OutstandingOrders setTitle={setTitle}/>
                }/>
                <Route element={
                    <RequireAuth allowedRoles={["can_see_price_history"]}/>
                }>
                    <Route path="price-history" element={
                        <PriceHistory setTitle={setTitle}/>
                    }/>
                </Route>
                <Route path=":id" element={
                    <Order setTitle={setTitle}/>
                }/>
            </Routes>
        </div>
    );
}
