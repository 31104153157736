import { useRef, useState } from "react";
import { Dialog } from "@reach/dialog";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { useShippingContext } from "../../../hooks/use-context/useShippingContext";
import { Button } from "../../Button";
import { Title } from "../../Title";
import "@reach/dialog/styles.css";
import { toast } from "react-toastify";

type SaveCartModalProps = {
    open: boolean;
    onClose: any;
    saveCart: any;
    updateCart: any;
    data: any;
};

export default function SaveCartModal({ open, onClose, saveCart, updateCart, data }: SaveCartModalProps) {
    const { t } = useTranslation();

    // refs
    const inputName: any = useRef();

    // context
    const { client_reference, resetShippingContext } = useShippingContext();

    // state
    const [cartName, setCartName] = useState(client_reference || data?.data?.name || "");
    const [error, setError] = useState("");

    // HANDLE SAVE CART
    const handleSaveClick = async () => {
        if (cartName.length <= 0) {
            setError(t("Il nome è obbligatorio"));
            return;
        }

        const params = {
            cart_id: data?.data?.id,
            body: {
                customer_code: data?.data?.customer?.code,
                name: cartName,
                client_reference
            }
        };

        try {
            await saveCart.mutateAsync({ cart_id: data?.data?.id });
            await updateCart.mutateAsync(params);
            resetShippingContext();
            setError("");
            toast.success(t("Carrello salvato con successo"));
            onClose(false);
        } catch (err) {
            console.log(err);
        }
    };

    const closeModal = () => {
        setError("");
        onClose(false);
    };

    return (
        <Dialog isOpen={open} onDismiss={closeModal}>
            <div className='bg-white space-y-6 overflow-y-scroll md:overflow-y-visible !h-auto !w-auto absolute inset-0 md:bottom-auto md:left-1/2 md:-translate-x-1/2 z-50 modal-selector md:!my-20 !m-0 !p-8'>
                <div className='flex justify-between items-center'>
                    <Title variant={Title.variant.secondary} className="mb-0">{t("Salva carrello")}</Title>
                    <MdClose className='text-lg cursor-pointer hover:text-secondary' onClick={closeModal} />
                </div>

                <div>
                    <label htmlFor="cart_name">{t("Inserisci il nome del carrello")}</label>
                    <input
                        type="text"
                        placeholder={t("Nome lista")}
                        onChange={(e) => setCartName(e.target.value)}
                        ref={inputName}
                        value={cartName}
                    />
                    {error.length > 0 && <p className='text-rose-500 text-xs mt-2'>{error}</p>}
                </div>

                <div className='text-right space-x-4'>
                    <Button type='button' variant={Button.variant.text} onClick={closeModal}>{t("Annulla")}</Button>
                    <Button type='button' variant={Button.variant.primary} onClick={handleSaveClick}>{t("Salva")}</Button>
                </div>
            </div>
        </Dialog>
    );
}
