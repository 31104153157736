import { useFormContext, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../../../hooks/use-context/useAuthContext";
import { ItemSelect } from "../../../../commom/ItemSelect";
import { Section } from "../../../../Section";
import Select from "react-select";
import { useSelectStyles } from "../../../../../hooks/useSelectStyles";
import { useEffect, useState } from "react";
import useShippings from "../../../../../api/shippings/useShippings";

type Props = {
    loadItemsOptions: any
    handleItemSearchChange: any
    flow?: string
};

// Reference issue 278
const notRequiredComplaintId = ["S01", "S02", "S05", "S06", "S07", "S08", "S09", "S10", "S12", "S13"];

export const ComplaintProductDetails = ({ loadItemsOptions, handleItemSearchChange, flow = "product" }: Props) => {
    const { t } = useTranslation();
    const { roles, user } = useAuthContext();
    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

    const { register, formState: { errors }, watch, setValue, control } = useFormContext();

    // state
    const [mappedShippings, setMappedShippings] = useState([]);

    // react hook form
    const customer_code = watch("customer_code");
    const item_id = watch("item_id");
    const complaint_type = watch("complaint_type");

    const { shippings } = useShippings({
        page: 1,
        "filter[customer_code]": customer_code,
        "filter[item_id]": item_id,
        pageSize: 1000
    });

    const { CustomStyles } = useSelectStyles();

    const handleCustomsBillChange = (inputValue: any, reactFormOnChange: (...event: any[]) => void) => {
        reactFormOnChange(inputValue?.slipnumber);
        setValue("shipping_order_id", inputValue?.id);
    };

    useEffect(() => {
        setMappedShippings(shippings?.data?.map((shipping: any) => {
            return { ...shipping, label: `${shipping.slipnumber} - ${dateFormat.format(new Date(shipping.date))}` }
        }));
    }, [shippings]);

    return (
        <fieldset>
            <Section title={"Dettagli prodotto"}>
                <legend className="sr-only">{t("Dettagli prodotto")}</legend>
                <div className='grid grid-cols-2 gap-x-6 gap-y-4'>
                    <div className={!roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ? "w-full" : "flex-1"}>
                        <label>{t("Inserisci codice articolo o nome prodotto")}</label>
                        <Controller
                            control={control}
                            name='item_id'
                            rules={{
                                validate: {
                                    required: (value) => {
                                        if (!notRequiredComplaintId?.find(item => item === complaint_type?.slice(4, 7)) && !value) {
                                            return t("- questo campo è obbligatorio").toString()
                                        } else {
                                            return undefined;
                                        }
                                    }
                                }
                            }}
                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                <ItemSelect
                                    name="item_id"
                                    ref={ref}
                                    loadItemsOptions={loadItemsOptions}
                                    handleChange={(e: any) => handleItemSearchChange(e, onChange)}
                                    error={error}
                                />
                            )}
                        />
                    </div>

                    {flow === "product" && <div>
                        <label htmlFor="lotto">{t("Numero lotto")}</label>
                        <input
                            {...register("lotto", { required: `${t("- questo campo è obbligatorio")}` })}
                            id="lotto"
                            type="text"
                            placeholder={t("inserisci il lotto")}
                        />
                        {errors?.lotto && <p className="text-sm text-rose-500 mt-1">{errors?.lotto.message}</p>}
                    </div>}

                    <div>
                        <label htmlFor="qty">{t("Quantità coinvolta che presenta il problema")}</label>
                        <input
                            {...register("qty")}
                            id="qty"
                            type="text"
                            placeholder={t("inserisci la quantità, es. 3 su 10")}
                        />
                        {errors?.qty && <p className="text-sm text-rose-500 mt-1">{errors?.qty.message}</p>}
                    </div>
                    {/* BOLLA */}
                    <div>
                        <label htmlFor="customs_bill">{t("Numero Bolla")}</label>
                        <Controller
                            name='customs_bill'
                            rules={{ required: complaint_type?.slice(4, 7) !== "S07" ? `${t("- questo campo è obbligatorio")}` : undefined }}
                            render={({ field: { onChange }, fieldState: { error } }) => (
                                <>
                                    <Select
                                        id="customs_bill"
                                        options={mappedShippings}
                                        onChange={(e: any) => handleCustomsBillChange(e, onChange)}
                                        styles={CustomStyles}
                                        placeholder={t("inserisci numero della bolla")}
                                        loadingMessage={() => t("Caricamento in corso...")}
                                        noOptionsMessage={() => t("Nessun risultato")}
                                        isClearable
                                        escapeClearsValue
                                    />
                                    {errors?.customs_bill && <p className="text-sm text-rose-500 mt-1">{errors?.customs_bill.message}</p>}
                                </>
                            )}
                        />
                    </div>
                </div>
            </Section>
        </fieldset>
    );
};
