import { useEffect, useRef, useState } from "react";
import SubRows from "./SubRows";
import client from "../../api/client";

// SUB ROW FETCH COMPONENT
const SubRowAsync = ({ row, endpoint }: any) => {
    const alreadyFetched = useRef(false);

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        client.get(`${endpoint}/${row.original.id}`)
            .then(res => {
                if (alreadyFetched.current) {
                    return;
                }
                setData(res.data.data);
                setLoading(false);
            });
        return () => {alreadyFetched.current = true;};
    }, [row.original.id]);

    return (
        <SubRows
            data={data}
            loading={loading}
            endpoint={endpoint}
        />
    );
};

export default SubRowAsync;