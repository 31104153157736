import { useQuery } from "@tanstack/react-query";
import client from "../client";

export default function useProfile(customer_code: string | undefined) {
    const fetchProfile = async (customer_code: string | undefined) => {
        const res = await client.get("/customers/profile", { params: { customer_code } });
        return res.data;
    };

    // GET SINGLE ORDER
    const { data, isLoading, isFetching } = useQuery(["profile", customer_code],
        () => fetchProfile(customer_code),
        {
            refetchOnWindowFocus: false,
            enabled: !!customer_code
        }
    );

    return { data, isLoading, isFetching };
}
