import { createContext, useState, useEffect } from "react";
import { useAuthContext } from "../hooks/use-context/useAuthContext";
import { MarginCalculationItem } from "../interfaces/MarginCalculationItem";

type MarginCalculatorContextProviderProps = {
    children: React.ReactNode;
};

type MarginCalculatorContextType = {
    toolMarginiItems: MarginCalculationItem[],
    setToolMarginiItems: React.Dispatch<React.SetStateAction<MarginCalculationItem[]>>,
    setSelectedCustomer: React.Dispatch<any>,
    selectedCustomer: any
};

export const MarginCalculatorContext = createContext<MarginCalculatorContextType | null>(null);

export const MarginCalculatorContextProvider = ({ children }: MarginCalculatorContextProviderProps) => {
    const { user } = useAuthContext();

    const [toolMarginiItems, setToolMarginiItems] = useState<MarginCalculationItem[] | []>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<any>(null);

    useEffect(() => {
        // get toolMarginiItems local data 
        const local = localStorage.getItem(`${user?.id}-toolMarginiItems`);
        const toolMarginiData = local ? JSON.parse(local) : null;
        const localToolMarginiItems = toolMarginiData ? toolMarginiData.toolMarginiItems : [];
        setToolMarginiItems(localToolMarginiItems);

        // get selectedCustomer from local 
        const localCustomer = localStorage.getItem(`${user?.id}-selectedCustomer`);
        const selectedCustomerData = localCustomer ? JSON.parse(localCustomer) : null;
        const localSelectedCustomer = selectedCustomerData ? selectedCustomerData.selectedCustomer : null;
        setSelectedCustomer(localSelectedCustomer);
    }, [user]);

    useEffect(() => {
        if (user) {
            localStorage.setItem(`${user?.id}-toolMarginiItems`, JSON.stringify({ toolMarginiItems }));
        }
    }, [toolMarginiItems, user?.id, user]);

    useEffect(() => {
        if (user) {
            localStorage.setItem(`${user?.id}-selectedCustomer`, JSON.stringify({ selectedCustomer }));
        }
    }, [selectedCustomer, user?.id, user]);

    return (
        <MarginCalculatorContext.Provider value={{ toolMarginiItems, setToolMarginiItems, selectedCustomer, setSelectedCustomer }}>
            {children}
        </MarginCalculatorContext.Provider>
    );
};
