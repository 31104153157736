// Button.jsx
import React, { ReactNode } from "react";
import classnames from "classnames";
// Icons
import { FaDownload, FaRegCalendarAlt, FaRegFile, FaSave, FaTrash, FaUpload } from "react-icons/fa";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { GrPowerReset, GrEdit } from "react-icons/gr";

enum Variant {
    primary,
    secondary,
    tertiary,
    outline,
    text
}

enum Size {
    full
}

enum Icon {
    download,
    save,
    delete,
    letfArrow,
    reset,
    edit,
    calendar,
    file,
    upload
}

type Props = {
    variant: Variant;
    href: string;
    target?: "_self" | "_blank";
    size: Size;
    icon: Icon;
    type?: "reset" | "button";
    children?: ReactNode;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    isDisabled?: boolean | undefined;
    className?: string;
};

const VARIANT_MAPS: Record<Variant, string> = {
    [Variant.primary]: "uppercase px-10 bg-primary text-white hover:bg-black",
    [Variant.secondary]: "uppercase px-10 bg-secondary text-white hover:bg-sky-600",
    [Variant.tertiary]: "uppercase px-10 bg-white border-b text-black hover:bg-gray-500 hover:text-white",
    [Variant.outline]: "uppercase px-10 bg-white border border-primary hover:bg-primary hover:text-white",
    [Variant.text]: "px-0 items-start hover:underline",
};

const SIZE_MAPS: Record<Size, string> = {
    [Size.full]: "w-full"
};

export function Button(props: Props) {
    const { children, variant, size, icon, href, onClick, isDisabled, type, className, target } = props;

    let iconBtn = undefined;
    switch (icon) {
        case Icon.download:
            iconBtn = <FaDownload/>;
            break;
        case Icon.save:
            iconBtn = <FaSave/>;
            break;
        case Icon.delete:
            iconBtn = <FaTrash/>;
            break;
        case Icon.letfArrow:
            iconBtn = <AiOutlineArrowLeft/>;
            break;
        case Icon.reset:
            iconBtn = <GrPowerReset/>;
            break;
        case Icon.edit:
            iconBtn = <GrEdit/>;
            break;
        case Icon.calendar:
            iconBtn = <FaRegCalendarAlt/>;
            break;
        case Icon.file:
            iconBtn = <FaRegFile />;
            break;
        case Icon.upload:
            iconBtn = <FaUpload />;
            break;
    }



    return (
        <React.Fragment>
            {href ? (
                <a
                    href={href}
                    target={target ? target : "_self"}
                    className={classnames(
                        "inline-flex items-center justify-center py-2 disabled:opacity-50 disabled:cursor-not-allowed outline-offset-4",
                        VARIANT_MAPS[variant],
                        SIZE_MAPS[size],
                        className ? className : null,
                        !isDisabled ? "active:scale-95" : ""
                    )}
                >
                    {iconBtn && <span className="mr-2">{iconBtn}</span>}
                    {children}
                </a>
            ) : (
                <button
                    type={type ? type : "submit"}
                    className={classnames(
                        "inline-flex items-center justify-center py-2 disabled:opacity-50 disabled:cursor-not-allowed outline-offset-4",
                        VARIANT_MAPS[variant],
                        SIZE_MAPS[size],
                        className ? className : null,
                        !isDisabled ? "active:scale-95" : ""
                    )}
                    onClick={onClick}
                    disabled={isDisabled}
                >
                    {iconBtn && <span className="mr-2">{iconBtn}</span>}
                    {children}
                </button>
            )}
        </React.Fragment>
    );
}

Button.defaultProps = {
    variant: Variant.primary,
    href: null,
    target: "_self",
    size: null,
    icon: null
};

Button.variant = Variant;
Button.size = Size;
Button.icon = Icon;
