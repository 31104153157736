import { useTranslation } from "react-i18next";
import { useMarginCalculatorContext } from "../../hooks/use-context/useMarginCalculatorContext";
import client from "../../api/client";
import { useRef, useState } from "react";
import { FaFileImport } from "react-icons/fa";
import { Loader } from "../Loader";
import { productsListImport } from "../../utils/productsListImport";
import { toast } from "react-toastify";

export const MarginCalculationImport = () => {
    const { t } = useTranslation();
    const { toolMarginiItems, setToolMarginiItems, selectedCustomer } = useMarginCalculatorContext();
    const importRef: any = useRef();

    const [isLoading, setIsLoading] = useState(false);

    const importMarginItems = async (data: any) => {
        setIsLoading(true);
        try {
            const res = await client.post("import/margin-calculation", { data: JSON.stringify(data), customer_code: selectedCustomer.code });

            if (res.data.error) {
                toast.error(t(res.data.error));
                setIsLoading(false);
                return;
            }
            
            setToolMarginiItems(prevItems => [...res?.data?.items, ...prevItems]);
            toast.success(t("File importato con successo"));
            
            setIsLoading(false);
        } catch (err: any) {
            toast.error(t(err.message));
            setIsLoading(false);
        }
    };
    
    const handleImportXlsx = async (e: any) => {
        const file = e.target.files[0];
        
        try {
            const data = await productsListImport(file, toolMarginiItems, t);
            importMarginItems(data);
        } catch (err: any) {
            toast.error(t(err.message));
        }

        importRef.current.value = null;
        window.scrollTo(0, 0);
    };

    return (
        <>
            <div>
                <label className={`${selectedCustomer ? "cursor-pointer" : "opacity-50 cursor-not-allowed"} inline-flex items-center justify-center px-10 py-2 uppercase bg-secondary text-white hover:bg-sky-600 active:scale-95`} htmlFor="file_upload"
                >
                    <FaFileImport className="mr-2" />
                    Import XLS
                </label>
                <input type="file"
                    className="hidden"
                    id="file_upload"
                    ref={importRef}
                    onChange={(e) => { handleImportXlsx(e); }}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx, .csv, text/csv, .numbers"
                    disabled={!selectedCustomer}
                />
            </div>
            {
                isLoading && <Loader />
            }
        </>
    );
};
