import { useTranslation } from "react-i18next";
import { Title } from "../../Title";

type RothoNotesSummaryProps = {
    data: any
};

export default function RothoNotesSummary({ data }: RothoNotesSummaryProps) {
    const { t } = useTranslation();

    return (
        <>
            {
                data?.data?.note ?
                    <div className="mb-8 border p-6 space-y-4">
                        <Title variant={Title.variant.secondary}>{t("Note per rothoblaas")}</Title>

                        <div className="text-sm font-light">
                            {data?.data?.note}
                        </div>
                    </div>
                    : null
            }
        </>
    );
}
