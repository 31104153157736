import { useTranslation } from "react-i18next";
import { useDiscountTableContext } from "../../hooks/use-context/useDiscountTableContext";
import { useFormContext } from "react-hook-form";
import classNames from "classnames";
import { InfoTooltip } from "../commom/InfoTooltip";
import { MdClose } from "react-icons/md";
import { FaLock } from "react-icons/fa";

export const DiscountFuture = ({ current, future }: any) => {
    const { t } = useTranslation();
    
    const { formatDate, editable, newDate, getDate } = useDiscountTableContext();

    const { register, unregister, watch, formState, getValues, setValue, trigger } = useFormContext();

    const itemCode = current?.itemkey;
    const discount1 = future?.pricevalue;
    const discount2 = future?.pricevalue2;
    const from = getDate(future?.validfrom);
    const until = getDate(future?.validuntil);

    const today = newDate();

    if (!!from && editable) {
        register(`data.${itemCode}.future.date.exist`, {
            value: from.substring(0, 10),
        });
    }

    const errors: any = formState.errors;

    const discount1error = errors?.data?.[itemCode]?.future?.discount?.t200;
    const discount2error = errors?.data?.[itemCode]?.future?.discount?.t290;
    const discount1input = watch(`data.${itemCode}.future.discount.t200`);
    
    const fromError = errors?.data?.[itemCode]?.future?.date?.from;
    const untilError = errors?.data?.[itemCode]?.future?.date?.until;

    function generateCorrectDate(myPrevious: any, offset: number, myDate: any = null) {
        const today = newDate(myDate);
        const previous = newDate(myPrevious);

        if (today > previous) {
            today.setDate(today.getDate() - offset);
            return today.toISOString().substring(0, 10);
        } else {
            previous.setDate(previous.getDate() - offset + 1);
            return previous.toISOString().substring(0, 10);
        }
    }

    const isValueLocked = !!watch(`data.${itemCode}.future.date.until`);

    function generateTodayOrPrevious() {
        const today = newDate();
        const validFrom = newDate(from);

        if (validFrom >= today) {
            return validFrom.toISOString().substring(0, 10);
        } else {
            return today.toISOString().substring(0, 10);
        }
    }

    function handleCloseDiscount() {
        if (isValueLocked) {
            setValue(`data.${itemCode}.future.date.until`, "", { shouldValidate: true });
        } else {
            setValue(`data.${itemCode}.future.date.until`, generateTodayOrPrevious(), { shouldValidate: true });
        }
        trigger();
    }

    return (
        <div className="grid grid-cols-3 gap-4 py-4 overflow-hidden col-span-3 bg-tertiary px-4">
            <div className="flex flex-col gap-1.5">
                {(!!discount1 || editable) && <div className="text-sm leading-6 text-gray-800">{ (discount1 || discount2) ? t("Sconto attivo") : t("Nuovo sconto")}</div>}

                {/* SCONTO 1 */}
                {discount1 ? (
                    // value
                    <div className="leading-8 flex gap-2 items-center">
                        <div className="bg-table-tertiary rounded-full w-6 h-6 aspect-square text-center text-xs leading-6">1</div>
                        <div>{parseFloat(discount1 ?? 0.00).toFixed(2)}%</div>
                    </div>
                ) : (
                    editable && <div className="leading-8 flex gap-2 items-center">
                        <div className="bg-table-tertiary rounded-full w-6 h-6 aspect-square text-center text-xs leading-6">1</div>
                        <div className="relative group">
                            <input
                                {...register(`data.${itemCode}.future.discount.t200`, {
                                    required: {
                                        message: t("Questo campo è obbligatorio"),
                                        value: !!getValues(`data.${itemCode}.future.date.from`),
                                    },
                                    max: {
                                        value: 99.99,
                                        message: t("max 99.99")
                                    },
                                    min: {
                                        value: 0.01,
                                        message: t("min 0.01")
                                    },
                                    onChange(event) {
                                        trigger();
                                        const previousFrom = getDate(current?.validfrom);
                                        const previousUntil = getDate(current?.validuntil);

                                        if ((event.target.value > 0 && event.target.value < 100) || event.target.validity.badInput) {
                                            setValue(`data.${itemCode}.current.date.until`, generateCorrectDate(previousFrom, 1), { shouldValidate: true });
                                            setValue(`data.${itemCode}.future.date.from`, generateCorrectDate(previousFrom, 0), { shouldValidate: true });
                                        } else {
                                            setValue(`data.${itemCode}.future.discount.t290`, null, { shouldValidate: true });
                                            setValue(`data.${itemCode}.future.date.from`, "", { shouldValidate: true });
                                            if (!previousUntil) {
                                                setValue(`data.${itemCode}.current.date.until`, "", { shouldValidate: true });
                                            } else {
                                                unregister(`data.${itemCode}.current.date.until`);
                                            }
                                        }
                                    },
                                })}
                                step='1'
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                                id={`data.${itemCode}.future.discount.t200`}
                                className={"text-sm leading-6 p-0.5 placeholder:text-gray-300 h-8" + (discount1error ? " border-rose-500 focus:outline-[0.5px] focus:outline-rose-500 focus:rounded border" : "")}
                                placeholder="0.00%"
                            />

                            {discount1error?.message && <div className="absolute group-hover:right-4 right-0.5 top-1/2 -translate-y-1/2 bg-white p-1 transition-all">
                                <InfoTooltip color="#f43f5e">{discount1error?.message}</InfoTooltip>
                            </div>}
                        </div>
                    </div>
                )}

                {/* SCONTO 2 */}
                {discount1 ? (
                    // value 
                    !!discount2 && <div className="leading-8 flex gap-2 items-center">
                        <div className="bg-table-tertiary rounded-full w-6 h-6 aspect-square text-center text-xs leading-6">2</div>
                        <div>{parseFloat(discount2 ?? 0.00).toFixed(2)}%</div>
                    </div>
                ) : (
                    editable && <div className="leading-8 flex gap-2 items-center">
                        <div className="bg-table-tertiary rounded-full w-6 h-6 aspect-square text-center text-xs leading-6">2</div>
                        <div className="relative group">
                            <input
                                {...register(`data.${itemCode}.future.discount.t290`, {
                                    max: {
                                        value: 99.99,
                                        message: t("max 99.99")
                                    },
                                    min: {
                                        value: 0.01,
                                        message: t("min 0.01")
                                    },
                                })}
                                disabled={!discount1input || discount1input <= 0}
                                step='1'
                                type="number"
                                id={`data.${itemCode}.future.discount.t290`}
                                className={"text-sm leading-6 p-0.5 placeholder:text-gray-300 h-8 disabled:cursor-not-allowed" + (discount2error ? " border-rose-500 focus:outline-[0.5px] focus:outline-rose-500 focus:rounded border" : "")}
                                placeholder="0.00%"
                                onWheel={(e: any) => e.target.blur()}
                            />
                            {discount2error?.message && <div className="absolute group-hover:right-4 right-0.5 top-1/2 -translate-y-1/2 bg-white p-1 transition-all">
                                <InfoTooltip color="#f43f5e">{discount2error?.message}</InfoTooltip>
                            </div>}
                        </div>
                    </div>
                )}
            </div>



            {/* FROM */}
            <div className="flex flex-col gap-1.5">
                {(!!from || editable) && <div className="text-sm leading-6 text-gray-800">{t("Valido dal")}</div>}
                {from ? (
                    <div className="leading-8">{formatDate(from)}</div>
                ) : (
                    editable && <div className="relative group">
                        <input
                            {...register(`data.${itemCode}.future.date.from`, {
                                required: {
                                    value: !!getValues(`data.${itemCode}.future.discount.t200`),
                                    message: t("Se lo sconto è presente questo campo è obbligatorio"),
                                },
                                validate: async (date) => {
                                    if (current) {
                                        const inputDate = newDate(date);
                                        const previousFrom = getDate(current?.validfrom);

                                        if (date) {
                                            return (inputDate > newDate(previousFrom) && inputDate >= today) || !date || t("la data deve essere maggiore alla data precedente e maggiore o uguale alla data odierna") as string;
                                        }
                                    }
                                    return true;
                                },
                                value: "",
                                onChange(event) {
                                    if (current) {
                                        const inputDate = newDate(event.target.value);
                                        const previousFrom = getDate(current?.validfrom);
                                        const previousUntil = getDate(current?.validuntil);

                                        if (event.target.value) {
                                            setValue(`data.${itemCode}.current.date.until`, generateCorrectDate(previousFrom, 1, inputDate), { shouldValidate: true });
                                        } else {
                                            if (previousUntil) {
                                                unregister(`data.${itemCode}.current.date.until`);
                                            } else {
                                                setValue(`data.${itemCode}.current.date.until`, "", { shouldValidate: true });
                                            }
                                        }
                                    }
                                    trigger();
                                },
                            })}
                            type="date"
                            id={`data.${itemCode}.future.date.from`}
                            className={classNames("text-sm leading-6 p-0.5 h-8", {
                                "border-rose-500 focus:outline-[0.5px] focus:outline-rose-500 focus:rounded border": !!fromError
                            })}
                        />
                        {fromError?.message && <div className="absolute group-hover:right-5 right-0.5 top-1/2 -translate-y-1/2 bg-white p-1 transition-all">
                            <InfoTooltip color="#f43f5e">{fromError?.message}</InfoTooltip>
                        </div>}
                    </div>
                )}
            </div>



            {/* UNTIL  */}
            <div className="flex flex-col gap-1.5">
                {(!!until || editable) && !!future?.validfrom && <div className="text-sm leading-6 text-gray-800">{t("Fino al")}</div>}
                {until ? (
                    <div className="leading-8">{formatDate(until)}</div>
                ) : (
                    editable && !!future?.validfrom && <div className="relative group">
                        <input
                            {...register(`data.${itemCode}.future.date.until`, {
                                validate: async (date) => {
                                    if (future) {
                                        const inputDate = newDate(date);
                                        const previousFrom = getDate(future?.validfrom);

                                        if (date) {
                                            return (inputDate >= newDate(previousFrom) && inputDate >= today) || !date || t("la data deve essere maggiore alla data precedente e maggiore o uguale alla data odierna") as string;
                                        }
                                    }
                                    return true;
                                },
                                value: "",
                            })}
                            disabled={true}
                            type="date"
                            id={`data.${itemCode}.future.date.until`}
                            className={classNames("text-sm leading-6 p-0.5 h-8", {
                                "border-rose-500 focus:outline-[0.5px] focus:outline-rose-500 focus:rounded border": !!untilError
                            })}
                        />
                        <button type="button" className={classNames("absolute top-1/2 -translate-y-1/2 p-1", {
                            "text-lg right-0": isValueLocked,
                            "text-xs right-0.5": !isValueLocked,
                        })} onClick={handleCloseDiscount}>
                            {isValueLocked ? (
                                <MdClose />
                            ) : (
                                <FaLock />
                            )}
                        </button>
                        {untilError?.message && <div className="absolute group-hover:right-5 right-0.5 top-1/2 -translate-y-1/2 bg-white p-1 transition-all">
                            <InfoTooltip color="#f43f5e">{untilError?.message}</InfoTooltip>
                        </div>}
                    </div>
                )}
            </div>
        </div>
    );
};
