import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { useSelectStyles } from "../../hooks/useSelectStyles";
import { FaTrash } from "react-icons/fa";
import useGetProductClasses from "../../api/discounts/useGetProductClasses";
import { useDiscountTableContext } from "../../hooks/use-context/useDiscountTableContext";
import { InfoTooltip } from "../commom/InfoTooltip";
import { Controller, useFormContext } from "react-hook-form";

export const DiscountNewRow = ({ data, index, level }: any) => {
    const { t } = useTranslation();

    const { updates, setUpdates, newDate, selectedSublevel } = useDiscountTableContext();

    const { unregister, watch, setValue, trigger } = useFormContext();

    const label = (data?.priceclassification ? data?.priceclassification + "-" + data?.code : data?.code);

    const discount1 = watch(`data.${data?.id}.new.discount.t200`);

    const { CustomStyles } = useSelectStyles();
    const { loadItemsOptions } = useGetProductClasses(data, false);
    const { loadItemsOptions: loadItemsClasses } = useGetProductClasses(data, true);

    const [Options, setOptions] = useState<any>([]);

    const today = newDate();

    const myRef = useRef<HTMLInputElement>(null);

    // handle select of new item for the current class and subclass
    const handleItemSearchChange = (inputValue: any, action: any) => {
        console.log(inputValue);
        
        if (action?.action === "select-option") {
            // console.log("inputValue");
            // console.log(inputValue);
            const temp = updates.map((update: any, myIndex: number) => {
                if (myIndex === index) {
                    return {
                        ...update,
                        code: inputValue?.value?.code,
                        id: inputValue?.value?.id || inputValue?.value?.code,
                        priceclassification: inputValue?.value?.priceclassification,
                        description: inputValue?.value?.description,
                    };
                } else {
                    return update;
                }
            });
            setUpdates(temp);
            trigger();
            setTimeout(() => {
                myRef?.current?.focus();
            }, 1);
        }
    };

    useEffect(() => {
        if (level === selectedSublevel && !label) {
            loadItemsClasses(data.subClass, (data: any) => {
                setOptions(data);
            });
        }
    }, []);

    // remove the current item form the array of new items
    const handleItemDelete = () => {
        unregister(`data.${data?.id}`);
        const temp = updates.filter((update: any, myIndex: number) => {
            if (myIndex !== index) {
                return {
                    ...update,
                };
            }
        });
        setUpdates(temp);
    };

    return (
        <div className="p-6 border-t border-table-tertiary last:border-none flex gap-4 bg-gray-50">
            <div className="grid grid-cols-4 gap-4 w-full">
                <div className={classNames("space-y-1", {
                    "col-span-1": label,
                    "col-span-4": !label,
                })}>
                    <label className="text-base ">{t("Seleziona classe/prodotto")}</label>
                    {label ? (
                        <div className="space-y-1">
                            <div className="font-light">{ label.split("-").map((level:any, myIndex:number) => {
                                if(myIndex > 0) {
                                    return <React.Fragment key={myIndex}>
                                        <span className="last:font-normal">{level}</span>
                                        {myIndex !== label.split("-").length - 1 && <span>-</span>}
                                    </React.Fragment>;
                                }
                            })}</div>
                            {data?.description && <div className="text-sm text-gray-400 font-light overflow-hidden overflow-ellipsis">{data?.description}</div>}
                        </div>
                    ) : (
                        <AsyncSelect
                            name="filter[item_code]"
                            loadOptions={loadItemsOptions}
                            isOptionDisabled={(option) => option.disabled}
                            defaultOptions={Options}
                            onChange={handleItemSearchChange}
                            styles={CustomStyles}
                            placeholder={t("Seleziona un prodotto...")}
                            loadingMessage={() => t("Caricamento in corso...")}
                            noOptionsMessage={() => t("Nessun risultato")}
                            openMenuOnClick={true}
                        />
                    )}
                </div>
                {label && <div className="col-span-3 grid grid-cols-3 gap-4">

                    {/* discount 1 */}
                    <div className="space-y-1">
                        <div>{t("Sconto 1 (%)")}</div>
                        <div className="relative group">
                            <Controller
                                name={`data.${data?.id}.new.discount.t200`}
                                defaultValue={""}
                                rules={{
                                    max: {
                                        value: 99.99,
                                        message: t("max 99.99")
                                    },
                                    min: {
                                        value: 0.01,
                                        message: t("min 0.01")
                                    },
                                    required: {
                                        value: true,
                                        message: t("Il campo è obbligatorio")
                                    },
                                    onChange(event) {
                                        // if discount 1 <= 0 set discount 2 to null
                                        if (event.target.value <= 0) {
                                            setValue(`data.${data?.id}.new.discount.t290`, "", { shouldValidate: true });
                                        }
                                    },
                                }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <>
                                        <input
                                            ref={myRef}
                                            name={`data.${data?.id}.new.discount.t200`}
                                            onChange={onChange}
                                            step='1'
                                            type="number"
                                            value={value}
                                            id={`data.${data?.id}.new.discount.t200`}
                                            className={"pl-4 py-3 placeholder:text-gray-300 h-12" + (error?.message ? " border-rose-500 focus:outline-[0.5px] focus:outline-rose-500 focus:rounded border" : "")}
                                            placeholder="0.00%"
                                            onWheel={(e:any) => e.target.blur()}
                                        />
                                        {error?.message && <div className="absolute group-hover:right-6 right-0.5 top-1/2 -translate-y-1/2 bg-white p-1 transition-all">
                                            <InfoTooltip color="#f43f5e">{error?.message}</InfoTooltip>
                                        </div>}
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    {/* discount 2 */}
                    <div className="space-y-1">
                        <div>{t("Sconto 2 (%)")}</div>
                        <div className="relative group">
                            <Controller
                                name={`data.${data?.id}.new.discount.t290`}
                                defaultValue={""}
                                rules={{
                                    max: {
                                        value: 99.99,
                                        message: t("max 99.99")
                                    },
                                    min: {
                                        value: 0.01,
                                        message: t("min 0.01")
                                    },
                                }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <>
                                        <input
                                            name={`data.${data?.id}.new.discount.t290`}
                                            onChange={onChange}
                                            step='1'
                                            type="number"
                                            value={value}
                                            id={`data.${data?.id}.new.discount.t290`}
                                            className={"pl-4 py-3 placeholder:text-gray-300 h-12" + (error?.message ? " border-rose-500 focus:outline-[0.5px] focus:outline-rose-500 focus:rounded border" : "")}
                                            placeholder="0.00%"
                                            disabled={!discount1 || discount1 <= 0}
                                            onWheel={(e: any) => e.target.blur()}
                                        />
                                        {error?.message && <div className="absolute group-hover:right-6 right-0.5 top-1/2 -translate-y-1/2 bg-white p-1 transition-all">
                                            <InfoTooltip color="#f43f5e">{error?.message}</InfoTooltip>
                                        </div>}
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    {/* date from */}
                    <div className="space-y-1">
                        <div>{t("Valido dal")}</div>
                        <div className="relative group">
                            <Controller
                                name={`data.${data?.id}.new.date.from`}
                                defaultValue={(today as Date).toISOString().substring(0,10)}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t("- questo campo è obbligatorio"),
                                    },
                                    validate: async (date) => {
                                        const inputDate = newDate(date);

                                        return (inputDate >= today) || !date || (t("la data deve essere maggiore o uguale alla data odierna") as string);
                                    },
                                }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <>
                                        <input
                                            name={`data.${data?.id}.new.date.from`}
                                            onChange={onChange}
                                            value={value}
                                            id={`data.${data?.id}.new.date.from`}
                                            type="date"
                                            className={"pl-4 pr-1 py-3 placeholder:text-gray-300 h-12" + (error?.message ? " border-rose-500 focus:outline-[0.5px] focus:outline-rose-500 focus:rounded border" : "")}
                                        />
                                        {error?.message && <div className="absolute group-hover:right-6 right-0.5 top-1/2 -translate-y-1/2 bg-white p-1 transition-all">
                                            <InfoTooltip color="#f43f5e">{error?.message}</InfoTooltip>
                                        </div>}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                </div>}
            </div>

            {/* delete current item */}
            <div className="w-14 flex justify-center items-center">
                <div className="cursor-pointer" onClick={() => handleItemDelete()}>
                    <FaTrash />
                </div>
            </div>
        </div>
    );
};
