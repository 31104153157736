import { useTranslation } from "react-i18next";
import OrdersSearchBar, { OrderSearchParams } from "../components/orders/OrdersSearchBar";
import { OrdersTable } from "../components/orders/OrdersTable";
import { Title } from "../components/Title";
import { useCallback, useEffect, useState } from "react";
import { ImFilesEmpty } from "react-icons/im";
import { Loader } from "../components/Loader";
import Pagination from "../components/Pagination";
import useOrders from "../api/orders/useOrders";
import SubRowAsync from "../components/carts/SubRowAsync";
import { useSearchParams } from "react-router-dom";
import { Section } from "../components/Section";
import { useAuthContext } from "../hooks/use-context/useAuthContext";

const Orders = ({ setTitle }: any) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { roles } = useAuthContext();

    // state
    const [currentPage, setCurrentPage] = useState(1);
    const [params, setParams] = useState<OrderSearchParams>({});
    const [canFetch, setCanFetch] = useState<boolean>(roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT", "TS"]));

    // react query
    const { orders, isLoading, isFetching } = useOrders({ page: currentPage, ...params }, canFetch);

    // SUB ROW RENDER FUNCTION
    const renderRowSubComponent = useCallback(
        ({ row }) => (
            <SubRowAsync row={row} endpoint="orders" />
        ),
        []
    );

    // reset page when params change
    useEffect(() => {
        setCurrentPage(1);

        const tmpParams: any = {};

        searchParams.forEach((value: any, key: any) => {
            tmpParams[key] = value;
        });

        setParams(tmpParams);
    }, [searchParams]);

    setTitle(t("I miei ordini"));

    return (
        <div>
            {(isLoading || isFetching) && canFetch && <Loader />}

            <Section title={"Cerca ordine"} className="mb-10">
                <OrdersSearchBar setSearchParams={setSearchParams} setCanFetch={setCanFetch} />
            </Section>

            <>
                {
                    orders?.data?.length > 0 ?
                        <>
                            <OrdersTable
                                items={orders?.data}
                                setParams={setParams}
                                renderRowSubComponent={renderRowSubComponent}

                            />
                            <Pagination
                                currentPage={currentPage}
                                totalCount={orders?.meta.total}
                                pageSize={10}
                                onPageChange={setCurrentPage}
                            />
                        </>
                        :
                        <div className="h-96 flex flex-col items-center justify-center space-y-6">
                            <span className="text-9xl text-gray-200"><ImFilesEmpty /></span>
                            {canFetch ? (
                                <p className="text-2xl text-gray-600">{t("Nessun ordine trovato")}</p>
                            ) : (
                                <p className="text-2xl text-gray-600">{t("Imposta un filtro per effettuare una ricerca.")}</p>
                            )}
                        </div>
                }
            </>
        </div>
    );
};

export default Orders;
