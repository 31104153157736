import { useQuery } from "@tanstack/react-query";
import { useShippingContext } from "../../hooks/use-context/useShippingContext";
import client from "../client";

type useFreightCostParams = {
    customer_code: string,
    delivery_address: any,
    cart_total_weight: number,
    cart_net_price?: number,
    cart_shipping_method: string,
};

export default function useFreightCost(params: useFreightCostParams) {
    const { free_port, setShippingMethod, setShippingMethods } = useShippingContext();

    const fetchFreightCost = async (params: any) => {
        const res = await client.get("shipping/freight-cost", {
            params: params
        });
        return res.data;
    };

    // GET SINGLE ORDER
    const { data: freightCost, isLoading, isFetching } = useQuery(["freight-cost", params],
        () => fetchFreightCost(params),
        {
            refetchOnWindowFocus: false,
            enabled: !!params.cart_total_weight && !!params.delivery_address && !!params.customer_code,
            onSuccess: (data) => {
                if (data.success === true) {
                    // case ws is up
                    setShippingMethods([data.data.standard, data.data["express-dhl"], data.data["express-tnt"]]);
                    if (free_port) {
                        setShippingMethod(null);
                    } else {
                        if ((data?.data.standard?.messages?.length < 1 && data?.data.standard?.freightCost >= 0) && params.cart_shipping_method === "standard") {
                            setShippingMethod(data.data.standard);
                        } else if ((data.data["express-dhl"].messages?.length < 1 && data.data["express-dhl"].freightCost >= 0) && params.cart_shipping_method === "express-dhl") {
                            setShippingMethod(data.data["express-dhl"]);
                        } else if ((data.data["express-tnt"].messages?.length < 1 && data.data["express-tnt"].freightCost >= 0) && params.cart_shipping_method === "express-tnt") {
                            setShippingMethod(data.data["express-tnt"]);
                        } else if ((data?.data.standard?.messages?.length < 1 && data?.data.standard?.freightCost >= 0) && !params.cart_shipping_method) {
                            setShippingMethod(data.data.standard);
                        } else {
                            setShippingMethod(null);
                        }
                    }
                } else if (data.success === false) {
                    // case ws is up but cannot calculate new address
                    setShippingMethods([]);
                    setShippingMethod(null);
                }

            }
        }
    );

    return { freightCost, isLoading, isFetching };
}