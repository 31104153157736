import { useNavigate, useParams } from "react-router-dom";
import { getCurrencyDecimals } from "../../utils/getCurrencyDecimals";
import { CartClientInfo } from "../cart/CartClientInfo";
import ExtraServicesSummary from "../cart/step_3/ExtraServicesSummary";
import ShippingInfoSummary from "../cart/step_3/ShippingInfoSummary";
import CartCostsSummary from "../cart/step_3/CartCostsSummary";
import ProductsSummary from "../commom/ProductsSummary";
import { Loader } from "../Loader";
import useOffer from "../../api/offers/useOffer";
import OffersActions from "./OffersActions";
import { useOrderMutations } from "../../api/orders/useOrderMutations";
import { Button } from "../Button";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import useOffersMutations from "../../api/offers/useOffersMutations";
import { OfferData } from "./OfferData";
import { Slide, ToastContainer } from "react-toastify";
import RothoNotesSummary from "../cart/step_3/RothoNotesSummary";
import DuplicationModal from "../templates/DuplicationModal";

const OfferDetails = ({ isSummary = false, setTitle, setIsStepperDisabled }: any) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { roles } = useAuthContext();

    // state
    const [openDuplicateOffer, setOpenDuplicateOffer] = useState<any>(false);

    // react query
    const { data: offer, summary, isLoading, isFetching, summaryFecthing } = useOffer(id, isSummary);

    // hooks
    const { createOrder } = useOrderMutations();
    const { duplicateOffer } = useOffersMutations();

    const currency = offer?.data?.isCustomerProposal ? offer?.data?.currency_id : offer?.data?.customer?.currency;
    const numberFormat = offer ? new Intl.NumberFormat(offer?.data?.customer?.language_id, { style: "currency", currency: currency, minimumFractionDigits: getCurrencyDecimals(currency), maximumFractionDigits: getCurrencyDecimals(currency) }) : null;

    const handlePreviousStepClick = () => {
        navigate(-1);
    };

    useEffect(() => {
        setTitle(`${t("Offerta")} #${offer?.data?.offer_number}${offer?.data?.offer_version !== "0" ? `/${offer?.data?.offer_version}` : ""}`);
        setIsStepperDisabled(!!offer?.data?.offer_proposed_at);
    }, [offer]);

    return (
        <>
            {(isLoading || isFetching || summaryFecthing || createOrder.isLoading || duplicateOffer.isLoading) && <Loader />}

            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />

            {
                offer &&
                <>
                    <DuplicationModal
                        label={t("Duplica offerta")}
                        modalState={openDuplicateOffer}
                        setModalState={setOpenDuplicateOffer}
                        data={offer?.data}
                        type="offer"
                    />

                    {/* {
                        !isSummary &&
                        <Title variant={Title.variant.primary}>{`Offerta #${offer?.data?.offer_number}${offer?.data?.offer_version !== '0' ? `/${offer?.data?.offer_version}` : ''}`}</Title>
                    } */}

                    <OfferData data={offer} />

                    <CartClientInfo data={offer} />

                    <ShippingInfoSummary data={offer} numberFormat={numberFormat} summary={summary?.data ?? offer?.data?.summary} />

                    <ProductsSummary data={offer} summary={summary?.data ?? offer?.data?.summary} availabitiliy={true} />

                    <ExtraServicesSummary data={offer} />

                    <RothoNotesSummary data={offer} />

                    <CartCostsSummary summary={summary?.data ?? offer?.data?.summary} data={offer} />

                    {/* warning messages legallock */}
                    {
                        (offer?.data?.customer?.legallock || offer?.data?.customer?.invoicing?.legallock) ?
                            <div className="py-2 px-6">
                                {
                                    roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ?
                                        <p className="text-sm text-red-500">{t("Cliente con blocco finanziario. Possibile procedere solo con carrelli e offerte. Per maggiori informazioni rivolgiti al tuo Tecnico Commerciale")}</p>
                                        : <p className="text-sm text-red-500">{t("Cliente con blocco finanziario. Possibile procedere solo con carrelli e offerte. Per maggiori informazioni rivolgiti al Credit Management")}</p>
                                }
                            </div>
                            : null
                    }

                    {/* Warning messages iva & shipping methods */}
                    {
                        (!offer?.data?.shipping_info?.shipping_methods_price || summary?.data?.total_iva === 0) && !offer?.data?.isCustomerProposal &&
                        <div className="py-2 px-6">
                            {/* case no freight cost no iva */}
                            {!offer?.data?.shipping_info?.shipping_methods_price && !summary?.data?.tax_calculation ? <p className="text-xs text-red-500"> {t("Calcolo costo di trasporto e IVA non disponibile, gli importi saranno aggiornati in conferma d’ordine.")}</p> : null}
                            {/* case no iva */}
                            {!!offer?.data?.shipping_info?.shipping_methods_price && !summary?.data?.tax_calculation ? <p className="text-xs text-red-500"> {t("Calcolo IVA non disponibile, l'importo sarà aggiornato in conferma d’ordine.")}</p> : null}
                            {/* case no freight cost (check if this case actually exists beacause when there's no freight cost probably cannot calculate iva) */}
                            {!offer?.data?.shipping_info?.shipping_methods_price && summary?.data?.tax_calculation ? <p className="text-xs text-red-500"> {t("Calcolo costo di trasporto non disponibile, l'importo sarà aggiornato in conferma d’ordine.")}</p> : null}
                        </div>
                    }

                    {/* warning message for items with priceclassification S-H0-45 */}
                    {
                        offer?.data?.items.find((item: any) => item?.priceclassification?.startsWith("S-H0-45")) &&
                        <div className="py-2 px-6">
                            <p className="text-xs text-red-500">{t("Rivolgiti al tuo TC per trasformare in ordine.")}</p>
                        </div>
                    }

                    {
                        offer &&
                        <div className={`flex ${isSummary ? "justify-between" : "justify-end"}`}>
                            {
                                isSummary &&
                                <Button variant={Button.variant.text} icon={Button.icon.letfArrow} onClick={handlePreviousStepClick}>
                                    {t("Indietro")}
                                </Button>
                            }
                            {
                                !offer?.data?.isCustomerProposal &&
                                <OffersActions
                                    offer={offer}
                                    setOpenDuplicateOffer={setOpenDuplicateOffer}
                                />
                            }
                        </div>
                    }

                </>
            }
        </>
    );
};

export default OfferDetails;
