export const getExpiredDays = (expiration_date: any) => {
    const date = new Date(expiration_date);
    const today = new Date();

    if (date > today) return 0;

    const diff = today.getTime() - date.getTime();

    return diff;
};

export const convertMiliseconds = (miliseconds: any, format: any) => {
    let days, hours, minutes, seconds, total_hours, total_minutes, total_seconds;

    total_seconds = Math.floor(miliseconds / 1000);
    total_minutes = Math.floor(total_seconds / 60);
    total_hours = Math.floor(total_minutes / 60);
    days = Math.floor(total_hours / 24);

    seconds = total_seconds % 60;
    minutes = total_minutes % 60;
    hours = total_hours % 24;

    switch (format) {
        case "s":
            return total_seconds;
        case "m":
            return total_minutes;
        case "h":
            return total_hours;
        case "d":
            return days;
        default:
            return { d: days, h: hours, m: minutes, s: seconds };
    }
};