/**
 * @param date 
 * @param years 
 * @returns new date with subtracted years converted to date string
 */
export const subtractYears = (date: Date, years: number) => {
    // TODO: extend function to return different formats dd-mm-yyyy
    date.setFullYear(date.getFullYear() - years);

    // Get the day of the month
    const dd = date.getDate();
    // Get the month (adding 1 because months are zero-based)
    const mm = date.getMonth() + 1;
    // Get the year
    const yyyy = date.getFullYear();

    return yyyy + "-" + pad(mm, 10) + "-" + pad(dd, 10);
};

function pad(num: number, size: number) {
    let stringNum = num.toString();

    if (num < size) {
        stringNum = "0" + stringNum;
    }

    return stringNum;
}
