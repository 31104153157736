import classNames from "classnames";

type Props = {
    children: any;
    variant?: "primary" | "ghost" | "secondary";
    disabled?: boolean
};

export function Tag({
    children,
    variant = "primary",
    disabled = false,
}: Props) {
    return (
        <div className={classNames("px-2 py-1 rounded-sm", {
            "bg-black text-white": variant === "primary",
            "bg-zinc-100": variant === "ghost",
            "bg-transparent border border-black": variant === "secondary",
            "opacity-30": disabled,
        })}>
            {children && <span className="uppercase text-xs font-medium">{children}</span>}
        </div>
    );
}