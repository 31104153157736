import { useQuery } from "@tanstack/react-query";
import client from "../client";

export default function useOrder(order_id: string | undefined) {
    const fetchOrder = async (order_id: string | undefined) => {
        const res = await client.get(`orders/${order_id}`);
        return res.data;
    };

    // GET SINGLE ORDER
    const { data: order, isLoading, isFetching } = useQuery(["order", order_id],
        () => fetchOrder(order_id),
        {
            refetchOnWindowFocus: false,
            enabled: !!order_id
        }
    );

    return { order, isLoading, isFetching };
}
