import { useCallback } from "react";
import client from "../client";

function useGetCountriesRegions() {

    const getCountries = useCallback(async () => {
        const res = await client.get("/countries");

        return res.data.countries;
    }, []);

    const getRegions = async (country_id: string) => {
        const params = {
            country_id: country_id
        };

        const res = await client.get("/countries/regions", { params: params });

        return res.data.regions;
    };

    return { getCountries, getRegions };
}

export default useGetCountriesRegions;