import axios from "axios";
import i18n from "../i18next/i18n";
import { AvailableLanguages } from "../constants/AvailableLanguages";

const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
    }
});

// REQUEST INTERCEPTOR
client.interceptors.request.use(
    function (config) {
        const user = localStorage.getItem("user");
        const impersonateToken = sessionStorage.getItem("impersonate-token");

        // set impersonate token
        if (impersonateToken) {
            if (config.headers) {
                config.headers["X-Impersonate-Token"] = impersonateToken;
            }
        }

        // set sanctum token
        if (user !== "null") {
            const token = user ? JSON.parse(user).auth_token : null;
            if (config.headers) {
                config.headers["Authorization"] = "Bearer " + token;
            }
        }

        // mando lingua interfaccia
        if (i18n?.language && AvailableLanguages.find((el) => el.value === i18n?.language) !== null) {
            if (config.headers) {
                config.headers["Accept-Language"] = i18n.language;
            }
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// RESPONSE INTERCEPTOR
const responseHandler = (response: any) => {
    return response;
};

const errorHandler = (error: any) => {
    if (error?.response?.status === 401) {
        // clear user to force logout in case of unauthorized
        localStorage.removeItem("user");
        sessionStorage.clear();
        window.location.replace("/");
        // save intender route for redirect at login
        sessionStorage.setItem("intended_route", window.location.pathname);
    } else if ((error?.response?.status >= 300 && error?.response?.status <= 400) || error?.response?.status >= 500) {
        if (process.env.REACT_APP_ENV !== "production") {
            console.error("IN PRODUCTION THIS GENERATES A REDIRECT");
        } else {
            window.location.replace("/maintenance");
        }
    }
    return Promise.reject(error);
};

client.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

export default client;
