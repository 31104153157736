import { ReactNode, useEffect, useRef, useState } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";

import "@reach/tooltip/styles.css";
import classNames from "classnames";

type SliderProps = {
    children: ReactNode
};

export const Slider = ({ children }: SliderProps) => {
    const sliderRef: any = useRef(null);
    const [isLeftDisabled, setIsLeftDisabled] = useState(false);
    const [isRightDisabled, setIsRightDisabled] = useState(false);
    const [scrollWidth, setScrollWidth] = useState(0);
    const [clientWidth, setClientWidth] = useState(0);

    const slide = (right: boolean) => {
        if (right && !isRightDisabled) {
            sliderRef.current.scrollLeft = sliderRef.current.scrollLeft + sliderRef.current.clientWidth;
        } else if (!right && !isLeftDisabled) {
            sliderRef.current.scrollLeft = sliderRef.current.scrollLeft - sliderRef.current.clientWidth;
        }
    };

    // cambio lo stato delle freccie
    const setArrowStatus = (event:any) => {
        setIsRightDisabled((scrollWidth - clientWidth) === event.target.scrollLeft);
        setIsLeftDisabled(event.target.scrollLeft === 0);
    };

    useEffect(() => {
        setScrollWidth(sliderRef.current.scrollWidth);
        setClientWidth(sliderRef.current.clientWidth);
    }, [children]);

    return (
        <div className="relative w-full">
            <div ref={sliderRef} className="flex overflow-x-auto scroll-smooth pt-14 md:pt-0" onScroll={(event) => setArrowStatus(event)}>
                {children}
            </div>
            <div className={classNames("absolute -top-14 right-7 flex bg-white", {
                "hidden": scrollWidth <= clientWidth
            })}>
                <HiChevronLeft className={classNames("w-8 h-8 p-2", {
                    "cursor-pointer": !isLeftDisabled,
                    "opacity-50 cursor-not-allowed": isLeftDisabled,
                })} onClick={() => slide(false)}/>
                <HiChevronRight className={classNames("w-8 h-8 p-2", {
                    "cursor-pointer": !isRightDisabled,
                    "opacity-50 cursor-not-allowed": isRightDisabled,
                })} onClick={() => slide(true)}/>
            </div>
        </div>
    );
};
