import { useQuery } from "@tanstack/react-query";
import { useShippingContext } from "../../hooks/use-context/useShippingContext";
import client from "../client";

type useAddressesParams = {
    data: any,
    params: {
        customer_code: string,
        "filter[name]"?: string,
        "filter[street]"?: string,
        "filter[city]"?: string,
        "filter[country]"?: string,
        "filter[region]"?: string,
        "filter[postalcode]"?: string,
    }
};

type Params = {
    customer_code: string,
    "filter[name]"?: string,
    "filter[street]"?: string,
    "filter[city]"?: string,
    "filter[country]"?: string,
    "filter[region]"?: string,
    "filter[postalcode]"?: string,
};

export default function useAddresses(props: useAddressesParams) {
    // console.log(props);


    const { delivery_address, delivery_addresses, setDeliveryAddress, setDeliveryAddresses } = useShippingContext();
    // console.log(props);
    // console.log(delivery_address);

    const fetchOrder = async (params: Params) => {
        const res = await client.get("addresses", { params });
        return res.data;
    };

    // GET SINGLE ORDER
    const { data: addresses, isLoading, isFetching, fetchStatus } = useQuery(["addresses", props.params],
        () => fetchOrder(props.params),
        {
            refetchOnWindowFocus: false,
            // * fetch data only if there's no delivery address set
            enabled: !!props.data,
            // onSuccess: (data) => {

            //     setDeliveryAddresses(data?.data);

            //     if (props?.data?.data?.shipping_info?.address && !delivery_address) {
            //         // console.log('condition 1');

            //         setDeliveryAddress(props?.data?.data?.shipping_info?.address);
            //     } else if (!props?.data?.data?.shipping_info?.address && !delivery_address) {
            //         // console.log('condition 2');

            //         let address: any = data.data.find((address: any) => { return address.preferred === '1' });
            //         address ? setDeliveryAddress(address) : setDeliveryAddress(data.data[0]);
            //     } else if (data?.data.length > 0 && delivery_address) {
            //         // console.log('condition 3');

            //         // console.log(data.data);

            //         // check if delivery address is inside the array and reorder it
            //         if (data?.data.find((address: any) => address.code === delivery_address.code)) {
            //             // console.log('condition 4');
            //             let reorderedAddresses = data?.data.filter((address: any) => { return address?.code !== delivery_address?.code });
            //             reorderedAddresses.unshift(delivery_address);

            //             setDeliveryAddresses(reorderedAddresses);
            //         }
            //     }
            // }
        }
    );

    return { addresses, isLoading: isLoading && fetchStatus !== "idle", isFetching };
}
