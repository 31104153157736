import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, usePagination, Column } from "react-table";
import useMarginCalculationMethods from "../../hooks/useMarginCalculationMethods";
import { Item } from "../../interfaces/Item";


type PreviewShippingItemsTableProps = {
    data: any;
};

export default function PreviewShippingItemsTable({ data: propsData }: PreviewShippingItemsTableProps) {
    const { t } = useTranslation();

    // table data
    const data = useMemo(
        () => {
            return propsData?.items;
        },
        [propsData?.items]
    );

    const columns: Array<Column<Item>> = useMemo(
        () => [
            {
                Header: t("Codice articolo").toString(),
                accessor: "code",
                Cell: (props) => {
                    return <span>{props.cell.row.original.code.toUpperCase()}</span>;
                }
            },
            {
                Header: t("Descrizione").toString(),
                accessor: "description",
            },
            {
                Header: t("Quantità").toString(),
                accessor: "qty",
                Cell: (props) => {
                    return <> {parseInt(props.row.original.qty.toString())}</>;
                }
            }
        ],
        [t]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
    } = useTable({ columns, data, initialState: { pageSize: 100 } }, useSortBy, usePagination);

    return (
        <div className='border'>
            <table {...getTableProps()} className="w-full">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className="text-left border-b p-3"
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} className='odd:bg-tertiary'>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps({ className: (cell.column as any).className })}
                                            className="p-3"
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
