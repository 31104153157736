import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../Button";
import debounce from "lodash.debounce";
import { ItemSelect } from "../../commom/ItemSelect";
import client from "../../../api/client";

type CartSearchBarProps = {
    setSearchParams: React.Dispatch<React.SetStateAction<searchParams>>
    selectedCustomer: any
};

type searchParams = {
    "filter[item_code]"?: string,
    "filter[customer_code]"?: string,
    "filter[search]"?: string,
    // 'filter[price_classification]'?: string,
    "sort"?: string
};

type formData = {
    "filter[item_code]"?: string,
    "filter[customer_code]"?: string,
    "filter[search]"?: string,
    // 'filter[price_classification]'?: string,
};

export default function PriceListSearchBar({ setSearchParams, selectedCustomer }: CartSearchBarProps) {
    const { t } = useTranslation();

    // refs
    const itemCodeSelect = useRef<any>();

    // state
    const [formData, setFormData] = useState<formData>({});

    // ITEM CODE
    const handleItemSearchChange = (inputValue: any, action: any) => {
        if (action?.action === "select-option") {
            setFormData((prevFormData: any) => {
                return { ...prevFormData, [action?.name]: inputValue?.value?.code };
            });
        } else {
            const newForm = { ...formData };
            delete newForm[action?.name as keyof formData];
            setFormData(newForm);
        }
    };

    // GET ITEMS
    const getProducts = useCallback(async (value: string) => {
        const params = {
            "filter[search]": value,
            "filter[customer_code]": selectedCustomer?.code
        };

        const res = await client.get("products", { params: params });

        // console.log(res);

        return res.data.data.map((item: any) => {
            let pieces = null;
            if (parseInt(item.piecesperpackage, 10) > 1) {
                pieces = <>({parseInt(item.piecesperpackage, 10)} {item.piecesperpackageuom})</>;
            }
            return {
                label: <span>{item.code} {item?.replacing_code && (item?.replacing_code === value) ? <span className='font-bold p-1 rounded'>{t("Vecchio codice")}: {item?.replacing_code}</span> : null} - {item.description} {pieces}</span>,
                value: item
            };
        });
    }, [selectedCustomer]);

    const loadItemsOptions = useMemo(() => {
        return debounce((value: any, callback: any) => {
            getProducts(value).then((options) => callback(options));
        }, 1000);
    }, [getProducts]);

    const handleSubmit = (e: any) => {
        e.preventDefault();

        setSearchParams(formData);
    };

    const resetForm = () => {
        itemCodeSelect.current.clearValue();
        setFormData({});
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <fieldset className="flex items-center gap-x-6 mb-0">
                    <legend className="sr-only"><span>{t("Ricerca")}</span></legend>

                    <div className="w-full grid grid-cols-1 gap-x-6">
                        <div>
                            <label>{t("Codice articolo")}</label>
                            <ItemSelect
                                name="filter[search]"
                                ref={itemCodeSelect}
                                loadItemsOptions={loadItemsOptions}
                                handleChange={handleItemSearchChange}
                            />
                        </div>
                        {/* <div>
                            <label className="text-xs">{t('Gruppo prodotto')}</label>
                            <input
                                value={formData['filter[price_classification]'] ?? ''}
                                type="text"
                                name="filter[price_classification]"
                                onChange={e => handleInputsChange(e)}
                                placeholder={t("inserisci gruppo prodotto")}
                            />
                        </div> */}
                    </div>

                    <div className="self-end">
                        <Button variant={Button.variant.primary} size={Button.size.full}>{t("Cerca")}</Button>
                    </div>
                </fieldset>
                <Button
                    variant={Button.variant.text}
                    className="text-xs py-0"
                    icon={Button.icon.reset}
                    onClick={resetForm}
                >
                    {t("reset ricerca")}
                </Button>
            </form>
        </>
    );
}