import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { FaChevronDown, FaPlus } from "react-icons/fa";
import { useDiscountTableContext } from "../../hooks/use-context/useDiscountTableContext";
import { useFormContext } from "react-hook-form";
import { DiscountNewRow } from "./DiscountNewRow";
import { DiscountTableRow } from "./DiscountTableRow";

export const DiscountMainRow = ({ subClass }: any) => {
    const { t } = useTranslation();

    const { selectedSublevel, setSelectedSublevel, editable, updates, setUpdates } = useDiscountTableContext();

    const { trigger } = useFormContext();

    const addNewRow = () => {
        let rowExist = false;

        updates.find((e: any) => { if (!e?.code && e?.subClass === subClass?.code) { rowExist = true; } });
        if (!rowExist) {
            setUpdates([{ subClass: selectedSublevel, key: Math.trunc(Math.random() * 1000).toString() }, ...updates]);
        }
    };

    return (
        <div className={classNames("grid grid-rows-[min-content_0fr] rounded-sm border border-gray-300 transition-all", {
            "grid-rows-[min-content_1fr]": selectedSublevel === subClass.code,
        })}>
            <div className="py-3 px-6 flex gap-6 justify-between bg-table-tertiary hover:bg-table-secondary cursor-pointer" onClick={() => { setSelectedSublevel(selectedSublevel === subClass.code ? null : subClass.code); trigger(); }}>
                <div className="flex gap-6 items-center">
                    <FaChevronDown className={classNames("transition-all", {
                        "rotate-180": selectedSublevel === subClass.code
                    })} />
                    <div className="flex gap-4 items-center whitespace-nowrap">{subClass?.code?.substring(2)}<div className="font-light text-primary/80 overflow-hidden whitespace-nowrap overflow-ellipsis">{subClass.description}</div></div>
                </div>
                <div className="whitespace-nowrap">{t("{{n}} sconti", { n: subClass?.data?.length })}</div>
            </div>
            {/* discounts list  */}
            <div className={classNames({ "overflow-hidden": selectedSublevel !== subClass.code })}>
                {/* add new row  */}
                {editable && <div className="p-6">
                    <div className="cursor-pointer flex gap-2 items-center" onClick={() => { addNewRow(); }}>
                        <FaPlus />
                        <span className="font-semibold">{t("Aggiungi sconto")}</span>
                    </div>
                </div>}
                {/* new rows list  */}
                {editable && updates.map((data: any, index: number) => {
                    if (selectedSublevel === data.subClass) {
                        return <DiscountNewRow
                            key={data?.key}
                            data={data}
                            index={index}
                            level={subClass?.code}
                        />;
                    }
                })}
                {subClass?.data?.length > 0 ? subClass.data.map((discount: any, discountIndex: number) => {
                    return <DiscountTableRow
                        key={discountIndex}
                        discount={discount}
                        index={discountIndex}
                    />;
                }) : (
                    <div className="font-light leading-6 w-full flex justify-center items-center h-12">{t("Nessuno sconto")}</div>
                )}
            </div>
        </div>
    );
};
