import { useCallback, useMemo } from "react";
import debounce from "lodash.debounce";
import client from "../client";

const usePriceListings = () => {
    const getPriceListing = useCallback(async (search: string) => {
        const params = {
            "filter[search]": search
        };

        const res = await client.get("reserved-conditions/price-listings", { params: params });

        return res.data.data.map((priceList: any) => {
            return { ...priceList, label: `${priceList?.code} - ${priceList?.description}` };
        });
    }, []);

    const priceListing = useMemo(() => {
        return debounce((value: any, callback: any) => {
            getPriceListing(value).then((options) => callback(options));
        }, 1000);
    }, [getPriceListing]);

    return { priceListing };
};

export default usePriceListings;
