import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useShippingContext } from "../../../hooks/use-context/useShippingContext";
import { getCurrencyDecimals } from "../../../utils/getCurrencyDecimals";
import { InfoTooltip } from "../../commom/InfoTooltip";
import useExtraServicesMutations from "../../../api/extra-services/useExtraServicesMutations";
import { Section } from "../../Section";

type ExtraServicesProps = {
    cart: any,
    extraServices: any
};

export const ExtraServices = ({ cart, extraServices }: ExtraServicesProps) => {
    const { t } = useTranslation();

    const elementsRef: any = useRef([]);

    // state
    const [show, setShow] = useState(false);
    const [filteredExtraServices, setFilteredExtraServices] = useState([]);

    // context
    const { delivery_notice_info, setDeliveryNoticeInfo, resetDeliveyNoticeInfo } = useShippingContext();

    // react query
    const { addExtraService, deleteExtraService } = useExtraServicesMutations();

    const handleExtraServicesChange = async (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
        if (elementsRef.current[i] && !elementsRef.current[i].disabled) {
            elementsRef.current[i].disabled = true;
            // Perform the action
            if (e.target.checked) {
                resetDeliveyNoticeInfo();
                await addExtraService.mutateAsync({ cart_id: cart?.data?.id, code: e.target.name, isOffer: cart?.data?.offer_state });
            } else {
                await deleteExtraService.mutateAsync({ cart_id: cart?.data?.id, code: e.target.name, isOffer: cart?.data?.offer_state });
            }

            if (e.target.name === "TELEPHONE") {
                setShow(!show);
            }

            // After the action is complete, reset buttonRef.current.disabled to false
            elementsRef.current[i].disabled = false;

        }
    };

    const handleDeliveryNoticeInfoChange = (e: any) => {
        setDeliveryNoticeInfo({
            name: e.target.name,
            value: e.target.value
        });
    };

    useEffect(() => {
        if (cart?.data?.address?.country_id !== "IT") {
            setFilteredExtraServices(extraServices?.data?.filter((extra_service: any) => extra_service.code !== "TELEPHONE"));
        } else {
            setFilteredExtraServices(extraServices?.data);
        }
    }, [cart, extraServices]);

    const numberFormat = cart ? new Intl.NumberFormat(cart?.data?.customer?.language_id, { style: "currency", currency: cart?.data?.customer?.currency, minimumFractionDigits: getCurrencyDecimals(cart?.data?.customer?.currency), maximumFractionDigits: getCurrencyDecimals(cart?.data?.customer?.currency) }) : null;

    return (
        <Section title={"Servizi accessori"}>
            <div className="space-y-2 border-b">
                {/* extra services */}
                {
                    filteredExtraServices?.length > 0 && filteredExtraServices?.map((service: any, i: number) => (
                        <div className="flex justify-between items-center border-b last:border-b-0 py-3 font-light" key={service.code}>
                            <div className="flex items-center px-6">
                                <input
                                    id={service.code}
                                    ref={ref => { elementsRef.current[i] = ref; }}
                                    name={service.code}
                                    type="checkbox"
                                    className="mr-1 cursor-pointer accent-black"
                                    onChange={(e) => handleExtraServicesChange(e, i)}
                                    checked={cart?.data?.items.find((cart_item: any) => { return service.code === cart_item.code; }) ?? false}
                                />
                                <label htmlFor={service.code} className="p-0 cursor-pointer">{t(service.code)}</label>
                                {
                                    service?.code === "tail_lift" &&
                                    <InfoTooltip className="ml-1">
                                        {t("Consigliato in mancanza di mezzi per lo scarico")}
                                    </InfoTooltip>
                                }
                            </div>
                            <div>
                                {`${numberFormat?.format(service.pricevalue)}`}
                            </div>
                        </div>
                    ))
                }

                {/* container delivery_notice */}
                {
                    cart?.data?.items.find((cart_item: any) => { return cart_item.code === "TELEPHONE"; }) &&
                    <div className="space-y-6 p-6">
                        <div className="font-light">
                            {t("Nota bene: questo servizio implica 24h aggiuntive per la consegna")}
                        </div>
                        <div className="grid grid-cols-2 space-x-6">
                            <div>
                                <label>{t("Nome referente consegna")}</label>
                                <input
                                    required
                                    className="placeholder:text-xs"
                                    value={delivery_notice_info.name}
                                    type="text"
                                    name="name"
                                    maxLength={40}
                                    onChange={e => handleDeliveryNoticeInfoChange(e)}
                                    placeholder={t("inserisci referente della consegna")}
                                />
                            </div>
                            <div>
                                <label>{t("Telefono referente")}</label>
                                <input
                                    required
                                    className="placeholder:text-xs"
                                    value={delivery_notice_info.phone}
                                    type="text"
                                    name="phone"
                                    onChange={e => handleDeliveryNoticeInfoChange(e)}
                                    placeholder={t("inserisci telefono del referente")}
                                />
                            </div>
                        </div>
                        <div>
                            <label>{t("Note consegna")}</label>
                            <textarea
                                // required
                                className="placeholder:text-xs"
                                value={delivery_notice_info.note}
                                cols={30}
                                rows={3}
                                maxLength={60}
                                name="note"
                                onChange={e => handleDeliveryNoticeInfoChange(e)}
                                placeholder={t("inserisci le note per la consegna")}
                            />
                        </div>
                    </div>
                }
            </div>
        </Section>

    );
};
