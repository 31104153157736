import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../client";

type UpdateAddressParams = {
    offer_id: number,
    delivery_address: any
};

export default function useOfferAddressMutations() {
    const queryClient = useQueryClient();

    // SAVE CART
    const updateOfferAddress = useMutation(async (params: UpdateAddressParams) => {
        return await client.put(`offer-addresses/${params.offer_id}`, params);
    }, { onSuccess: () => { queryClient.invalidateQueries(["offer"]); } });

    return { updateOfferAddress };
}
