import { useCallback, useMemo } from "react";
import debounce from "lodash.debounce";
import client from "../client";

const useGetSalesLine = () => {
    // GET SALES LINE
    const getSalesLine = useCallback(async (search: string) => {
        const params = {
            "filter[search]": search
        };

        const res = await client.get("impersonate", { params: params });

        return res.data.data.map((salesLine: any) => {
            return { ...salesLine, label: `${salesLine.code} - ${salesLine.name}` };
        });
    }, []);

    const loadSaleslineOptions = useMemo(() => {
        return debounce((value: any, callback: any) => {
            getSalesLine(value).then((options) => callback(options));
        }, 1000);
    }, [getSalesLine]);

    return { loadSaleslineOptions };
};

export default useGetSalesLine;