import { useQuery } from "@tanstack/react-query";
import client from "../../client";

export default function usePricelistDownloadInfo(customer_code: string | null, pricelistIsFetching: any) {
    // GET PRICE LIST
    const fetchPriceList = async (customer_code: string | null) => {
        const res = await client.get(`customers/${customer_code}`);
        return res.data;
    };

    const { data: pricelistCustomer, isLoading, isFetching } = useQuery(["price-list-customer", customer_code],
        () => fetchPriceList(customer_code),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 9000,
            refetchInterval: 1000 * 60,
            enabled: !!customer_code && !pricelistIsFetching
        }
    );

    return { pricelistCustomer, pricelistCustomerIsLoading: isLoading, pricelistCustomerIsFetching: isFetching };
}