import { useQuery } from "@tanstack/react-query";
import client from "../client";

type AlternativeProductsParams = {
    "product_id": string,
    "filter[customer_code]": string
};

export default function useAlternativeProducts(params: AlternativeProductsParams, enabled: boolean = false) {
    // GET AVAILABILITY
    const getAlternativeProducts = async (params: AlternativeProductsParams) => {
        const res = await client.get(`/products/${params.product_id}/alternative-products`, { params: { "filter[customer_code]": params["filter[customer_code]"] } });
        return res.data;
    };

    // react query
    const { data, isLoading, isFetching } = useQuery(["cart", params], () => getAlternativeProducts(params), {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: enabled
    });

    return { data, isLoading, isFetching };
}