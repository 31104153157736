import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../client";

type UserId = {
    user_id: number
};

type UpdateUserParams = {
    user_id: string,
    params: {
        roles?: string[],
        permissions?: string[],
        languageId?: string,
        interfaceLanguageId?: string | null
    }
};

export default function useUsersMutations() {
    const queryClient = useQueryClient();

    // SAVE USER
    const saveUser = useMutation(async (data: UpdateUserParams) => {
        return await client.post(`user-management/${data.user_id}/roles-permissions/save`, data.params);
    }, { onSuccess: () => { queryClient.invalidateQueries(["user"]); } });

    // ACTIVATE
    const activateUser = useMutation(async (user_id: UserId) => {
        return await client.post(`users/${user_id}/activate`);
    }, { onSuccess: () => { queryClient.invalidateQueries(["user"]); } });

    // DEACTIVATE
    const deactivateUser = useMutation(async (user_id: UserId) => {
        return await client.post(`users/${user_id}/deactivate`);
    }, { onSuccess: () => { queryClient.invalidateQueries(["user"]); } });

    // SEND WELCOME MAIL
    const sendWelcomeMail = useMutation(async (user_id: UserId) => {
        return await client.post(`users/${user_id}/send-welcome-mail`);
    }, { onSuccess: () => { queryClient.invalidateQueries(["user"]); } });

    return { saveUser, activateUser, deactivateUser, sendWelcomeMail };
}