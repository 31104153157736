import { BsChevronDown, BsChevronExpand, BsChevronUp } from "react-icons/bs";

export const SortableHeader = ({ props, title }: any) => {

    return (
        <div className='flex items-center gap-x-1'>
            <span>
                {title}
            </span>

            {
                props?.column?.isSorted ?
                    props?.column.isSortedDesc ? <BsChevronUp className="text-sm min-w-4" /> : <BsChevronDown className="text-sm min-w-4" />
                    : props?.column.canSort ? <BsChevronExpand className="text-sm min-w-4" /> : null
            }
        </div>
    );
};
