export const AvailableLanguages = [{
    "value": "it",
    "label": "Italiano"
}, {
    "value": "en",
    "label": "English"
}, {
    "value": "de",
    "label": "Deutsch"
}, {
    "value": "es",
    "label": "Español"
}, {
    "value": "fr",
    "label": "Français"
}, {
    "value": "pt",
    "label": "Português"
}, {
    "value": "ru",
    "label": "Русский"
}];
