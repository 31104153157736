import React, { useState, useRef, useEffect } from "react";
import { Button } from "../components/Button";
import { Message } from "../components/Message";
import client from "../api/client";
import { useTranslation } from "react-i18next";
import { Loader } from "../components/Loader";
import { Link } from "react-router-dom";

const ResetPassword = () => {
    const { t } = useTranslation();

    // state
    const [email, setEmail] = useState("");
    const [sent, setSent] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    // ref hooks
    const emailRef = useRef<HTMLInputElement>(null!);

    // set the focus on the email input
    useEffect(() => {
        if (emailRef.current) {
            emailRef.current.focus();
        }
    }, []);

    // axios post that send an email to the customer
    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const params = JSON.stringify({
                email: email
            });
            setSent(false);
            setError(false);
            setErrorMessage("");

            const res = await client.post("forgot-password", params);

            if (res.status === 200) {
                setError(false);
                setSent(true);
            }
            setIsLoading(false);
        } catch (err: any) {
            if (err.response) {
                // Request made and server responded
                setErrorMessage(err.response?.data?.errors?.email.join(" "));
            }
            setSent(false);
            setError(true);
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && <Loader />}
            <div className="flex flex-col items-end bg-magazzino bg-cover h-full">
                <div className="flex flex-col items-center justify-center h-full py-10 px-8 2xl:px-28 bg-black/60">
                    <form onSubmit={handleSubmit} className="w-full md:w-[400px]">
                        <fieldset className="space-y-6 text-white">
                            <legend className="block w-full">
                                <span className="block uppercase text-xl text-center font-bold"> {t("reimposta la password")}</span>
                            </legend>

                            {error && <Message open={error} handleCloseMessage={() => { setError(false); }} variant={Message.variant.error}>{errorMessage}</Message>}

                            {
                                sent &&
                                <Message open={sent} handleCloseMessage={() => { setSent(false); }} variant={Message.variant.success}>
                                    <div className="space-x-2">
                                        <span>
                                            {t("E-mail di recupero inviata con successo, controlla la casella di posta e segui le istruzioni per il recupero della password.")} 
                                        </span>
                                        <Link className="underline text-primary" to={`${process.env.REACT_APP_URL}`}>{t("Torna alla pagina di login")}</Link>
                                    </div>
                                </Message>
                            }

                            {
                                !sent &&
                                <>
                                    <div>
                                        <label htmlFor="email"> {t("Indirizzo e-mail")}</label>
                                        <input
                                            className="text-black"
                                            type="email"
                                            id="email"
                                            ref={emailRef}
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>

                                    <div>
                                        <Button size={Button.size.full} variant={Button.variant.tertiary}>{t("Reset password")}</Button>
                                    </div>
                                </>
                            }

                        </fieldset>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
