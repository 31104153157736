import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomerSelect } from "../components/commom/CustomerSelect";
import { Title } from "../components/Title";
import useGetCustomers from "../api/customers/useGetCustomers";
import { Controller, useForm } from "react-hook-form";
import { Button } from "../components/Button";
import useSearchBarProducts from "../api/products/useSearchBarProducts";
import Pagination from "../components/Pagination";
import { Loader } from "../components/Loader";
import { ImFilesEmpty } from "react-icons/im";
import { subtractYears } from "../utils/subtractYears";
import usePriceHistory from "../api/orders/usePriceHistory";
import { PriceHistoryTable } from "../components/price-history/PriceHistoryTable";
import { ItemSelect } from "../components/commom/ItemSelect";
import { Section } from "../components/Section";

type PriceHistoryProps = {
    setTitle: Dispatch<SetStateAction<string | null>>
};

export const PriceHistory = ({ setTitle }: PriceHistoryProps) => {
    // hooks
    const { t } = useTranslation();
    const { loadCustomersOptions } = useGetCustomers();
    const { loadItemsOptions } = useSearchBarProducts();

    // react hook form
    const { register, control, setValue, handleSubmit, formState: { errors } } = useForm();

    // state
    const [currentPage, setCurrentPage] = useState(1);
    const [params, setParams] = useState<any>(null);
    const [selectedCustomer, setSelectedCustomer] = useState<any>(null);

    //refs
    const itemSelect: any = useRef();

    // react query
    const { priceHistory, isFetching } = usePriceHistory({ page: currentPage, ...params });

    // handle customer SELECT change
    const handleCustomerChange = (inputValue: any, reactHookFormOnChange: (...event: any[]) => void) => {
        setSelectedCustomer(inputValue);
        if (inputValue) {
            reactHookFormOnChange(inputValue?.code);
            setSelectedCustomer(inputValue);
        } else {
            setValue("customer_code", null);
            setSelectedCustomer(null);
            itemSelect.current.clearValue();
        }
    };

    // handle item search SELECT change
    const handleItemSearchChange = (inputValue: any, reactHookFormOnChange: (...event: any[]) => void) => {
        if (inputValue) {
            reactHookFormOnChange(inputValue?.value?.code);
        } else {
            setValue("item_code", null);
        }
    };

    const submit = async (data: any) => {
        setParams(data);
    };

    useEffect(() => {
        setTitle(t("Storico prezzi articoli clienti"));
        setValue("from", subtractYears(new Date(), 1));
        setValue("to", subtractYears(new Date(), 0));
    }, []);

    return (
        <Section title={"Ricerca"} className="space-y-6">
            {isFetching && <Loader />}

            <form onSubmit={handleSubmit(submit)}>
                <div className="space-y-6">
                    <Controller
                        control={control}
                        name='customer_code'
                        rules={{ required: `${t("- questo campo è obbligatorio")}` }}
                        render={({ field: { onChange }, fieldState: { error } }) => (
                            <CustomerSelect
                                label="Seleziona cliente"
                                loadCustomersOptions={loadCustomersOptions}
                                handleChange={(e: any) => handleCustomerChange(e, onChange)}
                                error={error}
                                selectedCustomer={selectedCustomer}
                                setSelectedCustomer={setSelectedCustomer}
                            />
                        )}
                    />

                    <div className="flex justify-between gap-x-4">
                        <Controller
                            control={control}
                            name='item_code'
                            rules={{ required: t("- questo campo è obbligatorio") }}
                            render={({ field: { onChange }, fieldState: { error } }) => (
                                <div className="flex-1">
                                    <label>{t("Codice articolo")}</label>
                                    <ItemSelect
                                        ref={itemSelect}
                                        loadItemsOptions={loadItemsOptions}
                                        handleChange={(e: any) => handleItemSearchChange(e, onChange)}
                                        disabled={!selectedCustomer}
                                        error={error}
                                    />
                                </div>
                            )}
                        />

                        <div className="flex flex-1 gap-x-4">
                            <div className="flex-1">
                                <label htmlFor="from" className="text-sx">{t("Da")}</label>
                                <input {...register("from", { required: t("- questo campo è obbligatorio") })} type="date" id="from" />
                                {errors.from && <p className="text-sm text-rose-500 mt-1">{errors.from.message}</p>}
                            </div>

                            <div className="flex-1">
                                <label htmlFor="to" className="text-sx">{t("A")}</label>
                                <input {...register("to", { required: t("- questo campo è obbligatorio") })} type="date" id="to" />
                                {errors.to && <p className="text-sm text-rose-500 mt-1">{errors.to.message}</p>}
                            </div>
                        </div>
                    </div>

                    <div className="w-1/3 flex gap-x-2">
                        <Button size={Button.size.full}>{t("Cerca")}</Button>
                    </div>
                </div>
            </form>

            {
                priceHistory?.data?.length > 0 ?
                    <div>
                        <PriceHistoryTable
                            items={priceHistory?.data}
                            selectedCustomer={selectedCustomer}
                        />

                        <Pagination
                            currentPage={currentPage}
                            totalCount={priceHistory.meta.total}
                            pageSize={10}
                            onPageChange={setCurrentPage}
                        />
                    </div>
                    :
                    <div className="h-96 flex flex-col items-center justify-center space-y-6">
                        <span className="text-9xl text-gray-200"><ImFilesEmpty /></span>
                        <p className="text-2xl text-gray-600">{t("Nessun prodotto trovato")}</p>
                    </div>
            }
        </Section>
    );
};
