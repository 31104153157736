import { MarginCalculatorContext } from "../../contexts/MarginCalculatorContext";
import { useContext } from "react";

export const useMarginCalculatorContext = () => {
    const context = useContext(MarginCalculatorContext);

    if(!context) {
        throw Error("useMarginCalculatorContext must be inside margin context provider");
    }

    return context;
};