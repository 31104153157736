import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../client";

type ExtraServiceParams = {
    cart_id: number,
    code: string,
    isOffer?: boolean
};


export default function useExtraServicesMutations() {
    const queryClient = useQueryClient();

    // ADD EXTRA SERVICE
    const addExtraService = useMutation(async (params: ExtraServiceParams) => {
        if (params.isOffer) {
            return await client.post(`offers/${params.cart_id}/extra-services`, params);
        } else {
            return await client.post(`carts/${params.cart_id}/extra-services`, params);
        }
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["cart"]);
            queryClient.invalidateQueries(["offer"]);
        }
    });
    
    // DELETE EXTRA SERVICE
    const deleteExtraService = useMutation(async (params: ExtraServiceParams) => {
        if (params.isOffer) {
            return await client.delete(`/offers/${params.cart_id}/extra-services`, { params });
        } else {
            return await client.delete(`/carts/${params.cart_id}/extra-services`, { params });
        }
        
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["cart"]);
            queryClient.invalidateQueries(["offer"]);
        }
    });

    return { addExtraService, deleteExtraService };
}