import { useMutation } from "@tanstack/react-query";
import client from "../client";

export default function useProfileMutations() {
    // SEND UPDATE PROFILE REQUEST
    const updateProfile = useMutation(async (params: any) => {
        return await client.post("customers/profile", params);
    });

    return { updateProfile };
}