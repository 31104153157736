import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useShippingContext } from "../../../../hooks/use-context/useShippingContext";
import { Button } from "../../../Button";
import Select from "react-select";
import { useSelectStyles } from "../../../../hooks/useSelectStyles";
import { useAuthContext } from "../../../../hooks/use-context/useAuthContext";

export const TRANSPORTS = [
    {
        label: "TRANSPORT",
        value: "TRANSPORT"
    },
    {
        label: "AIRTRANSPORT-DHL",
        value: "AIRTRANSPORT"
    },
    {
        label: "AIRTRANSPORT-TNT",
        value: "AIRTRANSPORT"
    }
];

export const AddShippingManualFreightCost = () => {
    const { t } = useTranslation();
    const { roles } = useAuthContext();
    const { CustomStyles } = useSelectStyles();

    // state
    const [isOpen, setIsOpen] = useState(false);

    // hooks
    const { free_port, shipping_method, manual_freight_cost, manual_shipping_method, setManualShippingMethod, setManualFreightCost, setAdditionalFreightCost } = useShippingContext();

    const handleTransportChange = (inputValue: any) => {
        if (inputValue) {
            setManualShippingMethod(inputValue.value);
            setAdditionalFreightCost(null);
        } else {
            setManualShippingMethod("");
            setManualFreightCost("");
        }
    };

    useEffect(() => {
        if (manual_freight_cost?.length > 0 || free_port) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, []);

    return (
        <div>
            {
                !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) &&
                <>
                    <div className={`text-sm ${isOpen ? "border-b" : ""}`}>
                        <Button variant={Button.variant.text} type="button" onClick={() => setIsOpen(isOpen ? false : true)}>
                            {isOpen ? <BiChevronUp className="text-xl" /> : <BiChevronDown className="text-xl" />}
                            <span>{t("Aggiungi costo manuale")}</span>
                        </Button>
                    </div>

                    {
                        isOpen &&
                        <div className="py-2 w-1/2 flex gap-x-2">
                            <div className="flex-1">
                                <label htmlFor="">{t("Tipo di trasporto")}</label>
                                <Select
                                    options={roles(["BA", "CS_Other", "CS", "Assistant"]) ? TRANSPORTS : [TRANSPORTS[0]]}
                                    name="manual_shipping_method"
                                    value={manual_shipping_method ? { label: manual_shipping_method, value: manual_shipping_method } : null}
                                    styles={CustomStyles}
                                    placeholder={t("seleziona il trasporto")}
                                    onChange={handleTransportChange}
                                    isClearable
                                />
                            </div>
                            <div className="flex-1">
                                <label htmlFor="">{t("Importo manuale")}</label>
                                <input
                                    className={`${!manual_shipping_method && "cursor-not-allowed"}`}
                                    type="number"
                                    value={manual_freight_cost}
                                    onChange={(e) => setManualFreightCost(e.target.value)}
                                    min={roles(["BA", "CS_Other", "CS", "Assistant"]) ? 0 : 1}
                                    step={"0.01"}
                                    disabled={!manual_shipping_method}
                                    required={shipping_method?.name === "manual-freight-cost" || manual_shipping_method}
                                />
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    );
};
