import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "../components/Loader";
import Pagination from "../components/Pagination";
import { ImFilesEmpty } from "react-icons/im";
import useMarketingPriceList from "../api/reserved-conditions/marketing-price-list/useMarketingPriceList";
import MarketingPriceListSearchBar, { marketingSearchParams } from "../components/reserved-conditions/marketing-price-list/MarketingPriceListSearchBar";
import { MarketingPriceListTable } from "../components/reserved-conditions/marketing-price-list/MarketingPriceListTable";
import { useSearchParams } from "react-router-dom";
import { Segmented } from "../components/Segmented";
import { BsGrid, BsViewList } from "react-icons/bs";
import { Tile } from "../components/Tile";
import useMarginCalculationMethods from "../hooks/useMarginCalculationMethods";
import { useAuthContext } from "../hooks/use-context/useAuthContext";

const marketingPriceList = ({ setTitle }: any) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { semiramisRound } = useMarginCalculationMethods();
    const { user } = useAuthContext();


    // state
    const [currentPage, setCurrentPage] = useState(1);
    const [params, setParams] = useState<marketingSearchParams>({});
    const [viewMode, setViewMode] = useState<string>("table");
    const [canFetch, setCanFetch] = useState<boolean>(false);

    useEffect(() => {
        setTitle(t("Listino marketing"));
    }, [])

    // reset page when params change
    useEffect(() => {
        setCurrentPage(1);

        const tmpParams: any = {};

        searchParams.forEach((value: any, key: any) => {
            tmpParams[key] = value;
        });

        setParams(tmpParams);
    }, [searchParams]);

    // react query
    const {
        marketingPriceList,
        isFetching,
        isLoading
    } = useMarketingPriceList({
        page: currentPage,
        ...params,
    }, canFetch);

    return (
        <>

            {(isFetching || isLoading) && canFetch && <Loader />}

            <div className="my-10">
                <MarketingPriceListSearchBar
                    setSearchParams={setSearchParams}
                    setCanFetch={setCanFetch}
                />
            </div>

            {marketingPriceList?.data?.length > 0 ? (
                <>
                    <div className="flex justify-end my-6">
                        <Segmented
                            variant="bar"
                            buttons={[
                                { icon: <BsViewList />, value: "table" },
                                { icon: <BsGrid />, value: "card" },
                            ]}
                            label={t("Visualizzazione")}
                            selected={viewMode}
                            setSelected={setViewMode}
                        />
                    </div>
                    {viewMode === "table" && <MarketingPriceListTable
                        items={marketingPriceList.data}
                        setParams={setParams}
                    />}
                    {viewMode === "card" && <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-10">
                        {marketingPriceList.data.map((el: any) => {
                            const numberFormat = new Intl.NumberFormat(user?.interface_language_id || "en", { style: 'currency', currency: el?.currency, minimumFractionDigits: 5, maximumFractionDigits: 5 });
                            const price = semiramisRound(parseFloat(el?.pricevalue.toString()), 5);
                            const formattedPrice = numberFormat.format((price));

                            return <Tile
                                key={el?.organization + "-" + el.code}
                                itemCode={el.code}
                                description={el?.description}
                                brand={el?.brand}
                                language={el?.language}
                                availability={el?.quantity}
                                minSellable={el?.minsellable}
                                netPrice={formattedPrice}
                                organization={el?.organization}
                                pieces={`(${parseInt(el?.piecesperpackage)} ${el?.piecesperpackageuom})`}
                                priceclassification={el?.priceclassification_description}
                            />
                        })}
                    </div>}

                    <Pagination
                        currentPage={currentPage}
                        totalCount={marketingPriceList?.meta?.total}
                        pageSize={10}
                        onPageChange={setCurrentPage}
                    />
                </>
            ) : (
                <div className="h-96 flex flex-col items-center justify-center space-y-6">
                    <span className="text-9xl text-gray-200"><ImFilesEmpty /></span>
                    {canFetch ? (
                        <p className="text-2xl text-gray-600">{t("Nessun prodotto trovato")}</p>
                    ) : (
                        <p className="text-2xl text-gray-600">{t("Imposta un filtro per effettuare una ricerca.")}</p>
                    )}
                </div>
            )}
        </>
    );
};

export default marketingPriceList;
