/*
La rotta maintenance è diversa dalla pagina manutenzione.hmtl
Manutenzione.html viene chiamata da NGINX se il sito non risponde
/maintenance viene chiamata quando un servizio rispodne qualcosa di diverso da 2XX e 404
 */

const Maintenance = () => {
    return (
        <div className="py-10 2xl:px-28 text-center text-xl">
            <p className="py-4">
                <b className="text-2xl">E-SERVICE è attualmente in manutenzione.</b><br/> Prevediamo di tornare operativi tra un paio di minuti. Grazie per la tua pazienza.
            </p>
            <p className="py-4">
                <b className="text-2xl">E-SERVICE ist derzeit wegen Wartungsarbeiten nicht verfügbar.</b><br/> Wir erwarten, dass wir in ein paar Minuten zurück sein werden. Vielen Dank für Ihre Geduld.
            </p>
            <p className="py-4">
                <b className="text-2xl">E-SERVICE is currently down for maintenance.</b><br/> We expect to be back in a couple of minutes. Thank for your patience.
            </p>
            <p className="py-4">
                <b className="text-2xl">E-SERVICE está actualmente en mantenimiento.</b><br/> Esperamos volver en un par de minutos. Gracias por su paciencia.
            </p>
            <p className="py-4">
                <b className="text-2xl">E-SERVICE est actuellement en maintenance.</b><br/> Nous prévoyons de revenir dans quelques minutes. Merci pour votre patience.
            </p>
            <p className="py-4">
                <b className="text-2xl">E-SERVICE está atualmente em manutenção.</b><br/> Esperamos estar de volta em alguns minutos. Obrigado pela sua paciência.
            </p>
            <p className="py-4">
                <b className="text-2xl">E-SERVICE в настоящее время недоступен из-за технического обслуживания.</b><br/> Мы ожидаем вернуться через несколько минут. Благодарим за ваше терпение.
            </p>
        </div>
    );
};

export default Maintenance;
