import { useEffect, useState } from "react";
import { useAuthContext } from "./use-context/useAuthContext";

/**
 * 
 * @param items 
 * @returns two lists, the available and unavailable products separated
 */

export const useSplitAvailability = (items: any) => {
    const { user } = useAuthContext();

    const [availableItems, setAvailableItems] = useState<any>([]);
    const [unavailableItems, setUnavailableItems] = useState<any>([]);

    // SET AVAILABILITY STATE
    useEffect(() => {
        // clear items to avoid uplication
        setAvailableItems([]);
        setUnavailableItems([]);

        const filteredItems = items.filter((item: any) => {
            return !user?.constants?.extra_services?.includes(item?.code) && !user?.constants?.is_freight_cost?.includes(item?.code);
        });

        filteredItems && filteredItems.forEach((item: any) => {
            const availableQty = item?.availabilityInfo?.availableQuantity;

            if (availableQty >= item.qty) {
                setAvailableItems((prevItems: any) => [...prevItems, item]);
            } else if (availableQty > 0 && item.qty > availableQty) {
                const availableQtyItem = { ...item };
                const unavailableQtyItem = { ...item };

                availableQtyItem.qty = parseInt(availableQty);
                unavailableQtyItem.qty = item.qty - availableQty;
                unavailableQtyItem.rowQty = item.qty;

                setAvailableItems((prevItems: any) => [...prevItems, availableQtyItem]);
                setUnavailableItems((prevItems: any) => [...prevItems, unavailableQtyItem]);
            } else {
                const unavailableQtyItem = { ...item };
                unavailableQtyItem.rowQty = item.qty;
                setUnavailableItems((prevItems: any) => [...prevItems, unavailableQtyItem]);
            }
        });
    }, [items]);

    return { availableItems, unavailableItems };
};
