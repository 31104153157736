let buildVersion: any;

export const checkBuildVersion = (setModalVersionStatus: any): any => {
    fetch("/manifest.json")
        .then((response) => response.json())
        .then((data) => buildVersion = data?.build_version);

    const shouldCheckVersion = ["development", "production"].indexOf(process.env.REACT_APP_ENV || "") >= 0;
    if (shouldCheckVersion) {
        document.addEventListener("visibilitychange", async () => {
            if (document.visibilityState === "visible") {
                fetch("/manifest.json")
                    .then((response) => response.json())
                    .then((data) => {
                        const tempBuildVersion: any = data?.build_version;
                        if (buildVersion !== tempBuildVersion) {
                            setModalVersionStatus(true);
                        }
                    });
            }
        });
    }
};
