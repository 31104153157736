import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../Button";

type CartSearchBarProps = {
    setSearchParams?: any
};

type searchParams = {
    "filter[name]"?: string,
    "filter[street]"?: string,
    "filter[city]"?: string,
    "filter[country]"?: string,
    "filter[region]"?: string,
    "filter[postalcode]"?: string,
    "sort"?: string
};

export default function DeliveryAddressSearchBar({ setSearchParams }: CartSearchBarProps) {
    const { t } = useTranslation();
    const [formData, setFormData] = useState<searchParams>({});

    const handleInputsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prevFormData => { return { ...prevFormData, [e.target.name]: e.target.value }; });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        setSearchParams(formData);
    };

    const resetForm = () => {
        setFormData({});
        setSearchParams({});
    };

    // useEffect(() => {
    //     console.log(formData);
    // }, [formData]);

    return (
        <Fragment>
            <div className="mb-4">
                <fieldset className="grid grid-cols-3 gap-x-6 gap-y-4 xl:grid-cols-6 items-end  mb-0">
                    {/* <fieldset className="grid grid-cols-5 gap-x-6"> */}
                    <legend className="sr-only"><span>{t("Ricerca")}</span></legend>
                    {/* <div className="w-5/6 grid grid-cols-5 gap-x-6"> */}
                    <div className="grid-row-start">
                        <label>{t("Nome cliente")}</label>
                        <input
                            value={formData["filter[name]"] ?? ""}
                            type="text"
                            name="filter[name]"
                            onChange={e => handleInputsChange(e)}
                            placeholder={t("inserisci nome cliente")}
                        />
                    </div>
                    <div>
                        <label>{t("Via")}</label>
                        <input
                            value={formData["filter[street]"] ?? ""}
                            type="text"
                            name="filter[street]"
                            onChange={e => handleInputsChange(e)}
                            placeholder={t("inserisci la via")}
                        />
                    </div>
                    <div>
                        <label>{t("Città")}</label>
                        <input
                            value={formData["filter[city]"] ?? ""}
                            type="text"
                            name="filter[city]"
                            onChange={e => handleInputsChange(e)}
                            placeholder={t("inserisci la città")}
                        />
                    </div>
                    <div>
                        <label>{t("Paese")}</label>
                        <input
                            value={formData["filter[country]"] ?? ""}
                            type="text"
                            name="filter[country]"
                            onChange={e => handleInputsChange(e)}
                            placeholder={t("inserisci il paese")}
                        />
                    </div>
                    <div>
                        <label>{t("CAP")}</label>
                        <input
                            value={formData["filter[postalcode]"] ?? ""}
                            type="text"
                            onKeyPress={(e) => { return e.key === "Enter" ? handleSubmit(e) : null; }}
                            name="filter[postalcode]"
                            onChange={e => handleInputsChange(e)}
                            placeholder={t("inserisci il CAP")}
                        />
                    </div>
                    {/* </div> */}
                    <div className="self-end">
                        <Button variant={Button.variant.primary} size={Button.size.full} onClick={(e) => handleSubmit(e)}>{t("Cerca")}</Button>
                    </div>
                </fieldset>

                <Button
                    variant={Button.variant.text}
                    className="text-xs py-0"
                    icon={Button.icon.reset}
                    onClick={resetForm}
                    type="button"
                >
                    {t("reset ricerca")}
                </Button>
            </div>
        </Fragment>
    );
}
