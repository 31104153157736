import { useTranslation } from "react-i18next";
import { Title } from "../../Title";

type ShippingInfoSummaryProps = {
    data: any,
    numberFormat: Intl.NumberFormat | null,
    summary: any
};

export default function ShippingInfoSummary({ data, numberFormat, summary }: ShippingInfoSummaryProps) {
    const { t } = useTranslation();

    return (
        <div className="grid grid-cols-1 md:grid-cols-3 border mb-8 font-light text-sm">
            {/* ADDRESS */}
            <div className="p-6">
                <Title variant={Title.variant.secondary} className="mb-4">{t("Indirizzo di consegna")}</Title>
                <div className="uppercase">
                    <div>{data.data?.shipping_info?.address?.name}</div>
                    <div className="flex gap-x-1">
                        {data.data.shipping_info?.address?.street} {data.data.shipping_info?.address?.number}{data.data.shipping_info?.address?.number && ","} {data.data.shipping_info?.address?.city} {data.data.shipping_info?.address?.postalcode}
                    </div>
                    <div>{`${data.data.shipping_info?.address?.region_id} ${data.data.shipping_info?.address?.country_id}`}</div>
                </div>
            </div>

            {/* PAYMENT METHOD */}
            <div className="p-6 border-y md:border-y-0 border-x-0 md:border-x">
                <Title variant={Title.variant.secondary} className="mb-4">{t("Metodo di pagamento")}</Title>
                <div>
                    <div>{data?.data?.payment_method?.description ?? data?.data?.customer?.finance?.paymentmethod?.description}</div>
                    <div>{data?.data?.payment_term?.description}</div>
                </div>
            </div>

            {/* SHIPPING METHOD */}
            <div className="p-6">
                <Title variant={Title.variant.secondary} className="mb-4">{t("Metodo di spedizione")}</Title>
                <div>
                    <>
                        {/* MANUAL FREIGHT COST SET */}
                        {
                            data?.data?.shipping_info?.manual_freight_cost &&
                            <div className="first-letter:uppercase space-x-2">
                                <span>{data?.data?.shipping_info?.manual_shipping_method}:</span>
                                <span className="font-normal">{numberFormat?.format(data?.data?.shipping_info?.manual_freight_cost)}</span>
                            </div>

                        }

                        {/* AUTOMATIC FREIGHT COST SET */}
                        {
                            data.data.shipping_info?.shipping_methods_price &&
                            <div>
                                {
                                    data?.data.shipping_info?.shipping_methods_price?.filter((shippping_method: any) => { return shippping_method.name === data.data.shipping_info.shipping_method; }).map((shipping_method: any, index: number) => (
                                        <div key={index} className="first-letter:uppercase space-x-2">
                                            <span>{shipping_method?.name}:</span>
                                            <span className="font-normal">{numberFormat?.format(summary?.shipping_method_without_iva)}</span>
                                        </div>
                                    ))

                                }
                            </div>
                        }

                        {/* SALES ORDER FREIGHT COST SET */}
                        {
                            !data?.data?.shipping_info?.manual_freight_cost && !data.data.shipping_info?.shipping_methods_price && summary?.shipping_method_without_iva > 0 &&
                            <div className="first-letter:uppercase space-x-2">
                                <span>{data?.data?.shipping_info?.shipping_method}:</span>
                                <span className="font-normal">{numberFormat?.format(summary?.shipping_method_without_iva)}</span>
                            </div>
                        }

                        {/* NO FREIGHT COST */}
                        {
                            (!data?.data?.shipping_info?.manual_freight_cost && !data.data.shipping_info?.shipping_method && !summary?.shipping_method_without_iva && summary?.shipping_method_without_iva !== 0) &&
                            <div>{t("Non è stato possibile calcolare i costi di spedizione")}</div>
                        }

                        <div className="flex gap-x-2">
                            {/* 1 partial delivery, 2 complete delivery */}
                            <span className="font-light">{data?.data?.deliveryRestriction === 1 ? <span>{t("Consegna")}: {t("parziale")}</span> : data?.data?.deliveryRestriction === 2 ? <span>{t("Consegna")}: {t("completa")}</span> : ""}</span>
                        </div>

                        {/* TOTAL WEIGHT */}
                        {
                            data?.data?.total_weight &&
                            <>
                                {
                                    !data?.data?.salesOrder &&
                                    <div>
                                        <span>{t("Peso totale")}: </span>
                                        <span className="font-medium">{data?.data?.total_weight} kg</span>
                                    </div>
                                }
                            </>
                        }
                    </>
                </div>
            </div>
        </div>
    );
}
