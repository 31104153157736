import classNames from "classnames";
import { Title } from "./Title";
import { useTranslation } from "react-i18next";

type Props = {
    title: string;
    children: React.ReactNode;
    className?: string;
};

export function Section(props: Props) { 
    const { title, className, children } = props;
    
    const { t } = useTranslation();
    const classes = classNames("flex flex-col gap-y-6 mt-20", className);
    
    return (
        <div className={classes}>
            <Title variant={Title.variant.tertiary}>{t(title)}</Title>
            {children}
        </div>
    );
}