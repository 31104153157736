import { useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import Stepper from "../components/cart/Stepper";
import { OfferProducts } from "../components/offers/OfferProducts";
import OfferShipping from "../components/offers/OfferShipping";
import OfferDetails from "../components/offers/OfferDetails";
import { Title } from "../components/Title";

const OfferPage = () => {
    const params = useParams();

    // const shippingRegex = /^\d*\/shipping$/;
    // const summaryRegex = /^\d*\/summary$/;

    const [title, setTitle] = useState<string | null>("");
    const [isStepperDisabled, setIsStepperDisabled] = useState<boolean>(false);

    return (
        <div className="py-10 px-8 2xl:px-28">
            {/* {params['*'] ? shippingRegex.test(params['*']) || summaryRegex.test(params['*']) ? <Title>{t('Nuovo ordine o offerta')}</Title> : <Title>{t('Nuovo ordine o offerta')}</Title> : null} */}

            <Title variant={Title.variant.primary}>{title}</Title>

            <Stepper activePath={params["*"]} disabled={isStepperDisabled} />

            <Routes>
                <Route path='/:id' element={<OfferProducts setTitle={setTitle}/>}/>
                <Route path=":id/shipping" element={<OfferShipping/>}/>
                <Route path=":id/summary" element={<OfferDetails isSummary={true} setTitle={setTitle} setIsStepperDisabled={setIsStepperDisabled} />}/>
            </Routes>
        </div>
    );
};

export default OfferPage;
