import { ReactNode } from "react";
import classnames from "classnames";
import { AiOutlineWarning } from "react-icons/ai";
import { MdBlock, MdDone, MdInfoOutline } from "react-icons/md";
import { BiLoaderAlt } from "react-icons/bi";

enum Variant {
    success,
    error,
    warning,
    loading,
    info,
}

type Props = {
    variant: Variant;
    children?: ReactNode;
    open: boolean;
    handleCloseMessage?: () => void;
};

export function Message(props: Props) {
    const { children, variant, open, handleCloseMessage } = props;

    let iconBtn = undefined;
    switch (variant) {
        case Variant.success:
            iconBtn = <MdDone />;
            break;
        case Variant.error:
            iconBtn = <MdBlock />;
            break;
        case Variant.warning:
            iconBtn = <AiOutlineWarning />;
            break;
        case Variant.loading:
            iconBtn = <span className="animate-spin"> <BiLoaderAlt /></span>;
            break;
        case Variant.info:
            iconBtn = <MdInfoOutline />;
            break;
    }

    if (open) {
        return (
            <div role="alert" className={classnames("flex mb-8 rounded overflow-hidden border border-gray-200 shadow-md bg-white")}>
                <div className={classnames("p-4 flex justify-center items-center text-white text-xl", {
                    "bg-green-700": variant === Variant.success,
                    "bg-red-600": variant === Variant.error,
                    "bg-yellow-500": variant === Variant.warning,
                    "bg-gray-500": variant === Variant.loading,
                    "bg-blue-600": variant === Variant.info,
                })}>{iconBtn}</div>

                <div className="flex flex-1 justify-between">
                    <div className="relative flex-1 p-4 text-sm text-primary">
                        {children}
                        {handleCloseMessage && <button className="absolute top-3 right-4 closeBtn text-xl hover:opacity-70" onClick={handleCloseMessage} aria-label="Nascondi messaggio">&times;</button>}
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

Message.defaultProps = {
    variant: Variant
};

Message.variant = Variant;
