import { Menu, MenuButton, MenuItem, MenuList } from "@reach/menu-button";
import { Tooltip } from "@reach/tooltip";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ToastContainer, Slide, toast } from "react-toastify";
import { Button } from "../Button";
import { Modal } from "../commom/Modal";
import { HiOutlineDotsVertical } from "react-icons/hi";

export const CartsTableActions = (props: any) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    // state
    const [open, setOpen] = useState(false);

    const handleDeleteCart = async () => {
        await toast.promise(props.deleteCart.mutateAsync({ cart_id: props.row.original.id }), {
            pending: t("Carico..."),
            success: t("Carrello eliminato con successo"),
            error: {
                render({ data }: any) {
                    if (data?.response?.status === 403) {
                        return t(data?.response?.data?.message);
                    } else {
                        return t("Error, something went wrong");
                    }
                }
            }
        });

        setOpen(false);
    };

    const handleModifyCart = () => {
        navigate(`/carts/${props.row.original.id}`);
    };

    return (
        <>
            <Modal title={t("Elimina carrello")} open={open} onClose={setOpen}>
                <p>{t("Sicuro di voler eliminare il carrello? Questa operazione non può essere annullata")}.</p>
                <div className="text-right space-x-2 mt-4">
                    <Button variant={Button.variant.text} onClick={() => setOpen(false)}>{t("Annulla")}</Button>
                    <Button variant={Button.variant.primary} onClick={handleDeleteCart}>{t("Procedi")}</Button>
                </div>
            </Modal>

            <Menu>
                <MenuButton>
                    <Tooltip
                        label={"actions"}
                        style={{
                            backgroundColor: "#F1F5F6",
                            borderRadius: "3px",
                            fontWeight: "300",
                            marginRight: "20px",
                            zIndex: 50
                        }}>
                        <span>
                            <HiOutlineDotsVertical className='text-lg cursor-pointer' />
                        </span>
                    </Tooltip>
                </MenuButton>


                <MenuList className="offers-dropdown">
                    <MenuItem className="offers-dropdown-items offers-dropdown-items-selected" onSelect={handleModifyCart}>{t("Modifica")}</MenuItem>
                    <MenuItem className="offers-dropdown-items offers-dropdown-items-selected" onSelect={() => setOpen(true)}>{t("Elimina")}</MenuItem>
                </MenuList>
            </Menu>
        </>
    );
};
