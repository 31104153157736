import { useTranslation } from "react-i18next";
import { useDiscountTableContext } from "../../hooks/use-context/useDiscountTableContext";
import { useFormContext } from "react-hook-form";
import classNames from "classnames";
import { InfoTooltip } from "../commom/InfoTooltip";
import { MdClose } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import { useEffect } from "react";

export const DiscountCurrent = ({ current }: any) => {
    const { t } = useTranslation();

    const { formatDate, editable, newDate, getDate } = useDiscountTableContext();

    const { register, unregister, watch, formState, getValues, setValue, trigger } = useFormContext();

    const itemCode = current?.itemkey;
    const discount1 = current.pricevalue;
    const discount2 = current.pricevalue2;
    const from = getDate(current?.validfrom);
    const until = getDate(current?.validuntil);

    const futureFromDate = watch(`data.${itemCode}.future.date.from`);

    const errors: any = formState.errors;
    const error: any = errors?.data?.[itemCode]?.current?.date?.until;

    const isValueLocked = !!watch(`data.${itemCode}.current.date.until`);

    if (!!from && editable) {
        register(`data.${itemCode}.current.date.exist`, {
            value: from.substring(0, 10),
        });
    }

    function generateTodayOrPrevious() {
        const today = newDate();
        const validFrom = newDate(from);

        if (validFrom >= today) {
            return validFrom.toISOString().substring(0, 10);
        } else {
            return today.toISOString().substring(0, 10);
        }
    }

    function handleCloseDiscount() {
        if (isValueLocked) {
            setValue(`data.${itemCode}.current.date.until`, "", { shouldValidate: true });
            setValue(`data.${itemCode}.future.discount.t200`, null, { shouldValidate: true });
            setValue(`data.${itemCode}.future.discount.t290`, null, { shouldValidate: true });
            setValue(`data.${itemCode}.future.date.from`, "", { shouldValidate: true });
        } else {
            setValue(`data.${itemCode}.current.date.until`, generateTodayOrPrevious(), { shouldValidate: true });
            setValue(`data.${itemCode}.future.discount.t200`, null, { shouldValidate: true });
            setValue(`data.${itemCode}.future.discount.t290`, null, { shouldValidate: true });
            setValue(`data.${itemCode}.future.date.from`, "", { shouldValidate: true });
        }
        trigger();
    }

    // clean field if future date does not exist (fix for close table function)
    useEffect(() => {
        if (until && !futureFromDate) {
            unregister(`data.${itemCode}.current.date.until`);
        }
    }, [watch(`data.${itemCode}.future.date.from`)]);

    return (
        <div className="grid grid-cols-3 gap-4 py-4 overflow-hidden col-span-3 pl-2 pr-4">
            {/* percent */}
            <div className="flex flex-col gap-1.5">
                {!!discount1 && <div className="text-sm leading-6 text-gray-800">{t("Sconto attivo")}</div>}

                {/* SCONTO 1 */}
                {!!discount1 && <div className="leading-8 flex gap-2 items-center">
                    <div className="bg-table-tertiary rounded-full w-6 h-6 aspect-square text-center text-xs leading-6">1</div>
                    <div>{parseFloat(discount1 ?? 0.00).toFixed(2)}%</div>
                </div>}

                {/* SCONTO 2 */}
                {!!discount1 && !!discount2 && <div className="leading-8 flex gap-2 items-center">
                    <div className="bg-table-tertiary rounded-full w-6 h-6 aspect-square text-center text-xs leading-6">2</div>
                    <div>{parseFloat(discount2 ?? 0.00).toFixed(2)}%</div>
                </div>}
            </div>



            {/* FROM */}
            <div className="flex flex-col gap-1.5">
                {!!from && <div className="text-sm leading-6 text-gray-800">{t("Valido dal")}</div>}
                {!!from && <div className="leading-8">{formatDate(from)}</div>}
            </div>



            {/* UNTIL */}
            <div className="flex flex-col gap-1.5">
                {(!!until || editable) && <div className="text-sm leading-6 text-gray-800">{t("Fino al")}</div>}
                {(!!until && !!futureFromDate) || (!until && editable && from) ? (
                    <div className="relative group">
                        <input
                            {...register(`data.${itemCode}.current.date.until`, {
                                required: {
                                    value: !!getValues(`data.${itemCode}.future.date.from`),
                                    message: t("Questo campo è obbligatorio"),
                                },
                                validate: async (date) => {
                                    if (from) {
                                        const inputDate = newDate(date);
                                        const previousFrom = getDate(from);

                                        if (!!date && previousFrom) {
                                            return (inputDate >= newDate(previousFrom)) || !date || t("la data deve essere maggiore o uguale alla data precedente") as string;
                                        }
                                    }
                                    return true;
                                },
                                value: ""
                            })}
                            disabled={true}
                            type="date"
                            id={`data.${itemCode}.current.date.until`}
                            className={classNames("text-sm leading-6 p-0.5 h-8", {
                                "border-rose-500 focus:outline-[0.5px] focus:outline-rose-500 focus:rounded border": !!error
                            })
                            }
                        />
                        {!until && editable && from && <button type="button" className={classNames("absolute top-1/2 -translate-y-1/2 p-1", {
                            "text-lg right-0": isValueLocked,
                            "text-xs right-0.5": !isValueLocked,
                        })} onClick={handleCloseDiscount}>
                            {isValueLocked ? (
                                <MdClose />
                            ) : (
                                <FaLock />
                            )}
                        </button>}
                        {error?.message && <div className="absolute right-0.5 top-1/2 -translate-y-1/2 bg-white p-1">
                            <InfoTooltip color="#f43f5e">{error?.message}</InfoTooltip>
                        </div>}
                    </div>
                ) : (
                    !!until && <div className="leading-8">{formatDate(until)}</div>
                )}
            </div>
        </div>
    );
};
