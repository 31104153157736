import { useTranslation } from "react-i18next";
import { Modal } from "./Modal";
import { useState } from "react";
import { Button } from "../Button";
import useCartsMutations from "../../api/carts/useCartsMutations";
import { useShippingContext } from "../../hooks/use-context/useShippingContext";
import useOffersMutations from "../../api/offers/useOffersMutations";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { CustomerSelect } from "./CustomerSelect";

type Props = {
    data: any
    loadCustomersOptions: any
    readonly: boolean
};

export const ChangeCustomer = ({ data, loadCustomersOptions, readonly }: Props) => {
    const { t } = useTranslation();


    const { user } = useAuthContext();

    const { resetShippingContext } = useShippingContext();

    // state
    const [openChangeCustomer, setOpenChangeCustomer] = useState<any>(false);
    const [selectedCustomer, setSelectedCustomer] = useState<any>(null);

    const { updateCart } = useCartsMutations();
    const { updateOffer } = useOffersMutations();

    // handle customer SELECT change
    const handleCustomerSelectChange = async (inputValue: any) => {
        setSelectedCustomer(inputValue);
    };

    const handleCustomerChange = async () => {
        resetShippingContext();

        try {
            if (!data?.data?.offer_state) {
                const params = {
                    cart_id: data?.data?.id,
                    body: {
                        customer_code: selectedCustomer?.code ?? null,
                    }
                };
                await updateCart.mutateAsync(params);
            } else {
                const params = {
                    cart_id: data?.data?.id,
                    body: {
                        customer_code: selectedCustomer?.code ?? null,
                    }
                };
                await updateOffer.mutateAsync(params);
            }
        } catch (err: any) {
            console.log(err);
        }

        setOpenChangeCustomer(false);
        setSelectedCustomer(null);
    };

    return (
        <>
            {/* SELECTED CUSTOMER */}
            <div>
                <div className="flex items-center gap-x-2 font-light">
                    <div>{data?.data?.customer?.code} - {data?.data?.customer?.name} - {data?.data?.customer?.city}, {data?.data?.customer?.region_id} - {data?.data?.customer?.postalcode} - {data?.data?.customer?.street} - {data?.data?.customer?.country_id}</div>
                    {
                        !readonly && (user?.customers && user?.customers?.length > 1 || !user?.customers) &&
                        <div
                            className="text-xs cursor-pointer underline text-secondary"
                            onClick={() => setOpenChangeCustomer(true)}
                        >
                            {t("modifica")}
                        </div>
                    }
                </div>
            </div>

            {/* CHANGE CUSTOMER MODAL */}
            <Modal title={t("Modifica cliente")} open={openChangeCustomer} onClose={setOpenChangeCustomer}>
                <div className="space-y-2">
                    <CustomerSelect
                        label="Seleziona cliente"
                        loadCustomersOptions={loadCustomersOptions}
                        handleChange={handleCustomerSelectChange}
                        selectedCustomer={selectedCustomer}
                        setSelectedCustomer={setSelectedCustomer}
                    />

                    {(data?.data?.customer?.invoice_pricelist_id !== selectedCustomer?.invoice_pricelist_id) && selectedCustomer && <p className="text-rose-500">{t("Clienti con listini diversi, non è possibile mantenere i prezzi")}.</p>}

                    <div className="text-right space-x-2 mt-4">
                        <Button variant={Button.variant.text} onClick={() => setOpenChangeCustomer(false)}>{t("Annulla")}</Button>
                        <Button variant={Button.variant.primary} onClick={handleCustomerChange} isDisabled={!selectedCustomer}>{t("Procedi")}</Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
