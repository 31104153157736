import { DiscountTableContext } from "../../contexts/DiscountTableContext";
import { useContext } from "react";

export const useDiscountTableContext = () => {
    const context = useContext(DiscountTableContext);

    if (!context) {
        throw Error("useDiscountTableContext must be inside discount table context provider");
    }

    return context;
};