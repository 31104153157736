import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useShippingContext } from "../../../../hooks/use-context/useShippingContext";
import { getCurrencyStep } from "../../../../utils/getCurrencyDecimals";
import { Button } from "../../../Button";

export const AddShippingAdditionalCost = ({ numberFormat, currency }: any) => {
    const { t } = useTranslation();

    // state
    const [isOpen, setIsOpen] = useState(false);

    // hooks
    const { shipping_method, additional_freight_cost, setAdditionalFreightCost } = useShippingContext();

    useEffect(() => {
        if (additional_freight_cost && parseFloat(additional_freight_cost) > 0) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [additional_freight_cost]);

    return (
        <div className="space-y-2">
            <div className={`text-sm ${isOpen ? "border-b" : ""}`}>
                <Button variant={Button.variant.text} type="button" onClick={() => setIsOpen(isOpen ? false : true)}>
                    {isOpen ? <BiChevronUp className="text-xl" /> : <BiChevronDown className="text-xl" />}
                    <span>{t("Aggiungi costo addizionale")}</span>
                </Button>
            </div>

            {
                isOpen &&
                <div>
                    <label htmlFor="">{t("Importo addizionale")}</label>
                    <input
                        type="number"
                        className="w-1/4"
                        value={additional_freight_cost ?? ""}
                        onChange={(e) => setAdditionalFreightCost(e.target.value)}
                        min="1"
                        step={getCurrencyStep(currency)}
                    />

                    <div className="text-sm mt-4">
                        {t("Totale")}: {numberFormat.format(shipping_method?.freightCost + (additional_freight_cost && additional_freight_cost !== "" ? parseFloat(additional_freight_cost) : 0))}
                    </div>
                </div>
            }
        </div>
    );
};
