import { useMutation } from "@tanstack/react-query";
import client from "../client";

type DiscountParam = {
    "filter[search]": string,
};

export default function useDiscountsCustomerMutations() {
    // GET CUSTOMER
    const getCustomer = useMutation(async (params: DiscountParam) => {
        return await client.get("discount-table/customers", { params: params });
    });

    return { getCustomer };
}