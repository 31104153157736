import { Title } from "../components/Title";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import ComplaintsSearchBar, { ComplaintsSearchParams } from "../components/assistance/complaints/ComplaintsSearchBar";
import SubRowAsync from "../components/carts/SubRowAsync";
import { Loader } from "../components/Loader";
import Pagination from "../components/Pagination";
import { ComplaintsTable } from "../components/assistance/complaints/ComplaintsTable";
import { ImFilesEmpty } from "react-icons/im";
import useSemiramisComplaints from "../api/semiramis-complaints/useSemiramisComplaints";
import { Section } from "../components/Section";

export default function ComplaintList() {
    const { t } = useTranslation();

    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const [params, setParams] = useState<ComplaintsSearchParams>({});

    // react query
    const { semiramisComplaints, isLoading, isFetching } = useSemiramisComplaints({ page: currentPage, ...params });
    // const { data: complaint, isLoading, isFetching } = useComplaintTypes();

    // reset page when params change
    useEffect(() => {
        setCurrentPage(1);
    }, [searchParams]);

    useEffect(() => {
        const tmpParams: any = {};

        searchParams.forEach((value: any, key: any) => {
            tmpParams[key] = value;
        });

        setParams(tmpParams);
    }, [searchParams]);

    const renderRowSubComponent = useCallback(
        ({ row }) => (
            <SubRowAsync row={row} endpoint="semiramis-complaints" />
        ),
        []
    );

    return <>
        {(isLoading || isFetching) && <Loader />}

        <Section title="Cerca reclamo" className="mb-10">
            <ComplaintsSearchBar setSearchParams={setSearchParams} />
        </Section>

        <>
            {
                semiramisComplaints?.data?.length > 0 ?
                    <>
                        <ComplaintsTable
                            items={semiramisComplaints?.data}
                            setParams={setParams}
                            renderRowSubComponent={renderRowSubComponent}
                        />
                        <Pagination
                            currentPage={currentPage}
                            totalCount={semiramisComplaints?.meta?.total}
                            pageSize={10}
                            onPageChange={setCurrentPage}
                        />
                    </>
                    :
                    <div className="h-96 flex flex-col items-center justify-center space-y-6">
                        <span className="text-9xl text-gray-200"><ImFilesEmpty /></span>
                        <p className="text-2xl text-gray-600">{t("Nessun reclamo trovato")}</p>
                    </div>
            }
        </>
    </>;
}
