const EserviceDown = () => {
    return (
        <div className="py-10 2xl:px-28 text-center text-xl">
            <p className="py-4">
                <b className="text-2xl">Siamo spiacenti di dovervi informare che il nostro server attualmente è in manutenzione.</b><br /> Vi preghiamo di contattare il vostro agente di riferimento.
            </p>
            <p className="py-4">
                <b className="text-2xl">We regret to inform you that our server is currently under maintenance.</b><br /> Please contact your sales representative.
            </p>
            <p className="py-4">
                <b className="text-2xl">Wir bedauern, Ihnen mitteilen zu müssen, dass unser Server derzeit gewartet wird.</b><br /> Bitte wenden Sie sich an Ihren Ausendienstmitarbeiter.
            </p>
            <p className="py-4">
                <b className="text-2xl">Nous sommes désolés de vous informer que notre serveur est actuellement en maintenance.</b><br /> Por favor, póngase en contacto con su agente de referencia.
            </p>
            <p className="py-4">
                <b className="text-2xl">Nous sommes désolés de vous informer que notre serveur est actuellement en maintenance.</b><br /> Veuillez contacter votre agent de référence.
            </p>
        </div>
    );
};

export default EserviceDown;