import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useShippingContext } from "../../hooks/use-context/useShippingContext";
import client from "../client";

type OfferParams = {
    offer_id: number
};

type DuplicateOfferParams = {
    offer_id: number,
    customer_code: string,
    keep_prices: boolean
};

type CartParams = {
    cart_id: number
};

type UpdateOfferParams = {
    cart_id: number,
    body: {
        customer_code: string,
        name?: string,
        client_reference?: string,
        kind_of_pricing?: string | null
    }
};

export default function useOffersMutations() {
    const queryClient = useQueryClient();

    const { resetShippingContext } = useShippingContext();

    // REQUIRE OFFER
    const requireOffer = useMutation(async (params: CartParams) => {
        return await client.post("offer-requests", params);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["offers"]);
        }
    });

    // PROPOSE OFFER
    const proposeOffer = useMutation(async (params: CartParams) => {
        return await client.post("offer-proposals", params);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["offers"]);
        }
    });

    // DUPLICATE OFFER
    const duplicateOffer = useMutation(async (params: DuplicateOfferParams) => {
        return await client.post("duplicated-offers", params);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["offers"]);
            resetShippingContext();
        }
    });

    // NEW OFFER VERSION
    const newOfferVersion = useMutation(async (params: OfferParams) => {
        return await client.post("versioned-offers", params);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["offers"]);
            resetShippingContext();
        }
    });

    // REJECT OFFER
    const rejectOffer = useMutation(async (params: CartParams) => {
        return await client.post("rejected-offers", params);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["offers"]);
            queryClient.invalidateQueries(["offer"]);
        }
    });

    // DELETE OFFER
    const deleteOffer = useMutation(async (params: CartParams) => {
        return await client.delete(`offers/${params.cart_id}`);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["offers"]);
        }
    });

    // UPDATE OFFER 
    const updateOffer = useMutation(async (params: UpdateOfferParams) => {
        return await client.put(`offers/${params.cart_id}`, params.body);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["offer"]);
        }
    });

    return { requireOffer, proposeOffer, duplicateOffer, newOfferVersion, rejectOffer, deleteOffer, updateOffer };
}
