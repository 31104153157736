import { useTranslation } from "react-i18next";
import { BsCheckCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Button } from "../Button";
import { Title } from "../Title";

export const OrderSuccess = ({ order }: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const goToOrders = () => {
        navigate("/orders");
    };

    const goToOrder = () => {
        navigate(`/orders/${order?.data?.id}`);
    };

    return (
        <div className='flex flex-col items-center h-full'>
            <div className="mb-8">
                <BsCheckCircle className='text-secondary text-[50px] block m-auto mb-4' />
                <Title variant={Title.variant.secondary}>{t("Grazie {{customer_name}}", { customer_name: order?.data?.customer?.name }).toString()}</Title>
            </div>

            <div className="w-4/5 space-y-6 border p-6 font-light mb-4">
                <p className="font-bold">{t("Il tuo ordine {{semiramis_number}} è stato processato correttamente.", { semiramis_number: order?.data?.semiramis_number }).toString()}</p>
                <p>{t("Ti ringraziamo per aver acquistato i nostri prodotti e ti assicuriamo che i tuoi articoli verranno imballati accuratamente e spediti il prima possibile.")}</p>
                <p>{t("Riceverai una mail di conferma appena l'ordine verrà processato. Se non ricevi questa mail controlla la casella spam per assicurati di non perdere il messaggio.")}</p>
            </div>
            <p className="w-4/5 font-light text-gray-600">{t("Il tuo Tecnico Commerciale e il nostro Customer Service resteranno a tua disposizione per ogni dubbio o domanda inerente il tuo ordine.")}</p>

            <div className="flex flex-col gap-y-2 mt-8">
                <div className="text-center">{t("Rothoblaas team")}</div>
                <Button variant={Button.variant.secondary} onClick={goToOrder}>{t("Mostra dettagli ordine")}</Button>
                <Button variant={Button.variant.text} onClick={goToOrders}>{t("Torna alla lista ordini")}</Button>
            </div>
        </div>
    );
};
