import client from "../api/client";
import { Button } from "../components/Button"
import { useTranslation } from "react-i18next"
import { downloadResource } from "../constants/DownloadResource";
import { Loader } from "../components/Loader";
import { useEffect, useState } from "react";
import { useSelectStyles } from "../hooks/useSelectStyles";
import usePriceListings from "../api/price-listings/usePriceLisings";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { AvailableLanguages } from "../constants/AvailableLanguages";
import { useAuthContext } from "../hooks/use-context/useAuthContext";


export default function GrossPriceList({ setTitle }: any) {
    const { t } = useTranslation();

    // hooks
    const { priceListing } = usePriceListings();
    const { CustomStyles } = useSelectStyles();
    const { user } = useAuthContext();

    // state
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const [selectedPriceListing, setSelectedPriceListing] = useState<any>(null);
    const [selectedPriceListValidity, setSelectedPriceListValidity] = useState<any>(null);
    const [language, setLanguage] = useState<any>(AvailableLanguages.filter(option => option.value === user?.interface_language_id));

    // CLIENT
    const handleSearchChange = (inputValue: any) => {
        if (inputValue) {
            setSelectedPriceListing(inputValue?.pricelist_id);
            setSelectedPriceListValidity(inputValue?.validitypricelist);
        } else {
            setSelectedPriceListing(null);
            setSelectedPriceListValidity(null);
        }
    };

    const downloadPriceListExcel = async (ev: any, type: string) => {
        ev.preventDefault();

        // avoid multiple
        if (isDownloadLoading) {
            return;
        }

        setIsDownloadLoading(true);

        const res = await client.get(`reserved-conditions/gross-price-list/${selectedPriceListing}/xlsx/download`, {
            params: {
                validitypricelist: selectedPriceListValidity,
                language_id: language[0]?.value,
                "filter[pricelist_type]": type,
            },
            headers: {
                responseType: "blob"
            }
        });

        // mode download
        if (res.data && res.data.content && res.data.name && res.data.type) {
            const filetype = res.data.type;
            const content = res.data.content;
            const filename = res.data.name;

            downloadResource(content, filename, filetype);
        }

        setIsDownloadLoading(false);
    };

    const downloadPriceListPdf = async (ev: any, type: string) => {
        ev.preventDefault();

        // avoid multiple
        if (isDownloadLoading) {
            return;
        }

        setIsDownloadLoading(true);

        const res = await client.get(`reserved-conditions/gross-price-list/${selectedPriceListing}/pdf/download`, {
            params: {
                validitypricelist: selectedPriceListValidity,
                language_id: language[0]?.value,
                "filter[pricelist_type]": type,
            },
            headers: {
                responseType: "blob"
            }
        });

        // mode download
        if (res.data && res.data.content && res.data.name && res.data.type) {
            const filetype = res.data.type;
            const content = res.data.content;
            const filename = res.data.name;

            downloadResource(content, filename, filetype);
        }

        setIsDownloadLoading(false);
    };

    useEffect(() => {
        setTitle(t("Listini prezzi"));
        // update language automatically when user changes interface language
        // setLanguage(AvailableLanguages.filter(option => option.value === user?.interface_language_id));
    }, [user?.interface_language_id]);

    return <>
        {(isDownloadLoading) &&
            <Loader />}

        {/* language select */}
        <div className="space-y-1 md:w-1/2">
            <label className="text-sm">{t("Lingua del listino")}</label>
            <Select
                styles={CustomStyles}
                options={AvailableLanguages}
                placeholder={t("scegli la lingua")}
                onChange={(e) => setLanguage(AvailableLanguages.filter(option => option.value === e.value))}
                escapeClearsValue
                menuPlacement={"bottom"}
                value={language}
            />
        </div>

        <div className="md:w-1/2 mt-5 gap-1">
            <label className="text-sm">{t("Seleziona listino")}</label>
            <AsyncSelect
                loadOptions={priceListing}
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                onChange={handleSearchChange}
                styles={CustomStyles}
                placeholder={t("inserisci il codice")}
                loadingMessage={() => t("Caricamento in corso...")}
                noOptionsMessage={() => t("Nessun risultato")}
                openMenuOnClick={false}
                isClearable
                escapeClearsValue
                menuPlacement="bottom"
            />
        </div>

        {
            selectedPriceListing &&
            <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-5 2xl:w-1/2">
                <div className="border p-6 flex flex-col gap-3">
                    <div className="flex gap-6 justify-between items-center">
                        <div className="h-14 flex justify-center items-center">
                            <img src="/rothoblaas_black_logo.png" alt="" width={150} />
                        </div>
                        <span className="text-lg font-semibold">{t("Listino RB")}</span>
                    </div>
                    <div>
                        <div className="flex gap-2">
                            <Button icon={Button.icon.download}
                                variant={Button.variant.primary}
                                onClick={(ev) => downloadPriceListExcel(ev, "rb")} className="w-full">
                                {t("Excel")}
                            </Button>
                            <Button icon={Button.icon.download}
                                variant={Button.variant.primary}
                                onClick={(ev) => downloadPriceListPdf(ev, "rb")} className="w-full">
                                {t("PDF")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="border p-6 flex flex-col gap-3">
                    <div className="flex gap-6 justify-between items-center">
                        <div className="h-14 flex justify-center items-center">
                            <img src="/holz_technic_logo.png" alt="" width={150} />
                        </div>
                        <span className="text-lg font-semibold">{t("Listino HT")}</span>
                    </div>
                    <div>
                        <div className="flex gap-2">
                            <Button icon={Button.icon.download}
                                variant={Button.variant.primary}
                                onClick={(ev) => downloadPriceListExcel(ev, "ht")} className="w-full">
                                {t("Excel")}
                            </Button>
                            <Button icon={Button.icon.download}
                                variant={Button.variant.primary}
                                onClick={(ev) => downloadPriceListPdf(ev, "ht")} className="w-full">
                                {t("PDF")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="border p-6 flex flex-col gap-3 max-w-1/2">
                    <div className="flex gap-6 justify-between items-center">
                        <div className="h-14 flex justify-center items-center">
                            <img src="/rothoblaas_black_logo.png" alt="" width={150} />
                        </div>
                        <span className="text-lg font-semibold">{t("Listino SAFE")}</span>
                    </div>
                    <div>
                        <div className="flex gap-2">
                            <Button icon={Button.icon.download}
                                variant={Button.variant.primary}
                                onClick={(ev) => downloadPriceListExcel(ev, "safe")} className="w-full">
                                {t("Excel")}
                            </Button>
                            <Button icon={Button.icon.download}
                                variant={Button.variant.primary}
                                onClick={(ev) => downloadPriceListPdf(ev, "safe")} className="w-full">
                                {t("PDF")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="border p-6 flex flex-col gap-3">
                    <div className="flex gap-6 justify-between items-center">
                        <div className="h-14 flex justify-center items-center">
                            <img src="/rothoblaas_black_logo.png" alt="" width={150} />
                        </div>
                        <span className="text-lg font-semibold">{t("Listino ATTREZZATURA")}</span>
                    </div>
                    <div>
                        <div className="flex gap-2">
                            <Button icon={Button.icon.download}
                                variant={Button.variant.primary}
                                onClick={(ev) => downloadPriceListExcel(ev, "tools")} className="w-full">
                                {t("Excel")}
                            </Button>
                            <Button icon={Button.icon.download}
                                variant={Button.variant.primary}
                                onClick={(ev) => downloadPriceListPdf(ev, "tools")} className="w-full">
                                {t("PDF")}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
}
