import { createContext, useCallback, useEffect, useReducer } from "react";
import { useAuthContext } from "../hooks/use-context/useAuthContext";

type ShippingContextProps = {
    children: React.ReactNode;
};

type ShippingContextType = {
    shipping_method: any;
    shipping_methods: any;
    delivery_address: any;
    rotho_notes: any;
    client_reference: any;
    delivery_notice_info: any;
    delivery_addresses: any;
    payment_method: any;
    payment_terms: any;
    free_port: boolean;
    additional_freight_cost: string | null;
    manual_shipping_method: any;
    manual_freight_cost: string;
    delivery_restriction: number;
    setShippingContext: (context: ShippingInfo) => void;
    setShippingMethod: (method: any) => void;
    setShippingMethods: (methods: any) => void;
    setFreePort: (free_port: boolean) => void;
    setAdditionalFreightCost: (additional_freight_cost: string | null) => void;
    setManualShippingMethod: (manual_shipping_method: any) => void;
    setManualFreightCost: (manual_freight_cost: string) => void;
    setDeliveryAddress: (address: any) => void;
    setRothoNotes: (note: string) => void;
    setClientReference: (note: string) => void;
    resetShippingContext: () => void;
    setDeliveryNoticeInfo: (note: any) => void;
    setDeliveryAddresses: (addresses: any) => void;
    setNewAddress: (address: any) => void;
    resetDeliveyNoticeInfo: () => void;
    setPaymentMethod: (payment_method: any) => void;
    setPaymentTerms: (paymentTerms: any) => void;
    setDeliveryRestriction: (delivery_restriction: number) => void;
};

enum ActionKind {
    SET_SHIPPING_METHOD = "SET_SHIPPING_METHOD",
    SET_SHIPPING_METHODS = "SET_SHIPPING_METHODS",
    SET_FREE_PORT = "SET_FREE_PORT",
    SET_ADDITIONAL_FREIGHT_COST = "SET_ADDITIONAL_FREIGHT_COST",
    SET_MANUAL_SHIPPING_METHOD = "SET_MANUAL_SHIPPING_METHOD",
    SET_MANUAL_FREIGHT_COST = "SET_MANUAL_FREIGHT_COST",
    SET_DELIVERY_ADDRESS = "SET_DELIVERY_ADDRESS",
    SET_ROTHO_NOTES = "SET_ROTHO_NOTES",
    SET_CLIENT_REFERENCE = "SET_CLIENT_REFERENCE",
    SET_SHIPPING_CONTEXT = "SET_SHIPPING_CONTEXT",
    RESET_SHIPPING_CONTEXT = "RESET_SHIPPING_CONTEXT",
    SET_DELIVERY_NOTICE_INFO = "SET_DELIVERY_NOTICE_INFO",
    SET_DELIVERY_ADDRESSES = "SET_DELIVERY_ADDRESSES",
    SET_NEW_ADDRESS = "SET_NEW_ADDRESS",
    RESET_DELIVERY_NOTICE_INFO = "RESET_DELIVERY_NOTICE_INFO",
    SET_PAYMENT_TERMS = "SET_PAYMENT_TERMS",
    SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD",
    SET_DELIVERY_RESTRICTION = "SET_DELIVERY_RESTRICTION",
}

type Action = {
    type: ActionKind,
    payload: any
};

type ShippingInfo = {
    shipping_method: any;
    shipping_methods: any;
    delivery_address: any;
    rotho_notes: any;
    client_reference: any;
    delivery_notice_info: any;
    delivery_addresses: any;
    payment_terms: any;
    payment_method: any;
    free_port: boolean;
    additional_freight_cost: string | null;
    manual_shipping_method: any;
    manual_freight_cost: string;
    delivery_restriction: number;
};

export const ShippingContext = createContext<ShippingContextType | null>(null);

const shippingReducer = (state: ShippingInfo, action: Action) => {
    const { type, payload } = action;

    switch (type) {
        case ActionKind.SET_SHIPPING_METHOD:
            return { ...state, shipping_method: payload };
        case ActionKind.SET_SHIPPING_METHODS:
            return { ...state, shipping_methods: payload };
        case ActionKind.SET_DELIVERY_ADDRESS:
            return { ...state, delivery_address: payload };
        case ActionKind.SET_ROTHO_NOTES:
            return { ...state, rotho_notes: payload };
        case ActionKind.SET_CLIENT_REFERENCE:
            return { ...state, client_reference: payload };
        case ActionKind.SET_SHIPPING_CONTEXT:
            return payload;
        case ActionKind.RESET_SHIPPING_CONTEXT:
            return payload;
        case ActionKind.SET_DELIVERY_NOTICE_INFO:
            return { ...state, delivery_notice_info: { ...state.delivery_notice_info, [payload.name]: payload.value } };
        case ActionKind.SET_DELIVERY_ADDRESSES:
            return { ...state, delivery_addresses: payload };
        case ActionKind.SET_NEW_ADDRESS:
            return { ...state, delivery_addresses: [payload, ...state.delivery_addresses] };
        case ActionKind.RESET_DELIVERY_NOTICE_INFO:
            return { ...state, delivery_notice_info: payload };
        case ActionKind.SET_PAYMENT_TERMS:
            return { ...state, payment_terms: payload };
        case ActionKind.SET_PAYMENT_METHOD:
            return { ...state, payment_method: payload };
        case ActionKind.SET_ADDITIONAL_FREIGHT_COST:
            return { ...state, additional_freight_cost: payload };
        case ActionKind.SET_FREE_PORT:
            return { ...state, free_port: payload };
        case ActionKind.SET_MANUAL_SHIPPING_METHOD:
            return { ...state, manual_shipping_method: payload };
        case ActionKind.SET_MANUAL_FREIGHT_COST:
            return { ...state, manual_freight_cost: payload };
        case ActionKind.SET_DELIVERY_RESTRICTION:
            return { ...state, delivery_restriction: Number(payload) };
        default:
            return state;
    }
};

const initialState = {
    shipping_method: null,
    shipping_methods: null,
    delivery_address: null,
    delivery_notice_info: { name: "", phone: "", note: "" },
    rotho_notes: "",
    client_reference: "",
    delivery_addresses: [],
    payment_terms: null,
    payment_method: null,
    free_port: false,
    additional_freight_cost: "",
    manual_shipping_method: null,
    manual_freight_cost: "",
    delivery_restriction: 1,
};

export const ShippingContextProvider = ({ children }: ShippingContextProps) => {
    const [shippingInfo, dispatch] = useReducer(shippingReducer, initialState);

    const { user } = useAuthContext();

    const setShippingContext = useCallback((context: ShippingInfo) => {
        dispatch({ type: ActionKind.SET_SHIPPING_CONTEXT, payload: context });
    }, []);

    const setShippingMethod = useCallback((method: any) => {
        dispatch({ type: ActionKind.SET_SHIPPING_METHOD, payload: method });
    }, []);

    const setShippingMethods = useCallback((methods: any) => {
        dispatch({ type: ActionKind.SET_SHIPPING_METHODS, payload: methods });
    }, []);

    const setAdditionalFreightCost = (additional_freight_cost: string) => {
        dispatch({ type: ActionKind.SET_ADDITIONAL_FREIGHT_COST, payload: additional_freight_cost });
    };

    const setFreePort = (free_port: boolean) => {
        dispatch({ type: ActionKind.SET_FREE_PORT, payload: free_port });
    };

    const setManualShippingMethod = (manual_shipping_method: string) => {
        dispatch({ type: ActionKind.SET_MANUAL_SHIPPING_METHOD, payload: manual_shipping_method });
    };

    const setManualFreightCost = (manual_freight_cost: string) => {
        dispatch({ type: ActionKind.SET_MANUAL_FREIGHT_COST, payload: manual_freight_cost });
    };

    const setDeliveryAddress = useCallback((address: any) => {
        dispatch({ type: ActionKind.SET_DELIVERY_ADDRESS, payload: address });
    }, []);

    const setRothoNotes = useCallback((note: string) => {
        dispatch({ type: ActionKind.SET_ROTHO_NOTES, payload: note });
    }, []);

    const setClientReference = useCallback((note: string) => {
        dispatch({ type: ActionKind.SET_CLIENT_REFERENCE, payload: note });
    }, []);

    const setDeliveryNoticeInfo = (info: any) => {
        dispatch({ type: ActionKind.SET_DELIVERY_NOTICE_INFO, payload: info });
    };

    const resetShippingContext = useCallback(() => {
        dispatch({ type: ActionKind.RESET_SHIPPING_CONTEXT, payload: initialState });
    }, []);

    const setDeliveryAddresses = useCallback((addresses) => {
        dispatch({ type: ActionKind.SET_DELIVERY_ADDRESSES, payload: addresses });
    }, []);

    const setNewAddress = useCallback((address) => {
        dispatch({ type: ActionKind.SET_NEW_ADDRESS, payload: address });
    }, []);

    const resetDeliveyNoticeInfo = useCallback(() => {
        dispatch({ type: ActionKind.RESET_DELIVERY_NOTICE_INFO, payload: { name: "", phone: "", note: "" } });
    }, []);

    const setPaymentTerms = useCallback((paymentTerms) => {
        dispatch({ type: ActionKind.SET_PAYMENT_TERMS, payload: paymentTerms });
    }, []);

    const setPaymentMethod = useCallback((payment_method) => {
        dispatch({ type: ActionKind.SET_PAYMENT_METHOD, payload: payment_method });
    }, []);

    const setDeliveryRestriction = (delivery_restriction: number) => {
        dispatch({ type: ActionKind.SET_DELIVERY_RESTRICTION, payload: delivery_restriction });
    };

    useEffect(() => {
        const localShipping = localStorage.getItem(`${user?.id}-shippingInfo`);
        const localShippingInfo = localShipping ? JSON.parse(localShipping) : initialState;

        dispatch({ type: ActionKind.SET_SHIPPING_CONTEXT, payload: localShippingInfo });
    }, [user]);

    useEffect(() => {
        if (user) {
            localStorage.setItem(`${user?.id}-shippingInfo`, JSON.stringify(shippingInfo));
        }
    }, [shippingInfo, user?.id, user]);

    // useEffect(() => {
    //     console.log(shippingInfo)
    // }, [shippingInfo]);

    return (
        <ShippingContext.Provider value={{ ...shippingInfo, setShippingContext, setShippingMethod, setShippingMethods, setAdditionalFreightCost, setDeliveryAddress, setRothoNotes, setClientReference, resetShippingContext, setDeliveryNoticeInfo, setDeliveryAddresses, setNewAddress, resetDeliveyNoticeInfo, setPaymentTerms, setManualShippingMethod, setManualFreightCost, setPaymentMethod, setFreePort, setDeliveryRestriction }}>
            {children}
        </ShippingContext.Provider>
    );
};
