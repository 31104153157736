import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(Backend)
    .init({
        //default language
        fallbackLng: "en",
        //set to false in prod
        debug: false,
        detection: {
            //order of checking language methods
            order: ["localStorage"],
            //where to store lng info
            caches: ["localStorage"],
        },
        interpolation: { escapeValue: false },
        backend: {
            //path to translation files
            loadPath: "/locales/{{lng}}/translation.json",
            requestOptions: {
                cache: "no-store",
            },
        }
    });

export default i18n;
