import { useTranslation } from "react-i18next";
import { Title } from "../components/Title";
import useOutstandingOrders from "../api/outstanding-orders/useOutstandingOrders";
import { useCallback, useEffect, useState } from "react";
import { OutstandingOrdersTable } from "../components/outstanding-orders/OutstandingOrdersTable";
import Pagination from "../components/Pagination";
import PreviewOutstandingItemsTable from "../components/outstanding-orders/PreviewOutstandingItemsTable";
import OutstandingOrdersSearchBar from "../components/outstanding-orders/OutstandingOrdersSearchBar";
import { Loader } from "../components/Loader";
import { Section } from "../components/Section";

type searchParams = {
    "filter[code]"?: string,
    "filter[name]"?: string,
};

export default function OutstandingOrders({ setTitle }: any) {
    const { t } = useTranslation();

    // state
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams, setSearchParams] = useState<searchParams>({});

    const { outstandingOrders, isLoading, isFetching } = useOutstandingOrders({ page: currentPage, ...searchParams });

    // SUB ROW RENDER FUNCTION
    const renderRowSubComponent = useCallback(
        ({ row }) => (
            <PreviewOutstandingItemsTable items={row.original.outstandingItems} />
        ),
        []
    );

    // reset page when params change
    useEffect(() => {
        setCurrentPage(1);
    }, [searchParams]);

    useEffect(() => {
        setTitle(t("Le mie rimanenze"));
    }, []);

    return (
        <div>
            {(isLoading || isFetching) && <Loader />}

            <Section title={"Ricerca cliente"} className="mb-10">
                <OutstandingOrdersSearchBar setSearchParams={setSearchParams} />
            </Section>

            {
                outstandingOrders?.data?.length > 0 &&
                <>
                    <OutstandingOrdersTable renderRowSubComponent={renderRowSubComponent} items={outstandingOrders.data} setSearchParams={setSearchParams} />
                    <Pagination
                        currentPage={currentPage}
                        totalCount={outstandingOrders?.meta.total}
                        pageSize={10}
                        onPageChange={setCurrentPage}
                    />
                </>
            }

        </div>
    );
}
