import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useTable, useSortBy, usePagination, Column, useExpanded } from "react-table";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { getCurrencyDecimals } from "../../utils/getCurrencyDecimals";
import { SortableHeader } from "../commom/SortableHeader";
import { Status, StatusVariant } from "../Status";
import { OffersTableActions } from "./OffersTableActions";
import { Tooltip } from "@reach/tooltip";
import { OfferSearchParams } from "./OffersSearchBar";

type OffersTableProps = {
    items: any
    setParams: React.Dispatch<React.SetStateAction<OfferSearchParams>>
    renderRowSubComponent: any
};

export const OffersTable = ({ renderRowSubComponent, items, setParams }: OffersTableProps) => {
    const { user, roles } = useAuthContext();

    //state
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
    const { t } = useTranslation();

    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

    // table data
    const data = useMemo(() => items, [items]);

    const columns: Array<Column<any>> = useMemo(() => [
        {
            id: "expander",
            Header: "",
            disableSortBy: true,
            className: "border-b p-4",
            Cell: ({ row }: any) =>
            // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
            // to build the toggle for expanding a row

                <span
                    {...row.getToggleRowExpandedProps({
                        style: {
                            // We can even use the row.depth property
                            // and paddingLeft to indicate the depth
                            // of the row
                            paddingLeft: `${row.depth * 2}rem`,
                        },
                        tabIndex: 0
                    })}
                >
                    {row.isExpanded ? <HiChevronUp className="text-xl" /> : <HiChevronDown className="text-xl" />}
                </span>
        },
        {
            Header: t("Numero offerta").toString(),
            accessor: "offer_number",
            disableSortBy: true,
            className: "border-b p-4 font-normal first:underline text-left",
            Cell: (props) => {
                const offer = props.row.original;
                return <Link to={`/offers/${offer.id}/summary`}>{offer.offer_number + `${(offer.offer_version !== "0" && offer.offer_version !== null) ? `/${offer.offer_version}` : ""}`}</Link>;
            }
        },
        {
            Header: t("Utente").toString(),
            accessor: "user_name",
            disableSortBy: true,
            className: "border-b p-4 text-left",
            Cell: (props) => {
                return <span>{props?.row?.original?.user_name || "-"}</span>;
            }
        },
        {
            Header: t("Nota personalizzata").toString(),
            accessor: "client_reference",
            disableSortBy: true,
            maxWidth: 150,
            className: "border-b p-4 truncate text-left",
            Cell: (props) => {
                if (!props.row.original.client_reference) return "-";

                return <Tooltip
                    label={props.row.original.client_reference}
                    style={{
                        backgroundColor: "#F1F5F6",
                        borderRadius: "3px",
                        fontWeight: "300",
                        marginRight: "20px",
                        zIndex: 50
                    }}
                >
                    <span>{props.row.original.client_reference}</span>
                </Tooltip>;
            }
        },
        {
            Header: t("Codice cliente").toString(),
            disableSortBy: true,
            className: "border-b p-4 text-left",
            Cell: (props) => {
                const customer = props.row.original.customer;

                if (customer?.type === "DUMMY CUSTOMER") return null;

                return <span>{customer?.code}</span>;
            }
        },
        {
            Header: t("Nome cliente").toString(),
            accessor: "client_name",
            disableSortBy: true,
            className: "border-b p-4 text-left",
            Cell: (props) => {
                return <span>{props.row.original.heading ? (props.row.original.heading.name ? props.row.original.heading.name.toUpperCase() : "Missing Name") : (props.row.original.client_name ? props.row.original.client_name.toUpperCase() : "Missing Client Name")}</span>;
            }
        },
        {
            Header: t("Technical Salesman").toString(),
            accessor: "technical_salesman",
            disableSortBy: true,
            className: "border-b p-4 text-left",
            Cell: (props) => {
                const customer = props.row.original.customer;

                // return <span>{customer?.ts_code}</span>
                return <span>{customer?.ts_fullname}</span>;
            }
        },
        {
            Header: (props) => {
                return <SortableHeader props={props} title={t("Data offerta").toString()} />;
            },
            accessor: "offer_created_at",
            className: "border-b p-4 text-left",
            Cell: (props) => {
                const offer_state = props.row.original.offer_state;
                let date;

                if (offer_state === "proposed") {
                    date = props.row.original.offer_proposed_at;
                } else {
                    date = props.row.original.offer_created_at;
                }

                return <>{dateFormat.format(new Date(date))}</>;
            }
        },
        {
            Header: t("Importo senza IVA").toString(),
            accessor: "total_net_price",
            disableSortBy: true,
            className: "border-b p-4 text-right",
            Cell: (props) => {
                const total_net_price = props.row.original.total_net_price;
                const customer = props.row.original.customer;
                const customerProposal = props.row.original.customerProposal;

                const currency = customerProposal ? customerProposal?.currency_id : customer?.currency;
                const numberFormat = new Intl.NumberFormat(`${customer?.language_id}`, { style: "currency", currency: currency, minimumFractionDigits: getCurrencyDecimals(currency), maximumFractionDigits: getCurrencyDecimals(currency) });

                return <>
                    {numberFormat.format(customerProposal ? customerProposal.total_net_price : total_net_price)}
                </>;
            }
        },
        {
            Header: t("Stato offerta").toString(),
            disableSortBy: true,
            className: "border-b p-4 text-left",
            Cell: (props) => {
                const isExpired = props.row.original.offer_expired;
                const offer_state = props.row.original.status;
                let state: keyof typeof StatusVariant;
                let description;

                if (isExpired) {
                    state = "offer_expired";
                    description = t("Scaduta").toString();
                } else {
                    switch (offer_state) {
                        case "requested":
                            state = "offer_requested";
                            description = t("Richiesta").toString();
                            break;
                        case "proposed":
                            state = "offer_proposed";
                            description = !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ? t("Aperta").toString() : t("Ricevuta").toString();
                            break;
                        case "accepted":
                            state = "offer_accepted";
                            description = t("Accettata").toString();
                            break;
                        case "rejected":
                            state = "offer_rejected";
                            description = t("Rifiutata").toString();
                            break;
                        case "partially_confirmed":
                            state = "offer_partially_confirmed";
                            description = t("Parzialmente confermata").toString();
                            break;
                        default:
                            state = "offer_created";
                            break;
                    }
                }

                return (
                    <Status variant={Status.variant[state]}>{description}</Status>
                );
            }
        },
        {
            Header: " ",
            className: "border-b p-4",
            Cell: OffersTableActions
        },
    ], [t, hiddenColumns]);

    const defaultColumn = useMemo(
        () => ({
            width: "auto",
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        visibleColumns,
        page,
        state: { sortBy }
    } = useTable({ columns, data, defaultColumn, manualSortBy: true, initialState: { hiddenColumns: hiddenColumns, pageSize: 10 } }, useSortBy, useExpanded, usePagination);

    useEffect(() => {
        if (["offer_created_at"].includes(sortBy[0]?.id))
            setParams((prevParams: OfferSearchParams) => ({ ...prevParams, sort: sortBy[0].desc ? sortBy[0].id : `-${sortBy[0].id}` }));
    }, [sortBy]);

    // SET HIDDEN COLUMNS
    useEffect(() => {
        if (roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"])) {
            setHiddenColumns(["technical_salesman"]);
        } else {
            setHiddenColumns([]);
        }
    }, [data]);

    return (
        <>
            <table {...getTableProps()} className="border w-full text-xs font-light overflow-x-scroll block xl:table">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="border-b">
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    {...column.getHeaderProps({ className: (column as any).className })}
                                >
                                    {/* <span className='flex items-center gap-x-1'> */}
                                    {column.render("Header")}
                                    {/* </span> */}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            // Use a React.Fragment here so the table markup is still valid
                            <React.Fragment key={row.getRowProps().key}>
                                <tr>
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps({ className: (cell.column as any).className, style: { width: cell.column.width, maxWidth: cell.column.maxWidth } })}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                                {/*
                                    If the row is in an expanded state, render a row with a
                                    column that fills the entire length of the table.
                                */}
                                {row.isExpanded ? (
                                    <tr>
                                        <td colSpan={visibleColumns.length} className="border">
                                            {/*
                                                Inside it, call our renderRowSubComponent function. In reality,
                                                you could pass whatever you want as props to
                                                a component like this, including the entire
                                                table instance. But for this example, we'll just
                                                pass the row
                                            */}
                                            {renderRowSubComponent({ row, rowProps: row.getRowProps(), visibleColumns })}
                                        </td>
                                    </tr>
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};
