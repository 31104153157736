import { ShippingContext } from "../../contexts/ShippingContext";
import { useContext } from "react";

export const useShippingContext = () => {
    const context = useContext(ShippingContext);

    if(!context) {
        throw Error("useShippingContext must be inside shipping context provider");
    }

    return context;
};