import { useQuery } from "@tanstack/react-query";
import client from "../client";

export default function useUser(user_id: string | undefined) {
    const fetchUser = async (user_id: string | undefined) => {
        const res = await client.get(`user-management/${user_id}/roles-permissions`);
        return res.data;
    };

    // GET SINGLE USER
    const { data: user, isLoading, isFetching } = useQuery(["user", user_id],
        () => fetchUser(user_id),
        {
            refetchOnWindowFocus: false,
            enabled: !!user_id
        }
    );

    return { user, isLoading, isFetching };
}
