import { useQuery } from "@tanstack/react-query";
import client from "../../client";

type useMarketingPriceListParams = {
    "page": number,
    "filter[item_code]"?: string,
    "filter[organization]"?: string,
    "filter[priceclassification]"?: string,
    "filter[language]"?: string,
    "filter[brand]"?: string,
    "sort"?: string
};

export default function useMarketingPriceList(params: useMarketingPriceListParams, canFetch: boolean = true) {
    // GET PRICE LIST
    const fetchMarketingPriceList = async (params: useMarketingPriceListParams) => {
        const res = await client.get("reserved-conditions/marketing-price-list", { params });
        return res.data;
    };

    const { data: marketingPriceList, isLoading, isFetching, error } = useQuery(["marketing-price-list", params],
        () => fetchMarketingPriceList(params),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
            enabled: canFetch,
        }
    );

    return { marketingPriceList, isLoading, isFetching, error };
}