import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import classNames from "classnames";
import { useDiscountTableContext } from "../../hooks/use-context/useDiscountTableContext";
import { InfoTooltip } from "../commom/InfoTooltip";
import { Modal } from "../commom/Modal";
import { Controller, useFormContext } from "react-hook-form";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { toast } from "react-toastify";
import { DiscountMainRow } from "./DiscountMainRow";
import { Section } from "../Section";

export const DiscountTable = () => {
    const { t } = useTranslation();

    const { user } = useAuthContext();

    const {
        discountsData,
        editable,
        setEditable,
        modalCancel,
        setModalCancel,
        modalConfirm,
        setModalConfirm,
        setUpdates,
        discountFormRef,
        customerData,
        userCanModify,
        setCloseTableModal,
        haveDiscounts,
        haveDiscountsData,
        newDate,
        minClosingDate,
        formatDate,
        closeTableModal,
    } = useDiscountTableContext();

    const { trigger, reset, getFieldState, getValues, setValue } = useFormContext();

    const handleSetCloseDate = async () => {
        if (!getFieldState("closeTableDate")?.invalid && !!getValues("closeTableDate")) {

            const closeDate = getValues("closeTableDate");

            Object.keys(getValues()?.data ?? []).forEach((itemCode) => {
                if (getValues(`data.${itemCode}.current.date.until`) !== undefined) {
                    setValue(`data.${itemCode}.current.date.until`, closeDate);
                    setValue(`data.${itemCode}.future.discount.t200`, null);
                    setValue(`data.${itemCode}.future.discount.t290`, null);
                    setValue(`data.${itemCode}.future.date.from`, "");
                }
                if (getValues(`data.${itemCode}.future.date.until`) !== undefined) {
                    setValue(`data.${itemCode}.future.date.until`, closeDate);
                }
            });

            trigger();
            setCloseTableModal(false);
        } else {
            toast.error(t("Errore, la data è obbligatoria"));
        }
    };

    return <>
        {/* close modal dates */}
        <Modal title={t("Imposta data di scadenza")} open={closeTableModal} onClose={setCloseTableModal}>
            <div className="space-y-6">
                <div>
                    <div>{t("Desideri modificare la scadenza della tabella sconti?")}</div>
                    <div>{t("Data minima di scadenza:")} {formatDate(minClosingDate)}</div>
                </div>
                <div className="space-y-2">
                    <div>{t("Imposta la nuova data di scadenza della tabella sconti.")}</div>
                    <div className="group relative w-fit">
                        <Controller
                            name={"closeTableDate"}
                            defaultValue={minClosingDate?.toISOString()?.substr(0, 10)}
                            rules={{
                                validate: async (date) => {
                                    const inputDate = newDate(date);
                                    return (inputDate >= minClosingDate) || t("la data deve essere maggiore o uguale alla data odierna o alla data minima di chiusura") as string;
                                },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <>
                                    <input
                                        onChange={onChange}
                                        value={value ?? ""}
                                        id="closeTableDate"
                                        type="date"
                                        min={minClosingDate?.toISOString()?.substr(0, 10)}
                                        className={"w-52 pl-4 pr-1 py-1 placeholder:text-gray-300 h-10" + (error?.message ? " border-rose-500 focus:outline-[0.5px] focus:outline-rose-500 focus:rounded border" : "")}
                                    />
                                    {error?.message && <div className="absolute group-hover:right-6 right-1 top-1/2 -translate-y-1/2 bg-white p-1 transition-all">
                                        <InfoTooltip className="z-50" color="#f43f5e">{error?.message}</InfoTooltip>
                                    </div>}
                                </>
                            )}
                        />
                    </div>
                </div>
                <div>
                    <div>{t("La data di scadenza verrà assegnata automaticamente a tutte le voci presenti nella tabella sconti.")}</div>
                    <div>{t("Per impostare una scadenza differente è posibile modificarla manualmente sulla voce specifica desiderata")}</div>
                </div>

                <div className="text-right space-x-4 mt-4">
                    <Button type="button" variant={Button.variant.text} onClick={() => setCloseTableModal(false)}>{t("Annulla")}</Button>
                    <Button variant={Button.variant.primary} onClick={() => {
                        handleSetCloseDate();
                    }}>{t("Imposta data di scadenza")}</Button>
                </div>
            </div>
        </Modal>

        {/* Cancel updates modal  */}
        <Modal title={t("Annulla le modifiche")} open={modalCancel} onClose={setModalCancel}>
            <div className="space-y-2">
                <div>{t("Sei sicuro di voler annullare le modifiche?")}</div>

                <div className="text-right space-x-4 mt-4">
                    <Button type="button" variant={Button.variant.text} onClick={() => setModalCancel(false)}>{t("Torna alle modifiche")}</Button>
                    <Button type="button" variant={Button.variant.primary} onClick={() => {
                        setEditable(false);
                        localStorage.removeItem(`${user?.id}_discount_table_editable`);
                        localStorage.removeItem(`${user?.id}_discount_session_data`);
                        localStorage.removeItem(`${user?.id}_discount_session_new_items`);
                        reset({ "customer_code": customerData?.code });
                        setUpdates([]);
                        setModalCancel(false);
                    }}>{t("Annulla le modifiche")}</Button>
                </div>
            </div>
        </Modal>

        {/* Save updates modal */}
        <Modal title={t("Trasmetti modifiche")} open={modalConfirm} onClose={setModalConfirm}>
            <div className="space-y-2">
                <div>{t("Sei sicuro di voler trasmettere le modifiche?")}</div>

                <div className="text-right space-x-4 mt-4">
                    <Button type="button" variant={Button.variant.text} onClick={() => setModalConfirm(false)}>{t("Annulla")}</Button>
                    <Button variant={Button.variant.primary} onClick={() => {
                        setModalConfirm(false);
                        discountFormRef?.current.dispatchEvent(new Event("submit", { bubbles: true, cancelable: false }));
                    }}>{t("Trasmetti")}</Button>
                </div>
            </div>
        </Modal>

        <Section title={"Tabella sconti"} className="mb-10">
            <fieldset>
                <legend className="sr-only">{t("Tabella sconti")}</legend>

                {userCanModify && <div className="flex gap-6">
                    {/* edit buttons  */}
                    {editable ? (
                        <>
                            <Button type="button" variant={Button.variant.text} onClick={() => { setModalCancel(true); }}>{t("Annulla modifiche")}</Button>
                            <Button type="button" variant={Button.variant.primary} onClick={() => { setModalConfirm(true); trigger(); }} isDisabled={!haveDiscountsData}>{t("Trasmetti modifiche")}</Button>
                        </>
                    ) : (
                        <Button type="button" variant={Button.variant.primary} icon={Button.icon.edit} onClick={() => {
                            setEditable(true);
                            localStorage.setItem(`${user?.id}_discount_table_editable`, JSON.stringify(true));
                            trigger();
                        }}>{t("Modifica tabella")}</Button>
                    )}
                </div>}

                <div className="mt-10 space-y-6">
                    {editable && haveDiscounts && <div className="flex items-center gap-4 font-light">
                        <div>{t("Imposta la data di chiusura di tutti gli sconti.")}</div>
                        <Button type="button" variant={Button.variant.secondary} icon={Button.icon.calendar} onClick={() => setCloseTableModal(true)}>{t("Imposta data")}</Button>
                    </div>}
                    <div className="flex flex-col gap-2">
                        {discountsData.map((macroClass: any, macroIndex: number) => {
                            return <div key={macroIndex} className="flex flex-col gap-2">
                                {macroClass?.data.map((subClass: any, subIndex: number) => {
                                    // sub class
                                    return <DiscountMainRow
                                        key={subIndex}
                                        subClass={subClass}
                                    />;
                                })}
                            </div>;
                        })}
                    </div>
                </div>

            </fieldset>
        </Section>

    </>;
};
