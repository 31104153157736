import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../hooks/use-context/useAuthContext";

export default function CheckUserLogged() {
    const { user } = useAuthContext();
    const location = useLocation();

    const intendedRoute: string | null = sessionStorage.getItem("intended_route");

    return (
        user && intendedRoute ? <Navigate to={intendedRoute} state={{ from: location }} replace /> :
            user ? <Navigate to={"/dashboard"} state={{ from: location }} replace />
                : <Outlet />
    );
}
