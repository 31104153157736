import { useQuery } from "@tanstack/react-query";
import client from "../client";

type useOutstandingOrdersParams = {
    page: number
};

export default function useOutstandingOrders(params: useOutstandingOrdersParams) {
    // GET OFFERS
    const fetchOutstandingOrders = async (params: useOutstandingOrdersParams) => {
        const res = await client.get("sales-orders/outstanding", { params });
        return res.data;
    };

    const { data: outstandingOrders, isLoading, isFetching } = useQuery(["outstanding-orders", params],
        () => fetchOutstandingOrders(params),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5
        }
    );

    return { outstandingOrders, isLoading, isFetching };
}