import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../client";

type CartImportParams = {
    cart_id: number,
    body: any
};

export default function useCartImport() {
    const queryClient = useQueryClient();

    const cartImport = useMutation(async (params: CartImportParams) => {
        return await client.post(`carts/${params.cart_id}/xlsx-import`, { data: params.body });
    }, { onSuccess: () => { queryClient.invalidateQueries(["cart"]); } });

    return { cartImport };
}