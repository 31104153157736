import { createContext, useEffect, useState } from "react";
import { AuthUser } from "../interfaces/AuthUser";
import { useQueryClient } from "@tanstack/react-query";

type AuthContextProviderProps = {
    children: React.ReactNode;
};

type AuthContextType = {
    user: AuthUser | null;
    setUser: React.Dispatch<React.SetStateAction<AuthUser | null>>;
    impersonateToken: any;
    impersonateUser: any;
    showMarginColumns: any;
    setImpersonateToken: React.Dispatch<React.SetStateAction<string | null>>;
    setImpersonateUser: React.Dispatch<React.SetStateAction<string | null>>;
    setShowMarginColumns: React.Dispatch<React.SetStateAction<boolean>>;
    can: (permissions: string[]) => boolean;
    roles: (roles: string[]) => boolean;
};

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
    const queryClient = useQueryClient();

    // GET USER STORAGE
    const session: any = localStorage.getItem("user");
    const userData = session ? JSON.parse(session) : null;

    // GET IMPERSONATE TOKEN STORAGE
    const sessionImpersonateToken: any = sessionStorage.getItem("impersonate-token");
    const ImpersonateTokenData = sessionImpersonateToken ? sessionImpersonateToken : null;

    // GET IMPERSONATE USER STORAGE
    const sessionImpersonateUser: any = sessionStorage.getItem("impersonate-user");
    const ImpersonateUserData = sessionImpersonateUser ? JSON.parse(sessionImpersonateUser) : null;

    // GET IMPERSONATE USER STORAGE
    const sessionShowMarginColumns: any = localStorage.getItem("margin-columns");
    const marginColumnsData = sessionShowMarginColumns ? JSON.parse(sessionShowMarginColumns) === true ? true : false : false;

    const [user, setUser] = useState<AuthUser | null>(userData);
    const [impersonateToken, setImpersonateToken] = useState<string | null>(ImpersonateTokenData);
    const [impersonateUser, setImpersonateUser] = useState<string | null>(ImpersonateUserData);
    const [showMarginColumns, setShowMarginColumns] = useState<boolean>(marginColumnsData);

    const can = (permissions: string[]) => {
        return permissions.some(p => (user?.granted_permissions || []).includes(p));
    };

    const roles = (roles: string[]) => {
        return roles.some(r => (user?.roles || []).includes(r));
    };

    // USER STORAGE
    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(user));
        queryClient.invalidateQueries();
    }, [user]);

    // IMPERSONATE TOKEN STORAGE
    useEffect(() => {
        if (impersonateToken) {
            sessionStorage.setItem("impersonate-token", impersonateToken);
        } else {
            sessionStorage.removeItem("impersonate-token");
        }
        queryClient.invalidateQueries();
    }, [impersonateToken]);

    // IMPERSONATE USER STORAGE
    useEffect(() => {
        if (impersonateUser) {
            sessionStorage.setItem("impersonate-user", JSON.stringify(impersonateUser));
        } else {
            sessionStorage.removeItem("impersonate-user");
        }
    }, [impersonateUser]);

    // MARGIN COLUMNS STORAGE
    useEffect(() => {
        if (showMarginColumns) {
            localStorage.setItem("margin-columns", JSON.stringify(showMarginColumns));
        } else {
            localStorage.removeItem("margin-columns");
        }
    }, [showMarginColumns]);

    return (
        <AuthContext.Provider value={{ user, setUser, can, roles, impersonateToken, setImpersonateToken, impersonateUser, setImpersonateUser, showMarginColumns, setShowMarginColumns }}>
            {children}
        </AuthContext.Provider>
    );
};
