import { useTranslation } from "react-i18next";
import { useState } from "react";
import { SelectAddressModal } from "../../../commom/SelectAddressModal";
import { FaPen } from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
import { Section } from "../../../Section";
import { useAuthContext } from "../../../../hooks/use-context/useAuthContext";

type Props = {
    data: any,
    setOpenNewAddress: any
};

export const DeliveryAddress = ({ data, setOpenNewAddress }: Props) => {
    const { t } = useTranslation();
    const { roles } = useAuthContext();

    const {
        name,
        street,
        city,
        region_id,
        postalcode,
        country_id,
        phone,
        new_address
    } = data?.data?.shipping_info?.address || [];

    // state
    const [openSelectAddress, setOpenSelectAddress] = useState(false);

    return (
        <Section title={"Indirizzo di consegna"}>
            <div className='border'>
                <div className="p-6 space-y-1">
                    {
                        data?.data?.shipping_info?.address ?
                            <>
                                <div className="font-light">{name}</div>
                                <div className="font-light">{street}</div>
                                <div className="font-light">{city}, {region_id} {postalcode} {country_id}</div>
                                {
                                    phone &&
                                    <div className="flex gap-x-1">
                                        <div>{t("Numero di telefono")}:</div>
                                        <div className="font-light">{phone}</div>
                                    </div>
                                }
                            </>
                            :
                            <p>{t("Indirizzo di spedizione non impostato, si prega di selezionarne uno dall’elenco.")}</p>

                    }


                    <div className="pt-6">
                        <hr />
                        <div className="flex gap-6">
                            <div className="flex items-center gap-x-2 cursor-pointer hover:underline pt-4 w-fit" onClick={() => setOpenSelectAddress(true)}>
                                <FaPen />
                                <div className="font-normal font-sans text-base">
                                    {t("Scegli indirizzo")}
                                </div>
                            </div>
                            <div className="flex items-center gap-x-2 cursor-pointer hover:underline pt-4 w-fit" onClick={() => setOpenNewAddress(true)}>
                                <AiOutlinePlus />
                                <div className="font-normal font-sans text-base">
                                    {new_address ? t("Modifica indirizzo") : t("Nuovo indirizzo")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* MODALS */}
            {openSelectAddress && <SelectAddressModal data={data} open={openSelectAddress} onClose={setOpenSelectAddress} />}
        </Section>
    );
};
