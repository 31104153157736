import { useEffect } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import useOffer from "../api/offers/useOffer";
import { Button } from "../components/Button";
import { Title } from "../components/Title";
import { useShippingContext } from "../hooks/use-context/useShippingContext";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../hooks/use-context/useAuthContext";

function OfferCreated() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const { user } = useAuthContext();

    const { resetShippingContext } = useShippingContext();

    const { data: offer } = useOffer(id, false);

    useEffect(() => {
        resetShippingContext();
    }, [resetShippingContext]);

    return (
        <div className='flex flex-col items-center h-full py-10 px-8 2xl:px-28'>
            <div className="mb-8">
                <BsCheckCircle className='text-secondary text-[50px] block m-auto mb-4'/>
                <Title variant={Title.variant.secondary}><span className="capitalize">{t("Ciao")} {user?.name}</span></Title>
            </div>

            <div className="w-4/5 space-y-6 border p-6 font-light mb-4">
                <p className="font-bold">{t("L'offerta {{offer_number}} per il tuo cliente {{customer_name}} è stata creata con successo.", { offer_number: offer?.data?.offer_number, customer_name: offer?.data?.customer?.name })}</p>
                <p>{t("A breve riceverai il pdf tramite email.")}.</p>
            </div>

            <div className="flex flex-col gap-y-2 mt-8">
                <div className="text-center">{t("Rothoblaas team")}</div>
                <Button variant={Button.variant.secondary} onClick={() => navigate(`/offers/${id}/summary`)}>{t("Mostra dettagli offerta")}</Button>
                <Button variant={Button.variant.text} onClick={() => navigate("/offers")}>{t("Torna alla lista offerte")}</Button>
            </div>
        </div>
    );
}

export default OfferCreated;
