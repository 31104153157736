import React from "react";
import ReactDOM from "react-dom";
import { Suspense } from "react";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";

// styles
import "./styles.css";
import "react-toastify/dist/ReactToastify.css";

//i18n config
import "./i18next/i18n";

import App from "./App";

import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_GA4_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA4_ID);
}

if (process.env.REACT_APP_SENRTY_URI) {
    const isProduction: boolean = ["production"].indexOf(process.env.REACT_APP_ENV || "") >= 0;
    Sentry.init({
        dsn: process.env.REACT_APP_SENRTY_URI,
        ignoreErrors: [
            "Error: Request failed with status code 401",
            "Request failed with status code 401",
            "Network Error",
            /Network Error/i,
        ],
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                // Dev only
                tracePropagationTargets: ["localhost", /^https:\/\/api\.eservice-test\.rothoblaas\.com\/api/],
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: isProduction ? 0.1 : 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: isProduction ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: isProduction ? 0.1 : 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

ReactDOM.render(
    <React.StrictMode>
        {/* <HistoryRouter history={history}> */}
        <Suspense fallback={<div>Loading...</div>}>
            <App/>
        </Suspense>
        {/* </HistoryRouter> */}
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
