export const downloadResource = (content, filename, filetype) => {

    // application/octet-stream
    const downloadAttributeSupport = "download" in document.createElement("a");

    fetch(`data:${filetype};base64,${content}`)
        .then((res) => res.blob())
        .then((blobObj) => {
            let blobLink = document.createElement("a");
            blobLink.style.visibility = "hidden";
            blobLink.style.position = "absolute";
            window.URL = window.URL || window.webkitURL;
            blobLink.href = window.URL.createObjectURL(blobObj);
            if (!downloadAttributeSupport) {
                blobLink.setAttribute("target", "_blank");
            }
            else {
                blobLink.setAttribute("download", filename);
            }
            blobLink.id = Math.random().toString(36).substring(7);
            blobLink.textContent = "Download Link";
            document.body.appendChild(blobLink);
            blobLink.click();
            document.body.removeChild(blobLink);
        })
        .catch((error) => {
            console.log(error);
        });

};
