import classnames from "classnames";
import { ReactNode } from "react";

type StatusProps = {
    variant: StatusVariant;
    children?: ReactNode
};

export enum StatusVariant {
    item_available = "green",
    item_partially_available = "yellow",
    item_not_available = "red",
    offer_accepted = "green",
    offer_rejected = "red",
    offer_expired = "amber",
    offer_created = "blue",
    offer_requested = "violet",
    offer_proposed = "yellow",
    offer_partially_confirmed = "brown",
    order_evaluation = "violet",
    order_processing = "blue",
    order_partially_shipped = "yellow",
    order_concluded = "green",
    order_reversed = "red",
    order_closed = "violet",
    sent_to_semiramis = "amber",
    eservice_blocked = "red",
    shipping_concluded = "green",
    shipping_reversed = "red",
    invoice_not_paid = "violet",
    invoice_partially_paid = "yellow",
    invoice_completely_paid = "green",
    invoice_reversed = "red",
}

export const VARIANT_MAPS = {
    yellow: "bg-yellow-400",
    red: "bg-red-500",
    green: "bg-lime-500",
    amber: "bg-amber-600",
    blue: "bg-sky-600",
    violet: "bg-violet-600",
    brown: "bg-brown-600",
};

Status.variant = StatusVariant;

export function Status({ variant, children }: StatusProps) {
    return (
        <div className='flex items-center'>
            {
                children ?
                    <div className='mr-1 whitespace-nowrap'>
                        {children}
                    </div>
                    : null
            }
            <div className='bg-gray-200 h-2.5 w-8 rounded-full'>
                <div className={classnames("h-full rounded-full", VARIANT_MAPS[variant])}></div>
            </div>
        </div>
    );
}