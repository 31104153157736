// Title.jsx
import { ReactNode } from "react";
import classnames from "classnames";

enum Variant {
    primary,
    secondary,
    tertiary,
}

type Props = {
    variant: Variant;
    children?: ReactNode;
    className?: string;
};

const VARIANT_MAPS: Record<Variant, string> = {
    [Variant.primary]: "flex w-full items-center justify-center mb-8 text-center uppercase font-industry font-light text-2xl before:content-[''] after:content-[''] before:flex-1 after:flex-1 before:border-t after:border-t before:mr-10 after:ml-10",
    [Variant.secondary]: "text-xl uppercase font-semibold",
    [Variant.tertiary]: "text-3xl uppercase font-normal",
};

export function Title(props: Props) {
    const { children, variant, className } = props;
    return (
        <div className={`${variant === Variant.tertiary ? "flex gap-4 items-center" : ""}`}>
            {
                variant === Variant.tertiary ? 
                    <div className="w-1 h-8 bg-secondary" /> : null
            }
            <h2 className={classnames("", VARIANT_MAPS[variant], className ? className : "")}>
                {children}
            </h2>
        </div>
    );
}

Title.defaultProps = {
    variant: Variant.primary
};

Title.variant = Variant;