import { Dialog } from "@reach/dialog";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { ClipLoader } from "react-spinners";
import { Button } from "../../../Button";
import { Title } from "../../../Title";
import { Loader } from "../../../Loader";
import { ReplaceProductParams } from "../../../../api/carts/useCartItemsMutations";
import { AxiosResponse } from "axios";
import { UseMutationResult } from "@tanstack/react-query";

type ChangeProductModalProps = {
    open: boolean;
    onClose: React.Dispatch<React.SetStateAction<boolean>>;
    alternativeItems: any;
    cartId: string;
    productId: string;
    replaceProduct: UseMutationResult<AxiosResponse<any, any>, unknown, ReplaceProductParams, unknown>;
};

export default function ChangeProductModal({ open, onClose, alternativeItems, cartId, productId, replaceProduct }: ChangeProductModalProps) {
    const { t } = useTranslation();

    // state
    const [selectedAlternative, setSelectedAlternative] = useState<any>(null);
    const [alternativeItemQty, setAlternativeItemQty] = useState<any>(0);
    const [error, setError] = useState({ alternativeItem: "", qty: "" });

    const handleSelectedAlternativeProduct = (alternativeItem: any) => {
        setSelectedAlternative(alternativeItem);
        setAlternativeItemQty(parseInt(alternativeItem?.minsellable));
    };

    const handleSubmit = async () => {
        if (!selectedAlternative) {
            setError({ ...error, alternativeItem: t("seleziona un prodotto alternativo") });
            return;
        } else {
            setError((prevErrors) => { return { ...prevErrors, alternativeItem: "" }; });
        }

        if (alternativeItemQty < parseInt(selectedAlternative.minsellable) || alternativeItemQty % selectedAlternative.minsellable !== 0 || alternativeItemQty === 0) {
            setError((prevErrors) => { return { ...prevErrors, qty: t("la quantità non rispetta il minimo vendibile") }; });
            return;
        }

        const body = {
            product_code: selectedAlternative?.code,
            quantity: alternativeItemQty
        };

        await replaceProduct.mutateAsync({ cart_id: cartId, product_id: productId, body });

        onClose(false);
    };

    const closeModal = () => {
        setSelectedAlternative(null);
        setAlternativeItemQty(0);
        onClose(false);
    };

    return (
        <Dialog isOpen={open} onDismiss={closeModal}>
            {replaceProduct.isLoading && <Loader />}
            <div className='space-y-2'>
                {
                    (!alternativeItems) ?
                        <div className='flex justify-center items-center'>
                            <ClipLoader
                                color={"black"}
                                loading={true}
                                size={100}
                                aria-label="Loading Spinner"
                            />
                        </div>
                        : <>

                            <div className='flex justify-between items-center'>
                                <Title variant={Title.variant.secondary}>{t("Sostituisci articolo")}</Title>
                                <MdClose className='text-lg cursor-pointer hover:text-secondary' onClick={closeModal} />
                            </div>

                            {
                                alternativeItems?.length > 0 ?
                                    <>
                                        <div className='font-light text-xs'>
                                            <p>{t("L'articolo che hai aggiunto al momento non è disponibile.")}</p>
                                            <p>{t("Vuoi sostituirlo con un articolo simile?")}</p>
                                        </div>

                                        {/* ITEMS TABLE */}
                                        <form>
                                            <fieldset className='mb-0'>
                                                <legend className='text-sm pb-1'>{t("Seleziona")}</legend>
                                                <div className={`border mb-4 ${error.alternativeItem.length > 0 ? "border-rose-500" : ""}`}>
                                                    {
                                                        alternativeItems && alternativeItems.map((alternativeItem: any) => (
                                                            <label key={alternativeItem.code} className='flex items-center p-2 w-full cursor-pointer border-b last:border-b-0 space-x-2'>
                                                                <div className='w-10 h-10 flex justify-center items-center'>
                                                                    <input
                                                                        type="radio"
                                                                        name='change_product'
                                                                        onChange={() => handleSelectedAlternativeProduct(alternativeItem)}
                                                                        required
                                                                    />
                                                                </div>
                                                                <img src={`https://resources.rothoblaas.com/eservice-images/product/${alternativeItem.code}`} className="zoom max-w-[50px] max-h-[50px] m-auto" alt='' />
                                                                <div className='text-xs space-y-1'>
                                                                    <div>{alternativeItem.code}</div>
                                                                    <div className='font-light'>{alternativeItem.description}</div>
                                                                </div>
                                                            </label>
                                                        ))
                                                    }
                                                    {/* </div> */}
                                                </div>

                                                {error && <p className='text-rose-500 text-xs'>{error.alternativeItem}</p>}

                                                {/* QTY INPUT */}
                                                <div className='mb-4'>
                                                    <label htmlFor="change_item_qty">{t("Quantità")}</label>
                                                    <input
                                                        className={`${error.qty.length > 0 && "border-rose-500"}`}
                                                        // ref={alternativeQtyInput}
                                                        value={alternativeItemQty}
                                                        onChange={(e) => setAlternativeItemQty(e.target.value)}
                                                        type="number"
                                                        min={selectedAlternative?.minsellable}
                                                        step={selectedAlternative?.minsellable}
                                                        disabled={!selectedAlternative}
                                                        required
                                                    />
                                                </div>

                                                {error && <p className='text-rose-500 text-xs'>{error.qty}</p>}

                                                <div className='text-right space-x-4'>
                                                    <Button variant={Button.variant.text} type="button" onClick={closeModal}>Annulla</Button>
                                                    <Button variant={Button.variant.primary} type="button" onClick={handleSubmit} isDisabled={!selectedAlternative}>{t("Sostituisci")}</Button>
                                                </div>
                                            </fieldset>
                                        </form>

                                    </>
                                    : (
                                        <>
                                            <div className='font-light text-sm'>
                                                {t("Nessun articolo alternativo disponibile per l'articolo selezionato")}
                                            </div>
                                            <div className='text-right space-x-4'>
                                                <Button variant={Button.variant.text} type="button" onClick={closeModal}>{t("Annulla")}</Button>
                                            </div>
                                        </>
                                    )
                            }
                        </>
                }
            </div>
        </Dialog>
    );
}
