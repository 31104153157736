import { useQuery } from "@tanstack/react-query";
import client from "../client";

type useSemiramisComplaintsParams = {
    page: number
};

export default function useSemiramisComplaints(params: useSemiramisComplaintsParams) {
    // GET OFFERS
    const fetchSemiramisComplaints = async (params: useSemiramisComplaintsParams) => {
        const res = await client.get("semiramis-complaints", { params });
        return res.data;
    };

    const { data: semiramisComplaints, isLoading, isFetching } = useQuery(["semiramis_complaints", params],
        () => fetchSemiramisComplaints(params),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5
        }
    );

    return { semiramisComplaints, isLoading, isFetching };
}
