import { useQuery } from "@tanstack/react-query";
import client from "../client";

type usePriceHistoryParams = {
    page: number,
    customer_code: string,
    item_code: string,
    from: string,
    to: string,
};

export default function usePriceHistory(params: usePriceHistoryParams) {
    // GET OFFERS
    const fetchPriceHistory = async (params: usePriceHistoryParams) => {
        const res = await client.get("sales-orders/price-history", { params: params });
        return res.data;
    };

    const { data: priceHistory, isLoading, isFetching } = useQuery(["price-history", params],
        () => fetchPriceHistory(params),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
            enabled: !!params.customer_code && !!params.item_code && !!params.from && !!params.to 
        }
    );

    return { priceHistory, isLoading, isFetching };
}