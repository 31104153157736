import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const FixingLineType = () => {
    const { register, formState: { errors } } = useFormContext();
    const { t } = useTranslation();

    return (
        <div className="border p-6 space-y-4">
            {/* APPLICATION DATE */}
            <div className="w-1/2 pr-2">
                <label htmlFor="application_date">{t("Data applicazione prodotto")}</label>
                <input
                    {...register("application_date", { required: `${t("- questo campo è obbligatorio")}` })}
                    type="date"
                    id="application_date"
                />
                {errors?.application_date && <p className="text-sm text-rose-500 mt-1">{errors?.application_date.message}</p>}
            </div>

            {/* EXTERNAL APPLICATION */}
            <div className="space-y-2">
                <p className="text-sm">{t("E' stato applicato all'esterno?")}</p>

                <div className="flex items-center gap-x-4">
                    <div className="flex items-center gap-x-2">
                        <input
                            {...register("external_application", { required: `${t("- questo campo è obbligatorio")}` })}
                            className="cursor-pointer"
                            type="radio"
                            id="ae-si"
                            value={"si"}
                            name="external_application"
                        />
                        <label className="p-0 cursor-pointer font-light" htmlFor="ae-si">{t("Sì")}</label>
                    </div>
                    <div className="flex items-center gap-x-2">
                        <input
                            {...register("external_application", { required: `${t("- questo campo è obbligatorio")}` })}
                            className="cursor-pointer"
                            type="radio"
                            value={"no"}
                            id="ae-no"
                            name="external_application"
                        />
                        <label className="p-0 cursor-pointer font-light" htmlFor="ae-no">{t("No")}</label>
                    </div>
                </div>
                {errors?.external_application && <p className="text-sm text-rose-500 mt-1">{errors?.external_application.message}</p>}
            </div>

            {/* APPLICATION TYPE */}
            <div className="space-y-2">
                <p className="text-sm">{t("Tipo di applicazione")}</p>

                <div className="flex items-center gap-x-4">
                    <div className="flex items-center gap-x-2">
                        <input
                            {...register("application_type", { required: `${t("- questo campo è obbligatorio")}` })}
                            className="cursor-pointer"
                            type="radio"
                            value={"Diretto su legno"}
                            id="application_type_1"
                            name="application_type"
                        />
                        <label className="p-0 cursor-pointer font-light" htmlFor="application_type_1">{t("Diretto su legno")}</label>
                    </div>
                    <div className="flex items-center gap-x-2">
                        <input
                            {...register("application_type", { required: `${t("- questo campo è obbligatorio")}` })}
                            className="cursor-pointer"
                            type="radio"
                            value={"Su legno duro"}
                            id="application_type_2"
                            name="application_type"
                        />
                        <label className="p-0 cursor-pointer font-light" htmlFor="application_type_2">{t("Su legno duro")}</label>
                    </div>
                    <div className="flex items-center gap-x-2">
                        <input
                            {...register("application_type", { required: `${t("- questo campo è obbligatorio")}` })}
                            className="cursor-pointer"
                            type="radio"
                            value={"Su piastra in metallo"}
                            id="application_type_3"
                            name="application_type"
                        />
                        <label className="p-0 cursor-pointer font-light" htmlFor="application_type_3">{t("Su piastra in metallo")}</label>
                    </div>
                    <div className="flex items-center gap-x-2">
                        <input
                            {...register("application_type", { required: `${t("- questo campo è obbligatorio")}` })}
                            className="cursor-pointer"
                            type="radio"
                            value={"Su lengo umido"}
                            id="application_type_4"
                            name="application_type"
                        />
                        <label className="p-0 cursor-pointer font-light" htmlFor="application_type_4">{t("Su legno umido")}</label>
                    </div>
                </div>
                {errors?.application_type && <p className="text-sm text-rose-500 mt-1">{errors?.application_type.message}</p>}
            </div>

            {/* MATERIAL USED */}
            <div>
                <label htmlFor="material_used">{t("Tipo di calcestruzzo/acciaio/legno utilizzato e descrizione dell'ambiente in cui è inserito")}</label>
                <input
                    {...register("material_used", { required: `${t("- questo campo è obbligatorio")}` })}
                    id="material_used"
                    type="text"
                    placeholder={t("Inserisci descrizione")}
                />
                {errors?.material_used && <p className="text-sm text-rose-500 mt-1">{errors?.material_used.message}</p>}
            </div>
        </div>
    );
};
