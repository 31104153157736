import { useTranslation } from "react-i18next";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { CustomerSelect } from "../components/commom/CustomerSelect";
import { useEffect, useState } from "react";
import { DiscountActions } from "../components/discounts/DiscountActions";
import { DiscountTable } from "../components/discounts/DiscountTable";
import { DiscountCustomerDetails } from "../components/discounts/DiscountCustomerDetails";
import { useDiscountTableContext } from "../hooks/use-context/useDiscountTableContext";
import useGetDiscountsData from "../api/discounts/useGetDiscountsData";
import { Loader } from "../components/Loader";
import { Slide, ToastContainer, toast } from "react-toastify";
import { OnChangeCallback } from "react-toastify/dist/core";
import useDiscountsTableMutations from "../api/discounts/useDiscountsTableMutations";
import useGetDiscountCustomers from "../api/discounts/useGetDiscountCustomers";
import useDiscountsCustomerMutations from "../api/discounts/useDiscountsCustomerMutations";
import { useAuthContext } from "../hooks/use-context/useAuthContext";
import { isAfter } from "date-fns";

const Discount = ({ setTitle }: any) => {
    const { t } = useTranslation();

    const { user } = useAuthContext();

    const { loadCustomersOptions } = useGetDiscountCustomers();
    const [selectedCustomer, setSelectedCustomer] = useState<any>(null);

    const {
        updateDiscountData,
        customerData,
        getFromLocalStorage,
        discountFormRef,
        setEditable,
        setCustomerData,
        updates,
        setUpdates,
        newDate,
        setMinClosingDate,
        haveDiscounts,
        setHaveDiscounts,
        setHaveDiscountsData,
    } = useDiscountTableContext();

    // react hook form methods
    const methods = useForm({ mode: "onChange", defaultValues: { customer_code: customerData?.code, ...getFromLocalStorage(`${user?.id}_discount_session_data`) } });

    // save, close api
    const { saveDiscount } = useDiscountsTableMutations();
    const { getCustomer } = useDiscountsCustomerMutations();

    // get customer discounts
    const { discounts, isLoading, isError, isFetching, isInitialLoading } = useGetDiscountsData({ "filter[customer_code]": customerData?.code ?? "" });

    // cleanSession on submit and after submit
    const cleanSession = (customerData: any) => {
        setEditable(false);
        localStorage.removeItem(`${user?.id}_discount_session_data`);
        localStorage.removeItem(`${user?.id}_discount_session_new_items`);
        localStorage.removeItem(`${user?.id}_discount_table_editable`);
        setUpdates([]);
        methods.reset({ "customer_code": customerData?.code });
    };

    // check if there is some discount data added by user in order to enable/disable the submit button
    const checkDiscountsData = () => {
        let itemsNumber = 0;
        Object.keys(methods.getValues()?.data ?? {}).forEach((e) => {
            if (!!methods.getValues(`data.${e}.current.date.until`) || !!methods.getValues(`data.${e}.future.date.from`) || !!methods.getValues(`data.${e}.future.date.until`) || !!methods.getValues(`data.${e}.new.discount.t200`) || !!methods.getValues(`data.${e}.new.date.from`)) {
                itemsNumber++;
            }
        });

        setHaveDiscountsData(itemsNumber > 0);
    };

    // update session data in localstorage at every change
    useEffect(() => {
        localStorage.setItem(`${user?.id}_discount_session_data`, JSON.stringify(methods.getValues()));
        localStorage.setItem(`${user?.id}_discount_session_new_items`, JSON.stringify(updates));

        // set if user have discounts data
        checkDiscountsData();
    }, [useWatch({ control: methods.control }), updates]);

    // refresh table data when discount data coming from api change, and when th euser try to close the table
    useEffect(() => {
        // set the min date from when user can close the table
        const today = newDate();
        const max_date = newDate(discounts?.data?.max_date);

        if (max_date < today) {
            setMinClosingDate(today);
        } else {
            setMinClosingDate(max_date);
        }

        // set if user have discounts in order to hide the close table section
        setHaveDiscounts(Object.keys(discounts?.data?.discounts ?? {})?.length > 0);

        // update discount data that come from api, if the table is closed that function update the discount state and hide all the already existing discounts
        updateDiscountData(discounts?.data);

        // trigger the field validation for all the discounts
        methods.trigger();
    }, [discounts]);

    // if get customer discounts fail remove all data
    useEffect(() => {
        if (isError) {
            setCustomer(false);
        }
    }, [isError]);

    // if items exists in session data set the table as editable and trigger al fields for validation
    useEffect(() => {
        setTitle(t("Tabella sconti"));

        // refetch customer at page reload
        if (customerData?.code) {
            getCustomer.mutateAsync({ "filter[search]": customerData?.code }).then((res) => {
                setCustomer(res?.data?.data?.[0]);
                if ((customerData?.processingTables ?? []).length > 0) {
                    cleanSession(customerData);
                }
            });
        }
    }, []);

    // set/remove customer
    const setCustomer = (inputValue: any) => {
        // if customer data exists update customer state, localstorage and remove session data
        // NB: if user change it remove old data and set the new castomer
        if (inputValue) {
            setCustomerData(inputValue);
            methods.setValue("customer_code", inputValue?.code);
            localStorage.setItem(`${user?.id}_discount_customer_data`, JSON.stringify(inputValue));
        } else {
            setEditable(false);
            localStorage.removeItem(`${user?.id}_discount_table_editable`);
            localStorage.removeItem(`${user?.id}_discount_customer_data`);
            localStorage.removeItem(`${user?.id}_discount_session_data`);
            localStorage.removeItem(`${user?.id}_discount_session_new_items`);
            setCustomerData(null);
            setUpdates([]);
            methods.reset({ "customer_code": null });
        }
    };

    // handle customer SELECT change
    const handleCustomerChange = (inputValue: any, onChange: OnChangeCallback) => {
        // if the customer chenge all the data are replaced or removed
        setSelectedCustomer(inputValue);
        if (inputValue?.code) {
            cleanSession(inputValue);
            setCustomer(inputValue);
            onChange(inputValue.code);
        }
    };

    // submit handler
    const submitHandler = async (formData: any) => {
        const keys = Object.keys(formData?.data ?? {});
        const finalData: any = [];

        // function that generate the final data structure
        const generateFinalData = (data: any, key: any) => {
            finalData.push({
                item_id: key,
                date: {
                    "from": data?.date?.from ?? data?.date?.exist,
                    "until": data?.date?.until,
                },
                discount: {
                    "200": data?.discount?.t200 ?? null,
                    "290": data?.discount?.t290 ?? null,
                }
            });
        };

        // check for form validation and customer code
        if (methods.formState.isValid && !!formData?.customer_code) {
            /* -------------------------------------------------------------- save discount table ------------------------------------------------------------- */

            // cleaning form state data keeping all the updates
            keys.forEach((key: string) => {
                if (formData?.data?.[key]?.current?.date?.from || formData?.data?.[key]?.current?.date?.until) {
                    generateFinalData(formData?.data?.[key]?.current, key);
                }
                if (formData?.data?.[key]?.future?.date?.from || formData?.data?.[key]?.future?.date?.until) {
                    generateFinalData(formData?.data?.[key]?.future, key);
                }
                if (formData?.data?.[key]?.new?.date?.from) {
                    generateFinalData(formData?.data?.[key]?.new, key);
                }
            });


            // api call
            await toast.promise(saveDiscount.mutateAsync({
                customer_code: formData?.customer_code,
                data: JSON.stringify(finalData)
            }), {
                pending: t("Carico..."),
                success: t("Le tue modifiche sono state salvate con successo!"),
                error: t("Errore, non è stato possibile salvare la tabella sconti"),
            });

            // refresh customer data and reset form and session data
            getCustomer.mutateAsync({ "filter[search]": customerData?.code }).then((res: any) => {
                setCustomer(res?.data?.data?.[0]);
                cleanSession(res?.data?.data?.[0]);
            });
        } else {
            toast.error(t("Errore"));
        }
    };

    const invalidSubmitHandler = (e: any) => {
        Object.keys(e?.data ?? {}).forEach((itemKey: any) => {
            toast.error(t("Sono presenti dei campi non validi all'interno della tabella sconti" + ` (${itemKey})`));
        });
    };

    return <>
        <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            transition={Slide}
        />

        <FormProvider {...methods}>
            {/* loader component */}
            {((isLoading && isInitialLoading) || isFetching || saveDiscount.isLoading || getCustomer.isLoading) && <Loader />}

            {/* customer select and update */}
            <div className="space-y-6">
                <Controller
                    control={methods.control}
                    name='customer_code'
                    render={({ field: { onChange }, fieldState: { error } }) => (
                        customerData ? (
                            <DiscountCustomerDetails
                                customerData={customerData}
                                loadCustomersOptions={loadCustomersOptions}
                                handleCustomerChange={(event: any) => handleCustomerChange(event, onChange)}
                                error={error}
                            />
                        ) : (
                            <fieldset>
                                <legend className="sr-only">{t("Cerca cliente")}</legend>
                                <CustomerSelect
                                    label="Seleziona cliente"
                                    loadCustomersOptions={loadCustomersOptions}
                                    handleChange={(event: any) => handleCustomerChange(event, onChange)}
                                    error={error}
                                    selectedCustomer={selectedCustomer}
                                    setSelectedCustomer={setSelectedCustomer}
                                />
                            </fieldset>
                        )
                    )}
                />

                {
                    customerData?.ts_code === "RGR" &&
                    <fieldset className="p-6 border">
                        <div className="font-light">{t("Gruppo cliente, per inserimento o modifica tabella sconti rivolgersi al CUSTOMER SERVICE")}</div>
                    </fieldset>
                }

                {(customerData?.processingTables?.length === 0 && !(customerData?.pricelistdate && isAfter(new Date(), new Date(customerData?.pricelistdate))) && !discounts?.too_many_discounts) && (
                    <>
                        {/* complete discount table */}
                        <form ref={discountFormRef} onSubmit={methods.handleSubmit(submitHandler, invalidSubmitHandler)}>
                            {/* close table section */}
                            {/* {customerData && haveDiscounts && <DiscountActions />} */}
                            {customerData && <DiscountTable />}
                        </form>
                    </>
                )}
                {
                    customerData?.processingTables?.length > 0 &&
                    <fieldset className="p-6 border space-y-6">
                        <div className="uppercase mb-2 text-xl leading-6 font-semibold">{t("Attenzione")}</div>
                        <div className="font-light">{t("Le modifiche appena apportate alla tabella  sconti sono in fase di elaborazione. Saranno completamente riflettute nel Listino Prezzi entro i prossimi 10 minuti. Ti invitiamo ad attendere questo breve intervallo prima di poter effettuare una ricerca o il download del listino.")}</div>
                    </fieldset>
                }
                {
                    customerData?.pricelistdate && isAfter(new Date(), new Date(customerData?.pricelistdate)) &&
                    <fieldset className="p-6 border">
                        <div className="font-light">{t("Cliente appartenente a un gruppo con condizioni contrattuali, per ulteriori informazioni contattare il CUSTOMER SERVICE")}</div>
                    </fieldset>
                }
                {
                    discounts?.too_many_discounts &&
                    <fieldset className="p-6 border">
                        <div className="font-light">{t("Cliente con più di 1000 record in tabella sconti, per ulteriori informazioni contattare il CUSTOMER SERVICE")}</div>
                    </fieldset>
                }
            </div>
        </FormProvider>
    </>;
};

export default Discount;
