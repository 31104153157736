import { Menu, MenuButton, MenuItem, MenuList } from "@reach/menu-button";
import { Tooltip } from "@reach/tooltip";
import { useTranslation } from "react-i18next";
import { HiOutlineDotsVertical } from "react-icons/hi";
import AddAddressModal, { Address } from "../../../templates/AddAddressModal";
import { useEffect, useState } from "react";
import useProfileMutations from "../../../../api/profile/useProfileMutations";
import { Modal } from "../../../commom/Modal";
import { Button } from "../../../Button";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../Loader";

export const DeliveryAddressTableActions = (props: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // state
    const [openNewAddres, setOpenNewAddress] = useState(false);
    const [openDeleteAddress, setOpenDeleteAddress] = useState(false);
    const [defaultValues, setDefaultValues] = useState<Address | null>(null);

    // react query
    const { updateProfile } = useProfileMutations();

    const modifyAddress = async (formData: Address) => {
        await updateProfile.mutateAsync({ customer_code: props?.externalData?.data?.code, updateAddress: formData });
        setOpenNewAddress(false);
        navigate("/profile-sent");
    };

    const handleDeleteAddress = async () => {
        await updateProfile.mutateAsync({ customer_code: props?.externalData?.data?.code, deleteAddress: props?.row?.original });
        navigate("/profile-sent");
    };

    useEffect(() => {
        setDefaultValues({
            name: props?.row?.original?.name,
            street: props?.row?.original?.street,
            country_id: props?.row?.original?.country_id,
            region_id: props?.row?.original?.region_id,
            city: props?.row?.original?.city,
            postalcode: props?.row?.original?.postalcode,
            phone: props?.row?.original?.deliverycontactphone
        });
    }, [openNewAddres, props]);

    return (
        <>
            {updateProfile.isLoading && <Loader />}
            {openNewAddres && <AddAddressModal open={openNewAddres} onClose={setOpenNewAddress} title={t("Modifica indirizzo")} primaryActionLabel={t("Modifica")} callbackFn={modifyAddress} defaultValues={defaultValues} />}

            {/* Save updates modal */}
            <Modal title={t("Elimina indirizzo")} open={openDeleteAddress} onClose={setOpenDeleteAddress}>
                <div className="space-y-2">
                    <div>{t("Sei sicuro di voler eliminare questo indirizzo?")}</div>

                    <div className="text-right space-x-4 mt-4">
                        <Button type="button" variant={Button.variant.text} onClick={() => setOpenDeleteAddress(false)}>{t("Annulla")}</Button>
                        <Button variant={Button.variant.primary} onClick={handleDeleteAddress}>{t("Trasmetti")}</Button>
                    </div>
                </div>
            </Modal>

            <Menu>
                <MenuButton>
                    <Tooltip
                        label={"actions"}
                        style={{
                            backgroundColor: "#F1F5F6",
                            borderRadius: "3px",
                        }}
                    >
                        <span>
                            <HiOutlineDotsVertical className="text-lg cursor-pointer" />
                        </span>
                    </Tooltip>
                </MenuButton>

                <MenuList className="offers-dropdown">
                    <MenuItem
                        className="offers-dropdown-items offers-dropdown-items-selected"
                        onSelect={() => setOpenNewAddress(true)}
                    >
                        {t("Modifica")}
                    </MenuItem>
                    <MenuItem
                        className="offers-dropdown-items offers-dropdown-items-selected"
                        onSelect={() => setOpenDeleteAddress(true)}
                    >
                        {t("Elimina")}
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    );
};
