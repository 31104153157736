import { useCallback, useMemo } from "react";
import client from "../client";
import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";

const useSearchBarProducts = () => {
    const { t } = useTranslation();

    const getProducts = useCallback(async (value: string) => {
        const params = {
            "filter[search]": value,
        };

        const res = await client.get("search-products", { params: params });

        // console.log(res);

        return res.data.data.map((item: any) => {
            let pieces = null;
            if (parseInt(item.piecesperpackage, 10) > 1) {
                pieces = <>({parseInt(item.piecesperpackage, 10)} {item.piecesperpackageuom})</>;
            }
            return {
                label: <span>{item.code} {item?.replacing_code ? <span className='font-bold p-1 rounded'>{t("Vecchio codice")}: {item?.replacing_code}</span> : null} - {item.description} {pieces}</span>,
                value: item
            };
        });
    }, [/*cart?.data?.customer?.code*/]);

    const loadItemsOptions = useMemo(() => {
        return debounce((value: any, callback: any) => {
            getProducts(value).then((options) => callback(options));
        }, 1000);
    }, [getProducts]);

    return { loadItemsOptions };
};

export default useSearchBarProducts;
