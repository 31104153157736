import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const AirTightnessAndWaterProofType = () => {
    const { register, formState: { errors } } = useFormContext();
    const { t } = useTranslation();

    return (
        <div className="border p-6 space-y-4">
            {/* APPLICATION DATE */}
            <div className="w-1/2 pr-2">
                <label htmlFor="application_date">{t("Data applicazione prodotto")}</label>
                <input
                    {...register("application_date", { required: `${t("- questo campo è obbligatorio")}` })}
                    type="date"
                    id="application_date"
                />
                {errors?.application_date && <p className="text-sm text-rose-500 mt-1">{errors?.application_date.message}</p>}
            </div>

            <div className="flex gap-x-4">
                <div className="flex-1">
                    <label>{t("Tempo di esposizione alle intemperie")}</label>
                    <input
                        {...register("exposure_time", { required: `${t("- questo campo è obbligatorio")}` })}
                        type="text"
                    />
                    {errors?.exposure_time && <p className="text-sm text-rose-500 mt-1">{errors?.exposure_time.message}</p>}
                </div>
                <div className="flex-1">
                    <label>{t("Tipo di pacchetto (stratigrafia)")}</label>
                    <input
                        {...register("package_type", { required: `${t("- questo campo è obbligatorio")}` })}
                        type="text"
                    />
                    {errors?.package_type && <p className="text-sm text-rose-500 mt-1">{errors?.package_type.message}</p>}
                </div>
            </div>

            <div>
                <label>{t("Come è stato stoccato il prodotto")}</label>
                <input
                    {...register("storage_type", { required: `${t("- questo campo è obbligatorio")}` })}
                    type="text"
                />
                {errors?.storage_type && <p className="text-sm text-rose-500 mt-1">{errors?.storage_type.message}</p>}
            </div>
        </div>
    );
};
