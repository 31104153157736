import { useCallback, useMemo } from "react";
import debounce from "lodash.debounce";
import client from "../client";

const useUsers = () => {
    // GET USERS
    const getUsers = useCallback(async (search: string) => {
        const params = {
            "filter[search]": search
        };

        const res = await client.get("users", { params: params });
        
        return res.data.data.map((user: any) => {
            return { ...user, label: `${user.name} - ${user.email}` };
        });

    }, []);

    const loadUsersOptions = useMemo(() => {

        return debounce((value: any, callback: any) => {
            getUsers(value).then((options) => callback(options));
        }, 1000);
    }, [getUsers]);

    return { loadUsersOptions };
};

export default useUsers;