import { useTranslation } from "react-i18next";
import useGetCustomers from "../../api/customers/useGetCustomers";
import { CustomerSelect } from "../commom/CustomerSelect";
import { Modal } from "../commom/Modal";
import { Dispatch, SetStateAction, useState } from "react";
import { Button } from "../Button";
import useOffersMutations from "../../api/offers/useOffersMutations";
import { useNavigate } from "react-router-dom";
import { useOrderMutations } from "../../api/orders/useOrderMutations";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";

type DuplicationModalProps = {
    label: string,
    modalState: boolean,
    setModalState: Dispatch<SetStateAction<boolean>>,
    data: any,
    type: "order" | "offer",
};

const DuplicationModal = ({ label, modalState, setModalState, data, type }: DuplicationModalProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { roles } = useAuthContext();

    const { loadCustomersOptions } = useGetCustomers();

    const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
    const [duplicationError, setDuplicationError] = useState<string>("");
    const [keepPrices, setKeepPrices] = useState(false);
    const { duplicateOffer } = useOffersMutations();
    const { duplicateOrder } = useOrderMutations();

    // handle customer SELECT change
    const handleCustomerChange = async (inputValue: any) => {
        setSelectedCustomer(inputValue);
        if (inputValue && data?.customer?.invoice_pricelist_id !== inputValue.invoice_pricelist_id && type === "offer") {
            setDuplicationError(t("Clienti con listini diversi, non è possibile mantenere i prezzi"));
        }

        if (!inputValue) {
            setDuplicationError("");
        }
    };

    // DUPLICATE
    const handleDuplicate = async () => {
        if (type === "offer") {
            const res = await duplicateOffer.mutateAsync({ offer_id: data?.id, customer_code: selectedCustomer?.code, keep_prices: keepPrices });
            navigate(`/carts/${res.data.data.id}`, { state: { removedItems: res.data.removedItems } });
        } else if (type === "order") {
            const res = await duplicateOrder.mutateAsync({ order_id: data?.id, customer_code: selectedCustomer?.code, is_sales_order: data?.isSalesOrder || false });
            navigate(`/carts/${res.data.data.id}`, { state: { removedItems: res.data.removedItems } });
        }
    };

    // handle keep prices flag change
    const handleKeepPricesChange = (e: any) => {
        setKeepPrices(e.target.checked);
    };

    return <Modal title={label} open={modalState} onClose={setModalState}>
        <div className="space-y-2">
            <CustomerSelect
                label="Seleziona cliente"
                loadCustomersOptions={loadCustomersOptions}
                handleChange={handleCustomerChange}
                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}
            />

            {type === "offer" && <div className="flex items-center gap-x-1">
                <input type="checkbox" id="keep_prices" className="peer cursor-pointer" disabled={duplicationError?.length > 0 || !selectedCustomer || data?.offer_expired} onChange={(e) => handleKeepPricesChange(e)} />
                <label htmlFor="keep_prices" className="p-0 cursor-pointer peer-disabled:opacity-25">{t("Mantenere prezzi")}</label>
            </div>}

            {duplicationError && <p className="text-rose-500">{duplicationError}</p>}

            {/* SOLO per offerte  */}
            {type === "offer" && data?.offer_expired && <p className="text-rose-500">{t("Attenzione offerta scaduta. I prezzi verranno aggiornati")}.</p>}


            {
                !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ?
                    <p className="text-red-500">{t("I costi di trasporto standard variano sempre a seconda delle condizioni del cliente")}.</p> : null
            }

            <div className="text-right space-x-2 mt-4">
                <Button variant={Button.variant.text} onClick={() => setModalState(false)}>{t("Annulla")}</Button>
                <Button variant={Button.variant.primary} onClick={handleDuplicate} isDisabled={!selectedCustomer}>{t("Procedi")}</Button>
            </div>
        </div>

    </Modal>;
};

export default DuplicationModal;