import { useCallback, useMemo } from "react";
import debounce from "lodash.debounce";
import client from "../client";

const useGetCustomers = () => {
    // GET CUSTOMERS
    const getCustomers = useCallback(async (search: string) => {
        const params = {
            "filter[search]": search
        };

        const res = await client.get("customers", { params: params });

        return res.data.data.map((customer: any) => {
            return { ...customer, label: `${customer.name} - ${customer.code} - ${customer.city} - ${customer.ts_fullname}` };
        });
    }, []);

    const loadCustomersOptions = useMemo(() => {

        return debounce((value: any, callback: any) => {
            getCustomers(value).then((options) => callback(options));
        }, 1000);
    }, [getCustomers]);

    return { loadCustomersOptions };
};

export default useGetCustomers;
