import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Tooltip } from "@reach/tooltip";
import { useTranslation } from "react-i18next";
import { InfoTooltip } from "../commom/InfoTooltip";
import { isSameDay } from "date-fns";

import "@reach/tooltip/styles.css";

type AvailabilityTooltipProps = {
    availability_date: Date
    hq_availability_date: Date,
    warehouse: string,
};

export const AvailabilityTooltip = ({ availability_date, hq_availability_date, warehouse }: AvailabilityTooltipProps) => {
    const { t } = useTranslation();
    const { user, roles } = useAuthContext();

    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

    let template;
    // tooltip template
    if (hq_availability_date && !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"])) {
        template = (
            <div className="space-y-2">
                <div>
                    {
                        availability_date ?
                            <div className="space-y-1">
                                <div>{t("Disponibilità magazzino {{warehouse}}", { warehouse: warehouse })}:</div>
                                <div className="font-light">
                                    {t("Data")}: {availability_date ? dateFormat.format(new Date(availability_date)) : ""}
                                </div>
                            </div>
                            :
                            <div className="space-y-1">
                                <div>{t("Disponibilità magazzino {{warehouse}}", { warehouse: warehouse })}:</div>
                                <div className="font-light">{t("In approvvigionamento")}</div>
                            </div>
                    }
                </div>
                <div>
                    {
                        hq_availability_date ?
                            <div className="space-y-1">
                                <div>{t("Disponibilità magazzino HQs")}:</div>
                                {
                                    isSameDay(new Date(hq_availability_date), new Date()) ?
                                        <div className="font-light">
                                            {t("Prodotto disponibile")}
                                        </div>
                                        :
                                        <div className="font-light">
                                            {t("Data")}: {hq_availability_date ? dateFormat.format(new Date(hq_availability_date)) : ""}
                                        </div>
                                }
                            </div>
                            :
                            <div className="space-y-1">
                                <div>{t("Disponibilità magazzino HQs")}:</div>
                                <div className="font-light">{t("In approvvigionamento")}</div>
                            </div>
                    }
                </div>
            </div>
        );
    } else {
        template = (
            <div>
                <div className="space-x-1">
                    <span>{t("Data")}:</span>
                    <span className="font-light">{availability_date ? dateFormat.format(new Date(availability_date)) : ""}</span>
                </div>
            </div>
        );
    }

    return (
        <>

            {
                (availability_date || hq_availability_date) ?
                    <Tooltip
                        label={template}
                        style={{
                            backgroundColor: "#F1F5F6",
                            borderRadius: "3px",
                            marginRight: "20px",
                            zIndex: 50
                        }}
                    >
                        <span>
                            <AiOutlineInfoCircle className="text-sm text-gray-500" />
                        </span>
                    </Tooltip>
                    :
                    <InfoTooltip>{t("In approvvigionamento")}</InfoTooltip>
            }
        </>
    );
};
