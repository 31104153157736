import client from "../api/client";
import useGetCustomers from "../api/customers/useGetCustomers";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { ComplaintsTypeList } from "../components/assistance/complaints/ComplaintsTypeList";
import { CustomerSelect } from "../components/commom/CustomerSelect";
import { ProductFlow } from "../components/assistance/complaints/product-flow/ProductFlow";
import { ServiceFlow } from "../components/assistance/complaints/service-flow/ServiceFlow";
import { Button } from "../components/Button";
import { Loader } from "../components/Loader";
import { ToastContainer, Slide, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useComplaintSubTypes } from "../api/assistance/useComplaintSubTypes";

export default function Complaint() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // hooks
    const methods = useForm();
    const { loadCustomersOptions } = useGetCustomers();
    const { data: complaintTypes } = useComplaintSubTypes();

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
    const [complaintFlow, setComplaintFlow] = useState<"product" | "service" | null>(null);

    // handle customer SELECT change
    const handleCustomerChange = (inputValue: any, reactFormOnChange: any) => {
        setSelectedCustomer(inputValue);
        // set form value customer code
        if (inputValue) {
            reactFormOnChange(inputValue.code);
            setSelectedCustomer(inputValue);
            methods.setValue("salesRep", inputValue.ts_code);
            methods.setValue("organization", inputValue.organization_id);
        } else {
            // if user clears input set formState customer code back to null otherwise validation doesn't work
            methods.setValue("customer_code", null);
            setSelectedCustomer(null);
            // reset flows and form state
            setComplaintFlow(null);
            methods.setValue("salesRep", null);
            methods.setValue("organization", null);
        }
    };

    // handle complaint type SELECT change and set type of flow (PRODUCT OR SERVICE COMPLAINT)
    const handleSelectComplaintTypeChange = (inputValue: any, reactFormOnChange: any) => {
        methods.unregister(["item_code", "lotto", "qty", "complaint_orders", "problem_description", "allowed_application", "problem_seen", "problem_seen_name", "customs_bill", "amount_damage", "product_type", "application_date", "external_application", "application_type", "material_used", "exposure_time", "package_type", "storage_type", "expiration_date", "gun_type", "machine_number", "complaint_files"]);

        reactFormOnChange(inputValue?.id);

        inputValue?.id.startsWith("P00") ? setComplaintFlow("product") : inputValue?.id.startsWith("S00") ? setComplaintFlow("service") : setComplaintFlow(null);
    };

    // SUBMIT COMPLAINT
    const submitComplaint = async (data: any) => {
        const formData = new FormData();

        // append data
        formData.append("data", JSON.stringify(data));

        // append files
        if (data.complaint_files) {
            for (let i = 0; i < data.complaint_files.length; i++) {
                formData.append(`complaint_files-${i}`, data.complaint_files[i]);
            }
        }

        if (data.machine_files) {
            for (let i = 0; i < data.machine_files.length; i++) {
                formData.append(`machine_files-${i}`, data.machine_files[i]);
            }
        }

        setIsLoading(true);

        try {
            const res = await client.post("complaints", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            methods.reset();

            setIsLoading(false);

            navigate(`/complaint-sent/${res?.data?.complaint_id}`);
        } catch (err: any) {
            console.log(err);
            setIsLoading(false);
            toast.error(t("Qualcosa è andato storto"));
        }
    };

    return (
        <>
            <FormProvider {...methods}>
                {
                    <>
                        {isLoading && <Loader />}

                        <ToastContainer
                            position="top-center"
                            autoClose={3000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="colored"
                            transition={Slide}
                        />

                        <form onSubmit={methods.handleSubmit(submitComplaint)}>
                            {/* SELECT CUSTOMER */}
                            <fieldset className="w-full md:w-1/2">
                                <legend className="sr-only">{t("Cliente")}</legend>
                                <Controller
                                    control={methods.control}
                                    name='customer_code'
                                    rules={{ required: `${t("- questo campo è obbligatorio")}` }}
                                    render={({ field: { onChange }, fieldState: { error } }) => (
                                        <CustomerSelect
                                            label="Seleziona cliente"
                                            loadCustomersOptions={loadCustomersOptions}
                                            handleChange={(e: any) => handleCustomerChange(e, onChange)}
                                            error={error}
                                            selectedCustomer={selectedCustomer}
                                            setSelectedCustomer={setSelectedCustomer}
                                        />
                                    )}
                                />
                            </fieldset>

                            {/* COMPLAINT TYPE */}
                            {
                                complaintTypes &&
                                <Controller
                                    control={methods.control}
                                    name='complaint_type'
                                    rules={{ required: `${t("- questo campo è obbligatorio")}` }}
                                    render={({ field: { onChange }, fieldState: { error } }) => (
                                        <ComplaintsTypeList
                                            complaintTypes={complaintTypes}
                                            onChange={(e: any) => handleSelectComplaintTypeChange(e, onChange)}
                                            error={error}
                                            selectedCustomer={selectedCustomer}
                                        />
                                    )}
                                />
                            }

                            {/* COMPLAINT FLOWS */}
                            {complaintFlow === "product" && <ProductFlow selectedCustomer={selectedCustomer} />}
                            {complaintFlow === "service" && <ServiceFlow selectedCustomer={selectedCustomer} />}

                            <div className="text-right mt-10">
                                <Button>{t("Invia")}</Button>
                            </div>
                        </form>
                    </>
                }
            </FormProvider>
        </>
    );
}
