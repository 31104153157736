import { useEffect, useState } from "react";
import { Button } from "../components/Button";
import { useTranslation } from "react-i18next";
import Table from "../components/Table";
import { FaCheck } from "react-icons/fa6";
import { FiX } from "react-icons/fi";
import { useForm } from "react-hook-form";
import useProfileMutations from "../api/profile/useProfileMutations";
import { Loader } from "../components/Loader";
import { useNavigate } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";

export default function Contacts({ data: profile }: any) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // state
    const [isEditing, setIsEditing] = useState(false);
    const [defaultValues, setDefaultValues] = useState<any>({});

    // react hook form
    const { handleSubmit, register, reset, formState: { isDirty, dirtyFields } } = useForm({ defaultValues: defaultValues });

    // react query
    const { updateProfile } = useProfileMutations();

    useEffect(() => {
        if (profile?.data?.contacts?.length > 0) {
            const values: any = {};
            profile?.data?.contacts?.forEach((contact: any) => {
                values[contact.id] = {
                    flag_confirmationorder: contact.flag_confirmationorder,
                    flag_customerinvoice: contact.flag_customerinvoice,
                    flag_deliveryslip: contact.flag_deliveryslip,
                    flag_tracking: contact.flag_tracking,
                    contact_email: contact.email
                };
            });
            setDefaultValues(values);
            reset(values);
        }
    }, [profile, reset]);

    const columns = [
        {
            Header: t("").toString(),
            accessor: "client",
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return (
                    <div className="flex flex-col py-5">
                        <div className="space-x-2">
                            {
                                props?.row?.original?.givenname &&
                                <span className="font-normal">
                                    {props?.row?.original?.givenname}
                                </span>
                            }
                            <span className="font-normal">
                                {props?.row?.original?.surname}
                            </span>
                        </div>
                        <div className="flex gap-2 items-center py-1">
                            <FaPhoneAlt className="w-4 h-4" />
                            <span>{props?.row?.original?.phone}</span>
                        </div>
                        <div className="flex gap-2 items-center">
                            <IoIosMail className="w-5 h-5" />
                            <span>{props?.row?.original?.email}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            Header: t("Conferma ordine").toString(),
            accessor: "order",
            className: "text-center px-4 py-2 border-b",
            Cell: (props: any) => {

                return (
                    <div className="flex justify-center items-center">
                        {isEditing ?
                            <input
                                {...register(`${props?.row?.original?.id}.flag_confirmationorder`)}
                                type="checkbox"
                                className="accent-black"
                            />
                            :
                            props?.row?.original?.flag_confirmationorder ?
                                <FaCheck className="text-green-600 w-5 h-5" />
                                :
                                <FiX className="text-red-500 w-7 h-7" />
                        }
                    </div>
                );
            },
        },
        {
            Header: t("Fatture").toString(),
            accessor: "invoices",
            className: "text-center px-4 py-2 border-b",
            Cell: (props: any) => {

                return (
                    <div className="flex justify-center items-center">
                        {isEditing ? (
                            <input
                                {...register(`${props?.row?.original?.id}.flag_customerinvoice`)}
                                type="checkbox"
                                className="accent-black"
                            />
                        ) : props?.row?.original?.flag_customerinvoice ? (
                            <FaCheck className="text-green-600 w-5 h-5" />
                        ) : (
                            <FiX className="text-red-500 w-7 h-7" />
                        )}
                    </div>
                );
            },
        },
        {
            Header: t("Bolle").toString(),
            accessor: "note",
            className: "text-center px-4 py-2 border-b",
            Cell: (props: any) => {
                return (
                    <div className="flex justify-center items-center">
                        {isEditing ? (
                            <input
                                {...register(`${props?.row?.original?.id}.flag_deliveryslip`)}
                                type="checkbox"
                                className="accent-black"
                            />
                        ) : props?.row?.original?.flag_deliveryslip ? (
                            <FaCheck className="text-green-600 w-5 h-5" />
                        ) : (
                            <FiX className="text-red-500 w-7 h-7" />
                        )}
                    </div>
                );
            },
        },
        {
            Header: t("Tracking").toString(),
            accessor: "tracking",
            className: "text-center px-4 py-2 border-b",
            Cell: (props: any) => {
                return (
                    <div className="flex justify-center items-center">
                        {isEditing ? (
                            <input
                                {...register(`${props?.row?.original?.id}.flag_tracking`)}
                                type="checkbox"
                                className="accent-black"
                            />
                        ) : props?.row?.original?.flag_tracking ? (
                            <FaCheck className="text-green-600 w-5 h-5" />
                        ) : (
                            <FiX className="text-red-500 w-7 h-7" />
                        )}
                    </div>
                );
            },
        },
    ];

    const onSubmit = async (data: any) => {
        if (isDirty) {
            const mappedData: any = {};
            Object.keys(dirtyFields).map((field: any) => {
                Object.keys(dirtyFields[field]).map((nestedField) => {
                    if (!mappedData[field]) {
                        mappedData[field] = {};
                    }
                    
                    mappedData[field][nestedField] = data[field][nestedField];
                    mappedData[field]["contact_email"] = data[field]["contact_email"];
                });
            });
            // console.log(mappedData);
            
            await updateProfile.mutateAsync({ customer_code: profile?.data?.code, contacts: mappedData });
            setIsEditing(false);
            navigate("/profile-sent");
        }
    };

    return (
        <>
            {updateProfile.isLoading && <Loader />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset>
                    <Table
                        label="Contatti"
                        data={profile?.data?.contacts ?? []}
                        columns={columns}
                    />
                    <div className="flex justify-end w-full">
                        {
                            isEditing &&
                            <Button
                                className="mt-6"
                                isDisabled={!isDirty}
                            >
                                Invia richiesta modifica
                            </Button>
                        }

                        {
                            !isEditing &&
                            <Button
                                className="mt-6"
                                onClick={() => setIsEditing(true)}
                                type="button"
                            >
                                {t("Modifica")}
                            </Button>
                        }
                    </div>
                </fieldset>
            </form>
        </>
    );
}
