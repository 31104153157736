import { Button } from "./Button";
import { useTranslation } from "react-i18next";
import { Modal } from "./commom/Modal";

export function NewVersionModal({ modalVersionStatus }: any) {
    const { t } = useTranslation();

    return (
        <Modal open={modalVersionStatus} title={t("Nuova versione")}>
            <p>{t("È disponibile una nuova versione, ricarica la pagina.")}</p>
            <div className="text-right mt-4">
                <Button variant={Button.variant.primary} onClick={() => window.location.reload()}>{t("Ricarica")}</Button>
            </div>
        </Modal>
    );
}