import { useTranslation } from "react-i18next";
import { Status } from "../Status";
import { InfoTooltip } from "./InfoTooltip";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { AvailabilityTooltip } from "../cart/AvailabilityTooltip";
import { useState } from "react";
import ChangeProductModal from "../cart/step_2/Delivery-Availability/ChangeProductModal";
import { BsArrowRepeat } from "react-icons/bs";
import { Tooltip } from "@reach/tooltip";
import useAlternativeProducts from "../../api/alternative-products/useAlternativeProducts";
import useOfferItemsMutations from "../../api/offers/useOfferItemsMutations";
import { useCartItemsMutations } from "../../api/carts/useCartItemsMutations";

export const AvailabilityCell = (props: any) => {
    const { t } = useTranslation();
    const { user } = useAuthContext();

    const isOffer = !!props.cart.data.offer_state;

    // state
    const [open, setOpen] = useState<any>(false);

    // react query
    const { data: alternativeItems } = useAlternativeProducts({ product_id: props.row.original.code, "filter[customer_code]": props?.cart?.data?.customer?.code }, open);
    const { replaceProduct } = useCartItemsMutations();
    const { replaceOfferProduct } = useOfferItemsMutations();

    const numberFormat = new Intl.NumberFormat(user?.language_id);

    const availableQuantity = props.row.original.availabilityInfo?.availableQuantity;
    const unavailableQuantity = props.row.original.availabilityInfo?.unavailableQuantity;
    const availabilityCoverDate = props.row.original.availabilityInfo?.availabilityCoverDate;
    const hqAvailabilityCoverDate = props.row.original.hqAvailabilityInfo?.hqAvailabilityCoverDate;

    return (
        <div className="space-y-1">
            {
                <ChangeProductModal
                    open={open}
                    onClose={setOpen}
                    alternativeItems={alternativeItems?.data}
                    cartId={props.cart?.data?.id}
                    productId={props.row.original.id}
                    replaceProduct={isOffer ? replaceOfferProduct : replaceProduct}
                />
            }

            {
                availableQuantity > 0 &&
                <div className="flex items-center gap-x-1">
                    <Status variant={Status.variant["item_available"]}>
                        <InfoTooltip>{t("Prodotto disponibile")}</InfoTooltip>
                    </Status>
                    {numberFormat.format(availableQuantity)}
                </div>
            }

            {
                unavailableQuantity > 0 &&
                <div className="flex items-center gap-x-1">
                    <Status variant={Status.variant["item_not_available"]}>
                        <AvailabilityTooltip availability_date={availabilityCoverDate} hq_availability_date={hqAvailabilityCoverDate} warehouse={props?.cart?.data?.customer?.technicalSale?.storageArea?.code} />
                    </Status>

                    {/* QTY */}
                    {numberFormat.format(unavailableQuantity)}

                    {/* ALTERNATIVE PRODUCT */}
                    {
                        props?.row?.original?.has_alternative_item && !props.isSummary && 
                        <Tooltip
                            label={t("Sostituisci articolo")}
                            style={{
                                backgroundColor: "#F1F5F6",
                                borderRadius: "3px",
                                fontWeight: "300",
                                marginRight: "20px",
                                zIndex: 50
                            }}
                        >
                            <span>
                                <BsArrowRepeat className='font-normal text-sm cursor-pointer' onClick={() => setOpen(true)} />
                            </span>
                        </Tooltip>
                    }
                </div>
            }
        </div>
    );
};
