import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useShippingContext } from "../hooks/use-context/useShippingContext";
import useOrder from "../api/orders/useOrder";
import { OrderSuccess } from "../components/orders/OrderSuccess";
import { OrderBlocked } from "../components/orders/OrderBlocked";

function OrderSent() {
    const { id } = useParams();

    const { resetShippingContext } = useShippingContext();

    const { order } = useOrder(id);

    useEffect(() => {
        resetShippingContext();
    }, [resetShippingContext]);

    if (!order) return null;

    return (
        <div className="py-10 px-8 2xl:px-28">
            {(order && !order?.data?.blocked) ? <OrderSuccess order={order} /> : <OrderBlocked order={order} />}
        </div>
    );
}

export default OrderSent;
