import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Section } from "../../../../Section";

type Props = {
    flow?: string
};

export const ComplaintDescription = ({ flow = "product" }: Props) => {
    // react hook form
    const { register, formState: { errors }, watch, setValue } = useFormContext();
    const { t } = useTranslation();

    const problem_seen = watch("problem_seen");
    const problem_seen_name = watch("problem_seen_name");

    useEffect(() => {
        if (problem_seen === "no") {
            setValue("problem_seen_name", null);
        }
    }, [problem_seen]);

    return (
        <Section title={"Descrizione problema riscontrato"}>
            <fieldset>
                <legend className="sr-only">{t("Descrizione del problema")}</legend>
                <div className="space-y-4">
                    {/* TEXT AREA DESCRIPTION */}
                    <div>
                        <label htmlFor="description">{t("Descrizione")}</label>
                        <textarea
                            {...register("problem_description", { required: `${t("- questo campo è obbligatorio")}`, minLength: { value: 20, message: `${t("minimo {{number}} caratteri", { number: 20 })}` } })}
                            placeholder={t("Inserire descrizione")}
                            rows={4}
                        />
                        {errors?.problem_description && <p className="text-sm text-rose-500 mt-1">{errors?.problem_description.message}</p>}
                    </div>

                    {
                        flow === "product" &&
                        <>
                            {/* ALLOWED APPLICATION RADIO */}
                            <div className="space-y-2">
                                <p className="text-sm">{t("La scheda tecnica permette questa applicazione?")}</p>

                                <div className="flex gap-x-4">
                                    <div className="flex items-center gap-x-2">
                                        <input
                                            {...register("allowed_application", { required: `${t("- questo campo è obbligatorio")}` })}
                                            className="cursor-pointer"
                                            id="aa-si"
                                            value={"si"}
                                            type="radio"
                                            name="allowed_application"
                                        />
                                        <label htmlFor="aa-si" className="p-0 cursor-pointer font-light">{t("Sì")}</label>
                                    </div>
                                    <div className="flex items-center gap-x-2">
                                        <input
                                            {...register("allowed_application", { required: `${t("- questo campo è obbligatorio")}` })}
                                            className="cursor-pointer"
                                            id="aa-no"
                                            value={"no"}
                                            type="radio"
                                            name="allowed_application"
                                        />
                                        <label htmlFor="aa-no" className="p-0 cursor-pointer font-light">{t("No")}</label>
                                    </div>
                                </div>
                                {errors?.allowed_application && <p className="text-sm text-rose-500 mt-1">{errors?.allowed_application.message}</p>}
                            </div>

                            {/* PROBLEM SEEN RADIO */}
                            <div className="space-y-2">
                                <p className="text-sm">{t("Personale Rothoblaas ha visto il problema di persona?")}</p>

                                <div className="flex gap-x-4">
                                    <div className="flex items-center gap-x-2">
                                        <input
                                            {...register("problem_seen", { required: `${t("- questo campo è obbligatorio")}` })}
                                            className="cursor-pointer"
                                            id="ps-si"
                                            type="radio"
                                            value={"si"}
                                            name="problem_seen"
                                        />
                                        <label htmlFor="ps-si" className="p-0 cursor-pointer font-light">{t("Sì")}</label>
                                    </div>
                                    <div className="flex items-center gap-x-2">
                                        <input
                                            {...register("problem_seen", { required: `${t("- questo campo è obbligatorio")}` })}
                                            className="cursor-pointer"
                                            id="ps-no"
                                            value={"no"}
                                            type="radio"
                                            name="problem_seen"
                                        />
                                        <label htmlFor="ps-no" className="p-0 cursor-pointer font-light">{t("No")}</label>
                                    </div>
                                </div>
                                {errors?.problem_seen && <p className="text-sm text-rose-500 mt-1">{errors?.problem_seen.message}</p>}
                            </div>

                            {/* WHO'S SEEN THE PROBLEM */}
                            <div className="w-1/2">
                                <label htmlFor="ws-complaint">{t("Inserisci il nome della persona che ha visto il problema")}</label>
                                <input
                                    {...register("problem_seen_name", {
                                        validate: {
                                            required: () => {
                                                if (problem_seen === "si" && !problem_seen_name) return `${t("- questo campo è obbligatorio")}`;
                                            }
                                        }
                                    })}
                                    type="text"
                                    placeholder={t("inserisci il nome")}
                                    disabled={problem_seen === "no"}
                                />
                                {errors?.problem_seen_name && <p className="text-sm text-rose-500 mt-1">{errors?.problem_seen_name.message}</p>}
                            </div>
                        </>
                    }
                </div>
            </fieldset>
        </Section>
    );
};
