import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, usePagination, Column } from "react-table";
import useMarginCalculationMethods from "../../../hooks/useMarginCalculationMethods";
import { useAuthContext } from "../../../hooks/use-context/useAuthContext";
import { SortableHeader } from "../../commom/SortableHeader";
import { marketingSearchParams } from "./MarketingPriceListSearchBar";

type MarketingPriceListTableProps = {
    items: any,
    setParams: React.Dispatch<React.SetStateAction<marketingSearchParams>>,
};

export const MarketingPriceListTable = ({ items, setParams }: MarketingPriceListTableProps) => {
    const { t } = useTranslation();

    const { user } = useAuthContext();

    const { semiramisRound } = useMarginCalculationMethods();

    // table data
    const data = useMemo(
        () => {
            return items;
        },
        [items]
    );

    const columns: Array<Column<any>> = useMemo(
        () => [
            {
                Header: t("Codice articolo").toString(),
                accessor: "code",
                className: "p-4 whitespace-nowrap border-b",
                Cell: (props: any) => {
                    return <div className="flex gap-1 items-center">
                        <div>
                            <div className='mx-auto mb-1 text-center text-2xl'>
                                <img src={`https://resources.rothoblaas.com/eservice-images/product/${props.cell.row.original.code}`} className="hover:shadow hover:scale-500 ease-in-out duration-300 max-w-[50px] max-h-[50px] m-auto" alt='' />
                            </div>
                            <span className="block text-center">{props.cell.row.original?.code || "-"}</span>
                        </div>
                    </div>
                }
            },
            {
                Header: t("Consociata").toString(),
                accessor: "organization",
                className: "p-4 border-b",
            },
            {
                Header: t("Brand").toString(),
                accessor: "brand",
                className: "p-4 border-b",
                Cell: (props: any) => {
                    return props.cell.row.original?.brand || "-";
                }
            },
            {
                Header: t("Classificazione articolo").toString(),
                accessor: "priceclassification_description",
                className: "p-4 border-b"
            },
            {
                Header: t("Descrizione").toString(),
                accessor: "description",
                className: "p-4 border-b",
            },
            {
                Header: t("Lingua").toString(),
                accessor: "language",
                className: "p-4 border-b",
            },
            {
                Header: t("Pieces per package").toString(),
                accessor: "pieces",
                className: "p-4 border-b",
                Cell: (props: any) => {
                    return `${parseInt(props.row.original?.piecesperpackage)} ${props.row.original?.piecesperpackageuom}`;
                }
            },
            {
                Header: t("Prezzo netto").toString(),
                accessor: "price",
                className: "p-4 border-b",
                Cell: (props: any) => {
                    const numberFormat = new Intl.NumberFormat(user?.interface_language_id || "en", { style: 'currency', currency: props.row.original?.currency, minimumFractionDigits: 5, maximumFractionDigits: 5 });
                    const price = semiramisRound(parseFloat(props.row.original.pricevalue.toString()), 5);
                    return numberFormat.format((price));
                }
            },
            {
                Header: t("Minimo vendibile").toString(),
                accessor: "minsellable",
                className: "p-4 border-b",
                Cell: (props: any) => {
                    return `${parseInt(props.row.original?.minsellable)} ${props.row.original?.piecesperpackageuom}`;
                }
            },
            {
                Header: t("Disponibilità").toString(),
                // Header: (props) => {
                //     return <SortableHeader props={props} title={t("Disponibilità").toString()} />;
                // },
                accessor: "quantity",
                className: "p-4 border-b",
                Cell: (props:any) => {
                    return `${parseInt(props.row.original?.quantity)} ${props.row.original?.piecesperpackageuom}`;
                },
            },
        ],
        [t, data]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: { sortBy }
    } = useTable({
        columns,
        data,
        manualSortBy: true,
        disableSortRemove: true,
        initialState: { pageSize: 10 },
        // getRowId(originalRow: any, relativeIndex, parent) {
        //     return originalRow?.id;
        // },
    }, useSortBy, usePagination);

    // SORTING
    // useEffect(() => {
    //     if (["quantity"].includes(sortBy[0]?.id))
    //         setParams((prevParams: marketingSearchParams) => ({ ...prevParams, sort: sortBy[0].desc ? sortBy[0].id : `-${sortBy[0].id}` }));
    // }, [sortBy]);

    return (
        <>
            <table {...getTableProps()} className="border w-full text-xs font-light">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="border-b">
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className="p-4 text-left"
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell, index) => {
                                    return (
                                        <td
                                            {...cell.getCellProps({ className: (cell.column as any).className })}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};
