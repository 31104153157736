import React from "react";
import { DiscountCurrent } from "./DiscountCurrent";
import { DiscountFuture } from "./DiscountFuture";

export const DiscountTableRow = ({ discount, index }:any) => {

    const data = {
        current: { ...discount?.[200]?.[0], ...returnCorrect(0) },
        future: { ...discount?.[200]?.[1], ...returnCorrect(1) },
    };

    function returnCorrect(status:number):object {
        if (discount?.[290]?.length > 1) {
            return { pricevalue2: discount?.[290]?.[status]?.pricevalue };
        } else if (discount?.[290]?.length === 1) {
            if (discount?.[200]?.[status]?.validfrom && discount?.[290]?.[0]?.validfrom && discount?.[200]?.[status]?.validfrom === discount?.[290]?.[0]?.validfrom) {
                return { pricevalue2: discount?.[290]?.[0]?.pricevalue };
            }
            return { pricevalue2: null };
        } else {
            return { pricevalue2: null };
        }
    }
    //console.log(data.current);
    const labels = (data.current?.priceclassification ? data.current?.priceclassification + "-" + (data.current?.code || data.current?.itemkey) : data.current?.itemkey);
    return <div key={index} className="border-t border-table-tertiary first:border-none grid grid-cols-8 font-light leading-6 w-full h-[132px]">
        <div className="col-span-2 my-5 pl-6 pr-2 space-y-1">
            <div>
                {labels.split("-").map((label: any, index: number) => {
                    if(index > 0) {
                        return <React.Fragment key={index}>
                            <span className="last:font-normal">{label}</span>
                            {index !== labels.split("-").length - 1 && <span>-</span>}
                        </React.Fragment>;
                    }
                })}
            </div>
            {(data.current?.price_class_description || data.current?.item_description) && <div className="text-sm text-primary/80 font-light overflow-hidden overflow-ellipsis">{data.current?.item_description ?? data.current?.price_class_description}</div>}
        </div>
        <DiscountCurrent
            current={data.current}
        />
        <DiscountFuture
            current={data.current}
            future={data.future}
        />
    </div>;
};
