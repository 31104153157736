import { ReactNode } from "react";
import { IoIosWarning } from "react-icons/io";

type ErrorIconProps = {
    children: ReactNode;
};

export default function ErrorIcon({ children }: ErrorIconProps) {
    return (
        <div role="alert" tabIndex={1} className='relative inline-block text-red-500'>
            <span className='peer cursor-pointer text-xl'>
                <IoIosWarning />
            </span>
            <span className='hidden peer-hover:block peer-focus:block z-20 absolute top-full mt-2 right-2 text-left translate-x-2/4 w-36 p-2 bg-red-200 rounded shadow'>
                {children}
            </span>
        </div>
    );
}
