import { useTranslation } from "react-i18next";
import { BsImage } from "react-icons/bs";
import { Tag } from "./Tag";

type Props = {
    itemCode: string,
    pieces?: string,
    priceclassification?: string,
    description?: string,
    brand?: string,
    organization?: string,
    language?: string,
    netPrice?: string,
    minSellable?: number,
    availability?: number,
};

export const Tile = ({
    itemCode,
    pieces,
    priceclassification,
    description,
    brand,
    organization,
    language,
    netPrice,
    minSellable,
    availability,
}: Props) => {
    const { t } = useTranslation();

    return (
        <div className="border border-gray-200 rounded-sm h-fit">
            <div className="p-4 grid grid-cols-5 gap-4">
                <div className="col-span-2 relative aspect-[3/2] hover:shadow hover:scale-[2.5] ease-in-out duration-300">
                    <div className="-z-10 absolute inset-0 bg-zinc-100 aspect-[3/2] flex items-center justify-center border border-gray-200">
                        <BsImage className="text-2xl text-zinc-200"/>
                    </div>
                    <img src={`https://resources.rothoblaas.com/eservice-images/product/${itemCode}`} className="border border-gray-200 aspect-[3/2] object-cover" alt='' />
                </div>
                <div className="col-span-3 overflow-hidden font-light space-y-1.5 text-sm">
                    <div className="font-normal">{itemCode} <span className="font-light">{pieces}</span></div>
                    {priceclassification && <div>{priceclassification}</div>}
                    {description && <div>{description}</div>}
                    <div className="flex items-center gap-1">
                        {brand && <Tag variant="primary">{brand}</Tag>}
                        {organization && <Tag variant="primary">{organization}</Tag>}
                        {language !== "-" && <>
                            <span>-</span>
                            <Tag variant="ghost">{language}</Tag>
                        </>}
                    </div>
                </div>
            </div>
            {(!!netPrice || !!minSellable || !!availability) && <div className="space-y-2 p-4 border-t border-gray-200 bg-zinc-50 text-sm font-light">
                {netPrice && <div className="flex justify-between gap-2"><span>{t("Prezzo netto")}</span><span className="font-normal">{netPrice}</span></div>}
                {minSellable && <div className="flex justify-between gap-2"><span>{t("Min. vendibile")}</span><span>{parseInt(minSellable.toString())}</span></div>}
                <div className="flex justify-between gap-2"><span>{t("Disponibilità")}</span><span>{availability}</span></div>
            </div>}
        </div>
    );
};
