import classNames from "classnames";
import { useTranslation } from "react-i18next";

type Props = {
    label?: string;
    variant: "base" | "bar";
    buttons: {
        icon: any,
        label?: string,
        value: string,
    }[];
    selected: any,
    setSelected: React.Dispatch<React.SetStateAction<any>>
};

export function Segmented({
    label,
    variant = "bar",
    buttons = [],
    selected,
    setSelected,
}: Props) {
    const { t } = useTranslation();

    return (
        <div className="flex items-center gap-4">
            {label && <span className="text-stone-900">{label}</span>}
            <div className={classNames("flex", {
                "p-px rounded-lg bg-zinc-100": variant === "bar",
                "gap-6": variant === "base",
            })}>
                {buttons?.map((el, index) => {
                    return <div className={classNames("cursor-pointer flex gap-2 items-center p-4 rounded-lg", {
                        "bg-white border border-gray-200": el.value === selected,
                        "hover:bg-zinc-100": variant === "base",
                    })} key={index} onClick={() => setSelected(el.value)}>
                        <span>{el.icon}</span>
                        {el.label && <span className="leading-4 text-sm">{el.label}</span>}
                    </div>
                })}
            </div>
        </div>
    );
}