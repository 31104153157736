import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { toast } from "react-toastify";

type ItemCardProps = {
    cart_id: string;
    code: string;
    description: string;
    min: number;
    family?: {
        name: string,
        url: string
    };
    addProduct?: any;
    isLoading: boolean;
    anchor: any;
};

export const ItemCard = ({
    cart_id,
    code,
    description,
    min,
    family,
    addProduct,
    isLoading,
    anchor,
}: ItemCardProps) => {
    const { t } = useTranslation();

    const handleAddProduct = async () => {
        await toast.promise(
            addProduct.mutateAsync({
                cart_id,
                body: {
                    product_code: code,
                    quantity: min,
                },
            }),
            {
                pending: t("Carico..."),
                success: {
                    render() {
                        anchor.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                        return t("Modifica effettuata con successo");},
                },
                error: {
                    render({ data }: any) {
                        if (data?.response?.status === 403) {
                            return t(data?.response?.data?.message);
                        } else {
                            return t("Error, something went wrong");
                        }
                    },
                },
            }
        );
    };

    return (
        <div className="space-y-2 px-7">
            <div className="relative">
                <img src={`https://resources.rothoblaas.com/eservice-images/product/${code}`} className="p-4 aspect-square object-contain" alt='' />
                {family?.name && family?.url && <div className="absolute top-4 right-4 bg-black text-white font-light text-xs leading-[18px] uppercase px-2 py-1"><a target={"_blank"} href={family?.url} rel="noreferrer">{family?.name}</a></div>}
            </div>
            <div className="font-normal text-lg leading-5">{code}</div>
            <div className="font-light text-base h-[54px] line-clamp-2 pb-2">{description}</div>
            {addProduct && <Button
                variant={Button.variant.primary}
                className="w-full uppercase"
                isDisabled={isLoading}
                onClick={handleAddProduct}
            >
                {t("Aggiungi")}
            </Button>}
        </div>
    );
};
