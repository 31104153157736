import { Dialog } from "@reach/dialog";
import { Title } from "../Title";
import { MdClose } from "react-icons/md";
import { useTranslation } from "react-i18next";
import DeliveryAddressSearchBar from "../cart/step_2/Delivery-addresses/DeliveryAddressSearchBar";
import useAddresses from "../../api/addresses/useAddresses";
import { DeliveryAddressTable } from "../cart/step_2/Delivery-addresses/DeliveryAddressTable";
import { useState } from "react";
import { Button } from "../Button";
import { useShippingContext } from "../../hooks/use-context/useShippingContext";
import useCartAddressMutations from "../../api/addresses/useCartAddressMutations";
import useOfferAddressMutations from "../../api/addresses/useOfferAddressMutations";

type Props = {
    open: boolean,
    onClose: (param: boolean) => void
    data: any
};

type searchParams = {
    "filter[name]"?: string,
    "filter[street]"?: string,
    "filter[city]"?: string,
    "filter[country]"?: string,
    "filter[region]"?: string,
    "filter[postalcode]"?: string,
    "sort"?: string
};

export const SelectAddressModal = ({ open, onClose, data }: Props) => {
    const { t } = useTranslation();

    const [selectedAddress, setSelectedAddress] = useState<any>(null);
    const [searchParams, setSearchParams] = useState<searchParams>({});

    const { setManualFreightCost, setManualShippingMethod, setAdditionalFreightCost } = useShippingContext();

    const { addresses } = useAddresses({ data: data, params: { customer_code: data?.data?.customer?.code, ...searchParams } });
    const { updateCartAddress } = useCartAddressMutations();
    const { updateOfferAddress } = useOfferAddressMutations();

    const handleClose = () => {
        onClose(false);
    };

    const handleSave = () => {
        if (data?.data?.offer_state) {
            updateOfferAddress.mutateAsync({ offer_id: data?.data?.id, delivery_address: selectedAddress });
        } else {
            updateCartAddress.mutateAsync({ cart_id: data?.data?.id, delivery_address: selectedAddress });
        }

        setManualFreightCost("");
        setManualShippingMethod("");
        setAdditionalFreightCost("");

        onClose(false);
    };

    return (
        <Dialog isOpen={open} onDismiss={handleClose} className="overflow-y-scroll md:overflow-y-visible !h-auto !w-auto absolute inset-0 md:bottom-auto md:left-1/2 md:-translate-x-1/2 z-50 modal-selector md:!my-20 !m-0 !p-8">
            <div className="space-y-6">
                <div className='flex justify-between items-center'>
                    <Title variant={Title.variant.secondary}>{t("Seleziona indirizzo")}</Title>
                    <MdClose className='text-xl hover:text-secondary cursor-pointer active:scale-90' onClick={handleClose} />
                </div>

                <div className="border p-4">
                    <DeliveryAddressSearchBar setSearchParams={setSearchParams} />
                </div>

                {addresses?.data?.length > 0 ? <DeliveryAddressTable isSelectable={true} items={addresses?.data} setSelectedAddress={setSelectedAddress} /> : <div className="p-8 bg-gray-100 rounded">Nessun elemento corrisponde ai criteri di ricerca</div>}

                <div className='text-right space-x-4'>
                    <Button type='button' variant={Button.variant.text} onClick={handleClose}>{t("Annulla")}</Button>
                    <Button type='button' variant={Button.variant.primary} onClick={handleSave} isDisabled={!selectedAddress}>{t("Salva")}</Button>
                </div>
            </div>
        </Dialog>
    );
};
