import { useTranslation } from "react-i18next";
import { BsCheckCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Title } from "../components/Title";
import { Button } from "../components/Button";
import { useAuthContext } from "../hooks/use-context/useAuthContext";

const ProfileSent = ({ order }: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useAuthContext();    

    const goToProfile = () => {
        navigate("/profile");
    };

    return (
        <div className='flex flex-col items-center h-full py-10 px-8 2xl:px-28'>
            <div className="mb-8">
                <BsCheckCircle className='text-secondary text-[50px] block m-auto mb-4' />
                <Title variant={Title.variant.secondary}>{t("Grazie {{customer_name}}", { customer_name: user?.name }).toString()}</Title>
            </div>

            <div className="w-4/5 space-y-6 border p-6 font-light mb-4">
                <p className="font-bold">{t("La tua richiesta di modifica è stata processata correttamente.")}</p>
                <p>{t("Troverai la tua anagrafica aggiornata a breve.")}</p>
            </div>

            <div className="flex flex-col gap-y-2 mt-8">
                <div className="text-center">{t("Rothoblaas team")}</div>
                <Button variant={Button.variant.text} onClick={goToProfile}>{t("Torna all'anagrafica")}</Button>
            </div>
        </div>
    );
};

export default ProfileSent;